export const toSecondUnixFormat = (at?: number) => {
    if (!at) {
        return Math.floor(new Date().getTime() / 1000)
    }

    const isUnixInSecond = at.toString().length === 10
    return Math.floor(isUnixInSecond ? at : at / 1000)
}

export const getDifferentTime = (at: number) => {
    if (at === 0) {
        return ''
    }

    const currentTime = Math.floor(new Date().getTime() / 1000)
    const second = currentTime - at

    if (second <= 0) {
        return 'Now'
    }

    if (second < 60) {
        return `${Math.floor(second)} seconds ago`
    }

    const minutes = second / 60
    if (minutes < 60) {
        return `${Math.floor(minutes)} minutes ago`
    }

    const hour = minutes / 60
    if (hour < 24) {
        return `${Math.floor(hour)} hours ago`
    }

    // const day = hour / 24
    // if (day < 2) {
    //     return `${Math.floor(day)} days ago`
    // }

    // const week = day / 7
    // if (week < 4) {
    //     return `${Math.floor(week)} weeks ago`
    // }

    // back s to ms
    const date = new Date(at * 1000)

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Nov', 'Des']
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`
}
