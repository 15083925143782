import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxConnect } from 'store/modules/connect/connect'
import config from 'config/index'

const reducerPath = 'connectApi'
export const connectApiSlice = createApi({
    reducerPath,
    baseQuery: http(
        true,
        {
            'Content-Type': 'application/json',
        },
        config.AUTH_URL,
    ),
    endpoints: build => ({
        getUserInfo: build.mutation({
            query: param => ({
                url: `/user`,
                method: 'GET',
                params: param,
            }),
            transformResponse: (response: IReduxConnect.IGetTokenByCredentialResponse): IReduxConnect.IGetTokenByCredentialResponse => {
                return response
            },
        }),
        getTokenByCredential: build.mutation({
            query: (body: IReduxConnect.IGetTokenByCredentialParams) => ({
                url: `/login`,
                method: 'POST',
                body: {
                    client_id: body.client_id,
                    username: body.username,
                    password: body.password,
                },
            }),
            transformResponse: (response: IReduxConnect.IGetTokenByCredentialResponse): IReduxConnect.IGetTokenByCredentialResponse => {
                return response
            },
        }),
        getTokenByCredentialInBackground: build.mutation({
            query: (body: IReduxConnect.IGetTokenByCredentialParams) => ({
                url: `/login`,
                method: 'POST',
                body: {
                    client_id: body.client_id,
                    username: body.username,
                    password: body.password,
                },
            }),
            transformResponse: (response: IReduxConnect.IGetTokenByCredentialResponse): IReduxConnect.IGetTokenByCredentialResponse => {
                return response
            },
        }),
        refreshToken: build.mutation({
            query: (body: { refresh_token: string; client_id?: string }) => ({
                url: `/refresh-token`,
                method: 'POST',
                body: {
                    client_id: body.client_id,
                    refresh_token: body.refresh_token,
                },
            }),
            transformResponse: (response: IReduxConnect.IGetTokenByCredentialResponse): IReduxConnect.IGetTokenByCredentialResponse => {
                return response
            },
        }),
        logout: build.mutation({
            query: () => ({
                url: `/logout`,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 0 },
        }),
    }),
})

export const {
    useGetTokenByCredentialMutation,
    useLogoutMutation,
    useGetUserInfoMutation,
    useRefreshTokenMutation,
    useGetTokenByCredentialInBackgroundMutation,
} = connectApiSlice

export const connectQueryReducer = { [reducerPath]: connectApiSlice.reducer }
export const querySelector = state => state[reducerPath]
