import { memo, useCallback, useState } from 'react'
import { Button, CircularProgress, Divider, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'

import { Comment, Thread } from './CommentItem'
import { ThreadHeader } from './ThreadHeader'
import { IUseCheckPermissionComment, useCheckPermissionComment } from './hooks/useCheckPermissionComment'

import { ReduxCommentTypeNamespace } from 'store/modules/comment/comment'

import { ICommentData } from './definition'
import './CommentRoom.css'
import { CommentContentType, CommentStatus } from 'store/modules/comment/enum'
import { CheckCircle } from '@material-ui/icons'
import { ThreadFormCreation } from './ThreadFormCreation'
import { ReplyForm } from './ReplyForm'
import { usePartialUpdateCommentMutation } from 'store/modules/comment'
import { useSnackbar } from 'notistack'
import { useGetCommentator } from './hooks/useGetCommentator'
import DDQAICard from '../DDQ/DDQAICard'

export interface ICommentRoom {
    data?: ReduxCommentTypeNamespace.ICommentResponseTransformed
    /**
     * Header contains some elements:
     * - Prev and next thread button (used in dialog)
     * - View all button
     * - Resolve, menu, and close button
     *
     * @default false
     */
    hideHeader?: boolean
    headerProps?: {
        /**
         * The close button will be hide, when onClose props not sent
         */
        onClose?: Function
    }
    /**
     * IF true, please send the thread meta
     */
    showThreadCreationOnEmpty?: boolean
    /**
     * IF viewMode = THREAD_VIEW, please send the thread meta,
     *
     * @default 'THREAD_VIEW'
     */
    viewMode?: 'VIEW_ALL' | 'THREAD_VIEW'
    /**
     * Required for viewMode = 'THREAD_VIEW' or showThreadCreationOnEmpty = true
     */
    threadMeta?: ICommentData
    permissionMeta: IUseCheckPermissionComment
    questionText?: string
    askAI?: boolean
}

/**
 * Used for displaying thread creation, comment room
 */
const CommentRoom = ({
    showThreadCreationOnEmpty,
    threadMeta,
    permissionMeta,
    questionText,
    askAI,
    hideHeader,
    headerProps,
    viewMode = 'THREAD_VIEW',
    data = { threads: [], comment: {}, elementIdToThreadId: {}, thread: {} },
}: ICommentRoom) => {
    const { enqueueSnackbar } = useSnackbar()
    const [isEditMode, setIsEditMode] = useState(false)
    const [isDeleteMode, setIsDeleteMode] = useState(false)

    /**
     * When the _thread is undefined, means its a new thread
     */
    let _thread: ReduxCommentTypeNamespace.IThread | undefined
    const { currentUserId } = useGetCommentator()
    const [partialUpdateComment, { isLoading: isUpdatingComment }] = usePartialUpdateCommentMutation()
    const {
        canResolve,
        canReply,
        canEdit,
        canDelete,
        isLoading: isLoadingCheckPermission,
    } = useCheckPermissionComment({
        type: permissionMeta.type,
        id: permissionMeta.id,
    })

    const close = useCallback(() => {
        headerProps?.onClose?.()
    }, [headerProps])

    const toDeleteMode = useCallback((isTrue: boolean) => setIsDeleteMode(isTrue), [])
    const toEditMode = useCallback((isTrue: boolean) => setIsEditMode(isTrue), [])

    /**
     * RETURN RENDER
     * Permission check
     */
    if (isLoadingCheckPermission) {
        return (
            <div className="chat-room">
                <div
                    style={{
                        position: 'relative',
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 8,
                    }}
                >
                    <Typography>Checking your permission</Typography>
                    <CircularProgress size={18} />
                </div>
            </div>
        )
    }

    /**
     * RETURN RENDER
     * Error view, thread creation and thread view need a thread meta.
     */
    if (typeof threadMeta === 'undefined' && (showThreadCreationOnEmpty || viewMode === 'THREAD_VIEW')) {
        return (
            <div className="chat-room">
                <div
                    style={{
                        position: 'relative',
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 8,
                    }}
                >
                    <Typography>Please provide thread creation meta</Typography>
                </div>
            </div>
        )
    }

    const threadSelections = viewMode === 'THREAD_VIEW' ? [] : data.threads

    /**
     * RETURN RENDER
     * Thread Creation
     */

    if (threadMeta) {
        const threadId = data.elementIdToThreadId[threadMeta.elementId]
        _thread = data.thread[threadId]

        if (!_thread && showThreadCreationOnEmpty) {
            if (!askAI) {
                return (
                    <div className="chat-room">
                        <div style={{ position: 'relative' }}>
                            <ThreadFormCreation
                                onClose={close}
                                elementId={threadMeta.elementId}
                                contentType={threadMeta.contentType}
                                contentTypeId={threadMeta.contentTypeId}
                            />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="chat-room">
                        <div style={{ position: 'relative' }}>
                            <DDQAICard
                                isBSSDDq={true}
                                initialQuestion={questionText}
                                ddq_id={threadMeta.contextId}
                                elementName={threadMeta.elementId}
                            />
                        </div>
                    </div>
                )
            }
        }

        threadSelections.push(_thread)
    }

    const undoResolve = async () => {
        if (_thread && threadMeta) {
            try {
                await partialUpdateComment({
                    id: _thread.id,
                    body: {
                        status: CommentStatus['Publish'],
                    },
                    content: {
                        content_type: threadMeta.contentType || CommentContentType['Opportunity'],
                        object_id: threadMeta.contentTypeId || '',
                    },
                }).unwrap()
            } catch (err) {
                enqueueSnackbar('Failed to undo the resolve', { variant: 'error' })
            }
        }
    }

    const isCommentDialog = !!_thread

    const getChatRoomBody = () => {
        if (isCommentDialog) {
            if (_thread?.justResolved && _thread?.status === CommentStatus['Resolve']) {
                return (
                    <div
                        className="chat-room--container"
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                            <Button className="chat-room__header__right-action" onClick={close}>
                                <CloseIcon fontSize={'small'} />
                            </Button>
                        </div>

                        <CheckCircle style={{ fontSize: '48px' }} color="primary" />
                        <Typography style={{ fontWeight: 600, marginTop: '16px' }}>Comment Resolved</Typography>
                        <Typography style={{ fontSize: '14px', maxWidth: '300px', marginTop: '12px' }} align="center">
                            You can view all the comments from the more actions menu
                        </Typography>
                        <Button variant="text" style={{ marginTop: '12px', marginBottom: '12px' }} onClick={undoResolve}>
                            Undo resolved comment {isUpdatingComment && <CircularProgress size={16} />}
                        </Button>
                    </div>
                )
            }
        }

        return threadSelections.map(thread => {
            const comments = data.thread[thread.id].comments || []
            const replies = [...comments].sort((a, b) => a.created_on_ts - b.created_on_ts)

            const meta = {
                elementId: thread.sub_comment_object,
                contentType: thread.contentType,
                contentTypeId: thread.contentTypeId,
            }

            return (
                <div key={thread.id}>
                    <div style={{ position: 'relative' }}>
                        <Thread
                            canResolve={canResolve}
                            canEdit={canEdit}
                            canDelete={canDelete}
                            isCommentDialog={isCommentDialog}
                            mainCommentMeta={meta}
                            data={thread}
                            hasHeaderAction={viewMode === 'VIEW_ALL'}
                            headerMeta={{
                                isDeleteMode: isDeleteMode,
                                isEditMode: isEditMode,
                                setIsEditMode: setIsEditMode,
                                setIsDeleteMode: setIsDeleteMode,
                                onClose: close,
                            }}
                        />
                    </div>

                    <div className={viewMode === 'THREAD_VIEW' ? 'chat-room__reply-wrapper--scroll' : 'chat-room__reply-wrapper'}>
                        {replies.map(reply => (
                            <Comment
                                isCommentDialog={isCommentDialog}
                                key={reply.id}
                                mainCommentMeta={meta}
                                parentId={thread.id}
                                data={reply}
                                canDelete={canDelete}
                                canEdit={canEdit}
                            />
                        ))}
                    </div>

                    {canReply && (
                        <div style={{ position: 'relative' }}>
                            <ReplyForm contentType={thread.contentType} contentTypeId={thread.contentTypeId} parentId={thread.id} />
                        </div>
                    )}
                    <Divider />
                </div>
            )
        })
    }

    const isCurrentUserAuthor = currentUserId === _thread?.commentator.content_item_id

    return (
        <div className="chat-room">
            {!hideHeader && isCommentDialog && _thread?.status === CommentStatus['Publish'] && threadMeta && (
                <ThreadHeader
                    isCurrentUserAuthor={isCurrentUserAuthor}
                    threads={data.threads}
                    onClose={close}
                    viewMode={viewMode}
                    threadId={_thread.id}
                    threadStatus={_thread.status}
                    threadMeta={threadMeta}
                    headerProps={{
                        showCloseAction: true,
                        showMenuAction: canResolve || canDelete || canEdit,
                        showNextPrevAction: true,
                        showResolveAction: canResolve,
                        showViewAllAction: true,
                        onDelete: canDelete ? toDeleteMode : undefined,
                        onEdit: canEdit ? toEditMode : undefined,
                    }}
                />
            )}
            <div className="chat-room__thread-container">{getChatRoomBody()}</div>
        </div>
    )
}

export default memo(CommentRoom)
