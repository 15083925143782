import React from 'react'

import { getDrawerContext } from '../provider'
import { ICompanyDrawerPropsData } from '../definition'
import { useGetCompanyDetailsQuery } from 'store/modules/management/management.query'
import { default as CompanyDrawerPage } from 'app/pages/AdminPage/CompanyDrawer/CompanyDrawer'
import { getToken } from 'utils/localStorage/token'

export const CompanyDrawer = () => {
    const drawer = React.useContext(getDrawerContext<ICompanyDrawerPropsData>())

    const { companyId } = drawer.props || {}
    const access_token = getToken('access_token')

    const { data: companyDetails, isLoading: isLoadingCompanyDetails } = useGetCompanyDetailsQuery(
        { companyId: companyId },
        {
            skip: !access_token || !companyId,
        },
    )

    const onClose = () => {
        drawer.onClose()
    }

    if (drawer.isOpen && drawer.category === 'company') {
        return (
            <CompanyDrawerPage
                companyId={companyId}
                onChangeOpenDrawer={onClose}
                companyDetails={companyDetails}
                isLoadingCompanyDetails={isLoadingCompanyDetails}
            ></CompanyDrawerPage>
        )
    }

    return null
}
