import { Helmet } from 'react-helmet-async'

import Layout from 'app/components/Layout'
import { lazyLoad } from 'utils/loadable'

const Page = lazyLoad(
    () => import('./index'),
    module => module.default,
)

// Need to pass footer setter from layout to their children
export const TransactionDetailsPage = props => {
    return (
        <>
            <Helmet>
                <title>Transaction Detail Page</title>
                <meta name="description" content="Transaction Detail page" />
            </Helmet>

            <Layout footer fullPage>
                {({ setIsShowFooter }) => <Page {...props} setIsShowFooter={setIsShowFooter} />}
            </Layout>
        </>
    )
}
