import React, { useEffect } from 'react'
import { useRefreshTokenMutation } from 'store/modules/connect/connect.query'
import { masterApiSlice } from 'store/modules/master/master.query'
import { setInterval } from 'timers'
import { getHostname } from 'utils/general'
import { getToken, setToken } from 'utils/localStorage/token'
import { ONE_MINUTE } from '.'
import config from 'config/index'

interface IOptions {
    frequency: number
}

/**
 * Auto-refresh token every N minutes (frequency) regardless of user activity.
 * Auto Refresh token only works for login by username and password
 */
const SessionAutoRefresh = ({ frequency }: IOptions) => {
    const TIMEOUT = ONE_MINUTE * frequency
    const [refreshToken] = useRefreshTokenMutation()

    const hostname = getHostname()
    const { data: whitelabelInfoData } = masterApiSlice.endpoints.getWhiteLabelInfo.useQueryState(
        { website: hostname },
        { skip: !hostname },
    )

    const clientId = whitelabelInfoData?.webConfiguration?.clientId || config.CLIENT_ID

    useEffect(() => {
        const interval = setInterval(() => {
            const refreshTokenVal = getToken('refresh_token')
            if (refreshTokenVal) {
                refreshToken({ refresh_token: refreshTokenVal, client_id: clientId }).then(response => {
                    // @ts-ignore
                    const { access_token, refresh_token } = response?.data || {}
                    if (access_token && refresh_token) {
                        setToken(
                            JSON.stringify({
                                // @ts-ignore
                                access_token: response.data.access_token,
                                // @ts-ignore
                                refresh_token: response.data.refresh_token,
                                // @ts-ignore
                                token_type: response.data.token_type,
                            }),
                        )
                        // window?.location?.reload()
                    }
                })
            }
        }, TIMEOUT)
        return () => clearInterval(interval)
    }, [TIMEOUT, refreshToken, clientId])

    return React.Fragment
}

export default SessionAutoRefresh
