import { Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import TreeItem from '@material-ui/lab/TreeItem'
import React from 'react'
import { IReduxTaxonomy } from 'store/modules/taxonomy/taxonomy'

const getChildByName = (node: IReduxTaxonomy.IReduxTaxonomyResponse, name: string) => {
    let array: string[] = []

    function getAllChild(nodes: IReduxTaxonomy.IReduxTaxonomyResponse | null) {
        if (nodes === null) return []
        array.push(nodes.value)
        if (Array.isArray(nodes.children)) {
            nodes.children.forEach(node => {
                array = [...array, ...getAllChild(node)]
                array = array.filter((v, i) => array.indexOf(v) === i)
            })
        }
        return array
    }

    function getNodeByName(nodes: IReduxTaxonomy.IReduxTaxonomyResponse, name: string) {
        if (nodes.value === name) {
            return nodes
        } else if (Array.isArray(nodes.children)) {
            let result: any = null
            nodes.children.forEach(node => {
                if (!!getNodeByName(node, name)) {
                    result = getNodeByName(node, name)
                }
            })
            return result
        }

        return null
    }

    return getAllChild(getNodeByName(node, name))
}

/**
    A function that return event function to handle change of checkbox value,
    The lifecycle of changes when there's click event:
    - Empty -> Checked
    - Indeterminate -> Checked
    - Checked -> Empty
    @param nodes An object that contain's children
    @param selected Current selected value
    @param setSelected Setter of `selected` value
    @param event contain's the next value of checkbox (the value after user clicked the checkbox)
*/
const onCheckBoxChange =
    (nodes: IReduxTaxonomy.IReduxTaxonomyResponse, selected: string[], setSelected: any) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
        /*
            isNewestChecked will be true if the "next" value (after user click the checkbox) is checked/indeterminate,
            possibility value is checked or indeterminate
        */
        const isNewestChecked = event.currentTarget.checked

        let childrenNames: string[] = getChildByName(nodes, nodes.value)
        if (isNewestChecked) {
            childrenNames = [...selected, ...childrenNames]
        } else {
            childrenNames = selected.filter(name => !childrenNames.includes(name))
        }

        setSelected(childrenNames)
    }

const onInputChange = (prevState, setter: (obj: any) => void, name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const formValue = event.target.value
    setter({ ...prevState, [name]: formValue })
}

/**
    A function to collect nested children's name
    @param children An object that contain's children
    @param childrens An array of string variable that will modified to store children's name
*/
const getMyChildrenNames = (children: IReduxTaxonomy.IReduxTaxonomyResponse['children'], childrens: string[]) => {
    children?.forEach(child => {
        if (child.children) {
            getMyChildrenNames(child.children, childrens)
        } else {
            childrens.push(child.value)
        }
    })
}

export const renderTreeTaxonomy = (
    nodes: IReduxTaxonomy.IReduxTaxonomyResponse,
    selected: string[],
    setSelected: any,
    other?: any,
    setOther?: any,
) => {
    const isParent = (nodes.children?.length ?? 0) > 0
    const childrenNames = []
    isParent && getMyChildrenNames(nodes.children, childrenNames)

    const checkedChildItems = isParent ? childrenNames?.filter(name => selected.includes(name)) : []
    const isNotCheckedAnItems = (checkedChildItems?.length ?? 0) === 0
    const isAllChildChecked = childrenNames?.length === checkedChildItems?.length

    /* 
     Indeterminate is "-" icon in checkbox that only defined when
     there's not checked items or all child is checked,
     undefined means we not setup the indeterminate,
     true or false means we setup the indeterminate
    */
    const isIndeterminate = isNotCheckedAnItems || isAllChildChecked ? undefined : isParent && !isAllChildChecked

    return (
        <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isParent ? isAllChildChecked : selected.some(item => item === nodes.value)}
                                indeterminate={isIndeterminate}
                                onChange={onCheckBoxChange(nodes, selected, setSelected)}
                                onClick={e => e.stopPropagation()}
                                style={{ margin: 9, padding: 0 }}
                                data-testid={`question-${nodes.value}`}
                            />
                        }
                        label={<>{nodes.value}</>}
                        key={nodes.id}
                    />
                    {selected.some(item => item === nodes.value) && nodes.value.toLowerCase().includes('describe') && (
                        <div>
                            <TextField
                                onChange={onInputChange(other, setOther, nodes.value)}
                                id="outlined-start-adornment"
                                style={{ marginLeft: 40, marginTop: 16 }}
                                placeholder="Enter here"
                                defaultValue={other[nodes.value]}
                                inputProps={{
                                    'data-testid': `question-${nodes.value}-other-input`,
                                }}
                            />
                        </div>
                    )}
                </>
            }
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map(node => renderTreeTaxonomy(node, selected, setSelected, other, setOther))
                : null}
        </TreeItem>
    )
}
