import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxTaxonomy } from 'store/modules/taxonomy/taxonomy'

const reducerPath = 'taxonomyApi'
export const taxonomyApiSlice = createApi({
    reducerPath,
    baseQuery: http(),
    endpoints: builder => ({
        getTaxonomy: builder.mutation<IReduxTaxonomy.IReduxTaxonomyResponse[], string>({
            query: (alias: string) => ({
                url: `/v1/taxonomy/${alias}`,
                method: 'GET',
            }),
        }),
        getTaxonomies: builder.query<IReduxTaxonomy.IReduxTaxonomyResponse[], string>({
            query: (alias: string) => ({
                url: `/v1/taxonomy/${alias}`,
                method: 'GET',
            }),
        }),
    }),
})

export const { useGetTaxonomyMutation, useGetTaxonomiesQuery } = taxonomyApiSlice
export const taxonomyQueryReducer = { [reducerPath]: taxonomyApiSlice.reducer }
