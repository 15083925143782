import { memo, useState, ReactNode, useLayoutEffect, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

import Footer from './Footer'
import NavigationBar from './NavigationBar'
import { colorPalette } from 'styles/mainTheme'
import ProgressIndicator from '../Progress/ProgressIndicator'

import { IReduxMaster } from 'store/modules/master/master'
import { masterApiSlice } from 'store/modules/master/master.query'

import { getHostname } from 'utils/general'
import usePageTracking from 'utils/analytics/usePageTracking'
import config from 'config/index'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        body: {
            display: 'flex',
            paddingTop: 120,
            paddingBottom: 120,
            minHeight: 'calc(100vh - 95px)',
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(3),
            },
        },
        content: {
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(5) + 'px auto',
            },
            width: '100%',
        },
    }),
)

export interface IChildrenParam {
    setIsShowFooter?: (isLoading: boolean) => void
    whiteLabelInfo?: IReduxMaster.IWhiteLabelInfoResult
    isChildrenShouldLoading?: boolean
}

type Props = {
    children?: JSX.Element | ((param: IChildrenParam) => ReactNode)
    fullPage?: boolean
    adminPage?: boolean
    ddqPage?: boolean
    footer?: boolean
    isLoading?: boolean
    lightBluePage?: boolean
    authenticate?: boolean
    isSignup?: boolean
    isNeedInvestorNDA?: boolean
    allowUnapproved?: boolean
    isOnlyNeedLayoutData?: boolean
    isPreview?: boolean
    previewCloseTarget?: string
}

export default memo(function Layout({
    footer,
    children,
    adminPage,
    isLoading,
    lightBluePage,
    isSignup,
    isPreview,
    isOnlyNeedLayoutData,
    previewCloseTarget,
}: Props) {
    const dispatch = useDispatch()
    const classes = useStyles(adminPage)

    // React Google Analytics for every page
    usePageTracking()
    const [isShowFooter, setIsShowFooter] = useState<boolean>(footer || false)

    const hostname = getHostname()

    const { data: whiteLabelInfo, isLoading: isGettingWhiteLabel } = masterApiSlice.endpoints.getWhiteLabelInfo.useQueryState(
        { website: hostname },
        { skip: !hostname },
    )

    useLayoutEffect(() => {
        const handleWhiteLabel = () => {
            dispatch(masterApiSlice.endpoints.getWhiteLabelInfo.initiate({ website: hostname }))
        }
        handleWhiteLabel()
    }, [hostname, dispatch])

    useEffect(() => {
        if (window && config.PRINT_FULLSTORY_META === 'true') {
            console.log('Fullstory meta', 'nope')
        }
    }, [])

    // Add here if we want children loading with navbar, etc
    const isChildrenShouldLoading = isGettingWhiteLabel

    if (isOnlyNeedLayoutData && typeof children === 'function') {
        return (
            <>
                {children?.({
                    setIsShowFooter,
                    whiteLabelInfo,
                    isChildrenShouldLoading,
                })}
            </>
        )
    }

    const getLayoutContent = () => {
        if (isLoading) {
            return <ProgressIndicator />
        }

        const finalChildren =
            typeof children === 'function'
                ? children?.({
                      setIsShowFooter,
                      whiteLabelInfo,
                      isChildrenShouldLoading,
                  })
                : children

        return (
            <div id="main-container" className={classes.content}>
                {finalChildren}
            </div>
        )
    }

    return (
        <>
            <NavigationBar
                isPreview={isPreview}
                isSignup={isSignup}
                adminPage={adminPage}
                whiteLabelInfo={whiteLabelInfo}
                isWhitelabelLoading={isGettingWhiteLabel}
                previewCloseTarget={previewCloseTarget}
            />

            <div
                id="main-container-wrapper"
                className={classes.body}
                style={{
                    backgroundColor: adminPage || lightBluePage ? colorPalette.lightBluePrimary : 'white',
                }}
            >
                {getLayoutContent()}
            </div>
            {isShowFooter && <Footer />}
        </>
    )
})
