import {
    Typography,
    FormHelperText,
    TextField,
    Button,
    Tooltip,
    Chip,
    Box,
    Switch,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect, useContext } from 'react'
import InfoIcon from '@material-ui/icons/Info'
import ChipInput from 'material-ui-chip-input'
import CloseIcon from '@material-ui/icons/Close'
import { useUpdateTeamDetailsMutation } from 'store/modules/commercialentity/commercialentity.query'
import ProgressIndicator from 'app/components/Progress/ProgressIndicator'
import { useSnackbar } from 'notistack'
import { colorPalette } from 'styles/mainTheme'
import { useGetTaxonomiesQuery } from 'store/modules/taxonomy/taxonomy.query'
import { getDrawerContext } from 'app/context/drawer/provider'
import { useGetTeamsQuery } from 'store/modules/activity/activity.query'
import _ from 'lodash'
import * as yup from 'yup'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(3),
    },
    chipInput: {
        border: 'none',
        padding: 14,
        minHeight: 60,
        borderRadius: 4,
        height: 'auto',
        paddingBottom: 8,
        '&  .MuiChip-deleteIcon': {
            color: 'rgba(0, 0, 0, 0.12) !important',
        },
    },
    autocomplete: {
        width: 'auto',
        '& .MuiChip-label': {
            color: colorPalette.black,
        },
        '& .MuiChip-root': {
            border: 'none',
        },
        '& .MuiFilledInput-root': {
            marginTop: '-10px',
            backgroundColor: colorPalette.white,
        },
        '& .MuiChip-deleteIcon': {
            color: 'rgba(0, 0, 0, 0.12) !important',
        },
        '& .MuiAutocomplete-inputRoot': {
            height: 'auto',
            padding: '12px 14px',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    errorText: {
        fontSize: 12,
    },
}))

const TeamDrawer = props => {
    const classes = useStyles()

    const drawer = useContext(getDrawerContext())

    const TeamSchema: any = yup.object().shape({
        teamType: yup.array().min(1, 'Team Type is required'),
    })

    const handleClose = () => {
        drawer.onClose()
    }

    const { teamDetails, isLoadingTeamDetails } = props

    const { data: contentTagsTaxonomy, isLoading: isLoadingContentTags } = useGetTaxonomiesQuery('content-tags/')
    const { data: marketingSourcesTaxonomy, isLoading: isLoadingMarketingSources } = useGetTaxonomiesQuery('marketing-sources/')

    const [updateTeamDetails, { isLoading: isUpdatingTeamDetails }] = useUpdateTeamDetailsMutation()

    const [teamData, setTeamData] = useState<any>({
        associatedEmailDomains: [],
        summary: '',
        websiteUrl: '',
        teamType: [],
        opportunityDataRoomAccess: [],
        contentTags: [],
        marketingSourceId: '',
        isG20Referrer: false,
    })

    const [searchTeamName, setSearchTeamName] = useState<string>('')
    const [searchQuery, setSearchQuery] = useState<string>('')

    const {
        data: teamList,
        isLoading: isLoadingTeamList,
        isFetching: isFetchingTeamList,
    } = useGetTeamsQuery({
        params: {
            display_text__icontains: searchQuery,
            type: 'IV',
        },
    })

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setSearchQuery(searchTeamName)
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTeamName])

    const handleTeamDataChange = event => {
        setTeamData({ ...teamData, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        if (!isLoadingTeamDetails && !isLoadingContentTags && !isLoadingMarketingSources) {
            setTeamData({
                associatedEmailDomains: teamDetails?.commercialEntity?.associatedEmailDomains,
                summary: teamDetails?.commercialEntity?.summary,
                websiteUrl: teamDetails?.commercialEntity?.websiteUrl,
                teamType: teamDetails?.teamType,
                opportunityDataRoomAccess: teamDetails?.opportunityDataRoomAccess,
                contentTags: teamDetails?.commercialEntity?.contentTags?.termContentItemIds?.map(
                    // @ts-ignore:next-line
                    item => contentTagsTaxonomy?.filter(tag => tag?.id === item)?.[0],
                ),
                marketingSourceId: teamDetails?.onboardingCommercialEntityPart?.marketingSourceId
                    ? marketingSourcesTaxonomy?.filter(
                          item => item.id === teamDetails?.onboardingCommercialEntityPart?.marketingSourceId,
                      )?.[0].id
                    : '',
                isG20Referrer: teamDetails?.commercialEntity?.isG20Referrer?.value,
            })
        }
    }, [
        contentTagsTaxonomy,
        isLoadingContentTags,
        isLoadingTeamDetails,
        marketingSourcesTaxonomy,
        props.teamId,
        teamDetails,
        isLoadingMarketingSources,
    ])

    type Anchor = 'right'

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return
        }
        props.onChangeOpenDrawer(open)
    }

    const handleChangeSwitch = event => {
        setTeamData({ ...teamData, [event.target.name]: event.target.checked })
    }

    const { enqueueSnackbar } = useSnackbar()

    const teamTypeOptions = [
        { role: 'Investor', id: '4jpbhqch1ttm71dkd12r23gt00' },
        { role: 'Originator', id: '4jpbhqch1ttm71dkd12r23gt01' },
        { role: 'System', id: '4tzrb4qabxv6g0fbwebdbz4jdw' },
        { role: 'Transaction', id: '41svr57v1mx5sz08fy447zawx5' },
    ]

    const [localError, setLocalError] = useState<{ [key: string]: string }>({})

    const handleEdit = async () => {
        const isValidPayload = await TeamSchema.validate(
            { teamType: teamData.teamType },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                setLocalError({})
                return true
            })
            .catch(e => {
                const currentLocalError = {}
                e.inner.forEach(e => {
                    currentLocalError[e.path] = e.message
                })
                setLocalError(currentLocalError)
                enqueueSnackbar(`Team Type is required`, { variant: 'error' })
                return false
            })

        if (!isValidPayload) return
        updateTeamDetails({
            payload: {
                ...teamData,
                contentTags: {
                    taxonomyContentItemId: teamDetails?.commercialEntity?.contentTags?.taxonomyContentItemId,
                    termContentItemIds: [...teamData.contentTags.map(item => item.id)],
                },
                onboardingCommercialEntityPart: {
                    marketingSourceId: teamData.marketingSourceId,
                },
                CommercialEntityTypeIds: teamData.teamType.map(type => teamTypeOptions.filter(e => e.role === type)[0].id),
                OpportunityDataRoomAccess: teamData.opportunityDataRoomAccess.map(item => item.contentItemId),
            },
            teamId: props.teamId,
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar('Team details updated successfully!', { variant: 'success' })
            })
            .catch((err: any) => {
                enqueueSnackbar(`An error occurred`, { variant: 'error' })
            })
    }

    const handleTeamTypeChange = (event, value) => {
        const formattedRole = value.map(item => {
            return typeof item === 'object' ? item.role : item
        })
        setTeamData({ ...teamData, teamType: formattedRole })
    }

    const handleContentTagsChange = (event, value) => {
        setTeamData({ ...teamData, contentTags: value })
    }

    const handleMarketingSourceChange = event => {
        setTeamData({ ...teamData, marketingSourceId: event.target.value })
    }

    const handleDataRoomChange = (event, newValue) => {
        setTeamData({ ...teamData, opportunityDataRoomAccess: newValue })
    }

    if (isLoadingTeamDetails || isLoadingContentTags || isLoadingMarketingSources) {
        return <ProgressIndicator />
    }

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent={'space-between'} gridColumnGap={24} data-testid="teamdrawer">
                <div>
                    <Typography variant="subtitle2">Team</Typography>
                    <Typography variant="h3" color="primary">
                        {teamDetails?.commercialEntity?.name}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '8px', marginTop: '6px' }}>
                        <Switch
                            checked={teamData.isG20Referrer}
                            size="small"
                            color="primary"
                            className="switch-small"
                            inputProps={{ 'aria-label': 'Switch demo' }}
                            onChange={handleChangeSwitch}
                            name="isG20Referrer"
                        />

                        <FormHelperText>G20 Referral Partner</FormHelperText>

                        <Tooltip title="Tooltip explaining G20">
                            <InfoIcon style={{ color: '#4299E1' }} />
                        </Tooltip>
                    </div>
                </div>
                <CloseIcon onClick={toggleDrawer('right', false)} />
            </Box>
            <div style={{ marginTop: '1rem' }}>
                <ChipInput
                    InputProps={{
                        className: classes.chipInput,
                    }}
                    label="Company"
                    fullWidth
                    variant="outlined"
                    defaultValue={[teamDetails?.company?.companyName]}
                    disabled={true}
                    chipRenderer={({ chip, className, handleClick }, key) => (
                        <Chip
                            className={className}
                            key={key}
                            label={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{chip}</span>}
                            onClick={handleClick}
                            style={{
                                background: colorPalette.chipLight,
                                borderRadius: 4,
                            }}
                        />
                    )}
                />
            </div>
            <div style={{ marginTop: '1rem' }}>
                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    id="tags-outlined"
                    onChange={handleTeamTypeChange}
                    options={!_.isEmpty(teamTypeOptions) ? teamTypeOptions?.filter(type => !teamData?.teamType?.includes(type.role)) : []}
                    getOptionLabel={option => option.role}
                    value={teamData.teamType}
                    filterSelectedOptions
                    renderInput={({ inputProps, ...rest }) => (
                        <TextField {...rest} label="Team Type" variant="outlined" inputProps={{ ...inputProps, readOnly: true }} />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            return (
                                <Chip
                                    variant="outlined"
                                    style={{
                                        backgroundColor: colorPalette.chipLight,
                                    }}
                                    label={typeof option === 'string' ? option : option.role}
                                    {...getTagProps({ index })}
                                />
                            )
                        })
                    }
                />
                <Typography color="error" className={classes.errorText}>
                    {localError['teamType']}
                </Typography>
            </div>
            <FormHelperText>By changing this the roles of the users allocated to this entity will be updated as well.</FormHelperText>
            <div style={{ marginTop: '1rem' }}>
                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    id="tags-outlined"
                    onChange={handleContentTagsChange}
                    // @ts-ignore:next-line
                    options={!_.isEmpty(contentTagsTaxonomy) ? contentTagsTaxonomy : []}
                    getOptionLabel={option => option.value}
                    value={teamData.contentTags}
                    filterSelectedOptions
                    renderInput={({ inputProps, ...rest }) => (
                        <TextField {...rest} label="Content Tag" variant="outlined" inputProps={{ ...inputProps, readOnly: true }} />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            return (
                                <Chip
                                    variant="outlined"
                                    style={{
                                        backgroundColor: colorPalette.chipLight,
                                    }}
                                    label={typeof option === 'string' ? option : option.value}
                                    {...getTagProps({ index })}
                                />
                            )
                        })
                    }
                />
            </div>

            <TextField
                style={{ marginTop: '1rem' }}
                label="Website URL"
                variant="outlined"
                value={teamData.websiteUrl}
                onChange={e => setTeamData({ ...teamData, websiteUrl: e.target.value })}
            />
            <TextField
                style={{ marginTop: '1rem' }}
                label="Summary"
                variant="outlined"
                name="summary"
                onChange={handleTeamDataChange}
                value={teamData.summary}
            />
            <ChipInput
                style={{ marginTop: '1rem' }}
                InputProps={{
                    className: classes.chipInput,
                }}
                label="Associated Email Domains"
                chipRenderer={({ value, className, handleDelete }, key) => (
                    <Chip
                        key={key}
                        onDelete={handleDelete}
                        className={className}
                        label={value}
                        style={{
                            background: colorPalette.chipLight,
                            borderRadius: 4,
                        }}
                    />
                )}
                fullWidth
                variant="outlined"
                defaultValue={teamData.associatedEmailDomains}
                onChange={chips => setTeamData({ ...teamData, associatedEmailDomains: chips })}
            />
            <FormHelperText>
                These values are automatically updated by the user's email address once the user is associated with this entity. Optionally,
                it can be updated by providing the domains one per line.
            </FormHelperText>

            <FormControl variant="outlined" style={{ marginTop: '1rem' }} className={classes.formControl}>
                <InputLabel id="marketing-source-label">Marketing Source</InputLabel>
                <Select
                    label="Marketing Source"
                    labelId="marketing-source-label"
                    id="marketing-source"
                    value={teamData.marketingSourceId}
                    onChange={handleMarketingSourceChange}
                >
                    {marketingSourcesTaxonomy?.map(source => {
                        return <MenuItem value={source.id}>{source.value}</MenuItem>
                    })}
                </Select>
            </FormControl>

            <div style={{ marginTop: '1rem' }}>
                <Autocomplete
                    freeSolo={true}
                    className={classes.autocomplete}
                    multiple
                    id="tags-outlined"
                    disabled={isLoadingTeamList}
                    onChange={handleDataRoomChange}
                    loading={isLoadingTeamList || isFetchingTeamList}
                    options={!_.isEmpty(teamList) ? teamList : []}
                    // @ts-ignore:next-line
                    getOptionLabel={option => option.displayText}
                    filterSelectedOptions
                    value={teamData.opportunityDataRoomAccess}
                    renderInput={({ inputProps, ...rest }) => (
                        <TextField
                            {...rest}
                            value={searchTeamName}
                            onChange={e => setSearchTeamName(e.target.value)}
                            label="Data Room Access"
                            variant="outlined"
                            inputProps={{ ...inputProps }}
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            return (
                                <Chip
                                    variant="outlined"
                                    style={{
                                        backgroundColor: colorPalette.chipLight,
                                    }}
                                    // @ts-ignore:next-line
                                    label={typeof option === 'string' ? option : option.displayText}
                                    {...getTagProps({ index })}
                                />
                            )
                        })
                    }
                />
            </div>

            <FormHelperText>This field controls the team’s access to the Data Room for each transaction.</FormHelperText>
            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '2rem' }}>
                <Button
                    variant="contained"
                    size="large"
                    style={{ marginRight: '1rem' }}
                    onClick={handleEdit}
                    disabled={isLoadingTeamDetails || isUpdatingTeamDetails}
                >
                    {(isLoadingTeamDetails || isUpdatingTeamDetails) && (
                        <CircularProgress size={16} style={{ marginRight: 6, color: colorPalette.primary }} />
                    )}
                    Save
                </Button>
                <Button variant="outlined" size="large" disabled={isLoadingTeamDetails || isUpdatingTeamDetails} onClick={handleClose}>
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default TeamDrawer
