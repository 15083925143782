import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'

import config from 'config/index'
import { IReduxMarketplace } from './marketplace'

const reducerPath = 'marketplaceApi'

export const marketplaceApi = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ACTIVITY_URL),
    endpoints: builder => ({
        getOpportunityPermission: builder.query<IReduxMarketplace.IOpportunityPermissionResponse, string>({
            query: opportunityId => ({
                url: `/marketplace/v1/opportunities/${opportunityId}/permissions/`,
                method: 'GET',
            }),
        }),
        getFavouriteOpportunities: builder.query<IReduxMarketplace.IFavoriteOpportunities, Object | void>({
            query: () => ({
                url: '/marketplace/v1/favourite-opportunities/',
                method: 'GET',
            }),
            transformResponse: (response: IReduxMarketplace.IFavoriteOpportunitiesResponse) => {
                const result = {}

                response.results.forEach(res => {
                    result[res.content_item_id] = true
                })

                return {
                    ...response,
                    result,
                }
            },
        }),
        getDealByDeal: builder.query<IReduxMarketplace.IDealByDealResponse, Object | void>({
            query: () => ({
                url: '/marketplace/v1/deal-by-deal/',
                method: 'GET',
            }),
        }),
    }),
})

export const { useGetOpportunityPermissionQuery, useGetFavouriteOpportunitiesQuery, useGetDealByDealQuery } = marketplaceApi

export const marketplaceQueryReducer = { [reducerPath]: marketplaceApi.reducer }
export const marketplaceQuerySelector = state => state[reducerPath]
