import { connectApiSlice } from 'store/modules/connect/connect.query'
import { investorApiSlice } from 'store/modules/investor/investor.query'
import { level2Api } from 'store/modules/level2'
import { messengerApi } from 'store/modules/messenger'
import { managementApi } from 'store/modules/management'
import { masterApiSlice } from 'store/modules/master/master.query'
import { opportunityApi } from 'store/modules/opportunity/opportunity.query'
import { originatorApiSlice } from 'store/modules/originator/originator.query'
import { scheduleApi } from 'store/modules/schedule'
import { activityApiSlice } from 'store/modules/activity/activity.query'
import { surveyApi } from 'store/modules/survey'
import { userApi, userSlice } from 'store/modules/user'
import { commercialEntityApiSlice } from 'store/modules/commercialentity/commercialentity.query'
import { removeToken } from './localStorage/token'

export const AuthUtil = {
    logout: dispatch => {
        removeToken()
        dispatch(connectApiSlice.endpoints.logout.initiate(''))
        dispatch(userSlice.actions.logout())
        dispatch(userApi.util.resetApiState())
        dispatch(surveyApi.util.resetApiState())
        dispatch(opportunityApi.util.resetApiState())
        dispatch(investorApiSlice.util.resetApiState())
        dispatch(originatorApiSlice.util.resetApiState())
        dispatch(connectApiSlice.util.resetApiState())
        dispatch(masterApiSlice.util.resetApiState())
        dispatch(scheduleApi.util.resetApiState())
        dispatch(level2Api.util.resetApiState())
        dispatch(messengerApi.util.resetApiState())
        dispatch(managementApi.util.resetApiState())
        dispatch(activityApiSlice.util.resetApiState())
        dispatch(commercialEntityApiSlice.util.resetApiState())
    },
}
