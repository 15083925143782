import { Typography } from '@material-ui/core'
import { useState, useEffect, ReactNode } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { colorPalette } from 'styles/mainTheme'
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined'

import './UploadFiles.css'

export interface IUploadFilesChildren {
    files: File[]
    reset: Function
}

export interface IUploadFiles {
    testId?: string
    placeholder?: string
    isOpen?: boolean // detect close modal for resetting files
    dropzoneOptions?: DropzoneOptions
    isHideInputAfterUpload?: boolean
    children?: ({ files, reset }: IUploadFilesChildren) => ReactNode
}

const UploadFiles = ({
    isOpen,
    placeholder = 'Drag and drop',
    isHideInputAfterUpload = false,
    dropzoneOptions,
    children,
    testId = 'dropzone-input',
}: IUploadFiles) => {
    const [isShowInput, setIsShowInput] = useState<boolean>(true)
    const [files, setFiles] = useState<File[]>([])

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles)
            if (acceptedFiles.length && isHideInputAfterUpload) {
                setIsShowInput(false)
            }
        },

        ...dropzoneOptions,
    })

    const reset = () => {
        setFiles([])
        setIsShowInput(true)
    }

    useEffect(() => {
        if (isOpen === false) {
            reset()
        }
    }, [isOpen])

    return (
        <>
            {((isHideInputAfterUpload && isShowInput) || !isHideInputAfterUpload) && (
                <>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} data-testid={testId} />
                        <CloudUploadIcon className="uploadIcon" />
                        <p data-testid={`${testId}-placeholder`} style={{ fontSize: '1.1rem' }}>
                            {placeholder} or <u style={{ color: colorPalette.primary, fontWeight: 600 }}>browse</u> to choose file
                        </p>
                        <br />
                        <p>Supported file formats include: JPG, PNG, SVG</p>
                    </div>
                    <div data-testid="upload-errors">
                        {fileRejections?.map(file => {
                            return (
                                <Typography variant="caption" color="error">
                                    {file.file.name}: {file.errors?.map(err => err.message).join(', ')}
                                </Typography>
                            )
                        })}
                    </div>
                </>
            )}

            {children?.({ files, reset })}
        </>
    )
}

export default UploadFiles
