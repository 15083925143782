import { useEffect, useState } from 'react'

import { useGetGroupsQuery, useGetReportsMutation } from 'store/modules/report'
import { ReduxReportTypeNamespace } from 'store/modules/report/report'
import { userApi } from 'store/modules/user'

/**
 * A hooks to get all of reports from each group
 */
export const useReports = () => {
    const [reports, setReports] = useState<
        (ReduxReportTypeNamespace.IGetReportResponse & { group: ReduxReportTypeNamespace.IGetGroupResponse })[]
    >([])
    const { data } = userApi.endpoints.getUserProfile.useQueryState({})

    const { data: groups = [], isLoading: isLoadingGroup, error } = useGetGroupsQuery({}, { skip: !data?.userType.isSuperAdmin })
    const [getReport] = useGetReportsMutation()

    useEffect(() => {
        const collectReport = async () => {
            let allReports: (ReduxReportTypeNamespace.IGetReportResponse & { group: ReduxReportTypeNamespace.IGetGroupResponse })[] = []

            const reports = await Promise.all(
                groups.map(async group => {
                    const reports = await getReport(group.id).unwrap()
                    return reports.map(report => {
                        return {
                            ...report,
                            group,
                        }
                    })
                }),
            )

            reports.forEach(report => {
                allReports = [...allReports, ...report]
            })

            setReports(allReports)
        }

        if (!isLoadingGroup && !error && !reports.length && groups.length) {
            collectReport()
        }
    }, [error, getReport, groups, isLoadingGroup, reports.length])

    return reports
}
