import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import { CircularProgress, Backdrop, Box, Button, Fade, Modal, Typography } from '@material-ui/core'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { RootState } from 'store'
import { colorPalette } from 'styles/mainTheme'
import { userSlice } from 'store/modules/user'
import { useRefreshTokenMutation } from 'store/modules/connect/connect.query'
import { getToken, setToken } from 'utils/localStorage/token'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthUtil } from 'utils/auth'
import config from 'config/index'
import { masterApiSlice } from 'store/modules/master/master.query'
import { getHostname } from 'utils/general'
import { generalSlice } from 'store/modules/general'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: 400,
            padding: theme.spacing(3),
            backgroundColor: colorPalette.lightgray,
            borderRadius: theme.spacing(0.5),
            boxShadow: '0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)',
            outline: 'none',
        },
        modalButton: {
            marginRight: '5px',
            marginLeft: '5px',
        },
    }),
)

type Props = {
    children?: JSX.Element
    fullPage?: boolean
    adminPage?: boolean
    ddqPage?: boolean
    footer?: boolean
    isLoading?: boolean
    lightBluePage?: boolean
    authenticate?: boolean
    debug?: boolean
}

let timeOutInterval
export const ONE_MINUTE = 1000 * 60
const ONE_HOUR = ONE_MINUTE * 60
const ONE_DAY = ONE_HOUR * 24

/**
 * timeout will trigger after 25 minutes of inactivity
 */
const TIMEOUT = ONE_MINUTE * 25
/**
 * timeout will trigger after 120 minutes of inactivity
 */
const TIMEOUT_FINITIVE_ACCOUNT = ONE_MINUTE * 120

/**
 * REFRESH_TOKEN_LIFETIME
 *
 * Should be greater than access token lifetime (TIMEOUT)
 * Refresh token lifetime is 90 minutes
 */
const REFRESH_TOKEN_LIFETIME = TIMEOUT + ONE_MINUTE * 65
/**
 * FINITIVE_REFRESH_TOKEN_LIFETIME
 *
 * Should be greater than access token lifetime (TIMEOUT_FINITIVE_ACCOUNT)
 * Nothing refresh token lifetime because the refresh token "depend on" the redirect url session
 * Because of this, for now we will set it to 1 month
 */
const FINITIVE_REFRESH_TOKEN_LIFETIME = TIMEOUT_FINITIVE_ACCOUNT + ONE_DAY * 30
const LOGOUT_TIMER_IN_SECOND = 30 // 30s

/**
 * A timer that will show the popup to user for determining the session.
 * There's 2 mechanisme timeout:
 * - Username-Password: We will call the refresh token API with the current refresh token to get the new access and refresh token
 * - Finitive Account: This login doesn't provide the refresh token, so to update to "access token", we need to redirect the user to `${config.AUTH_URL}/login?client_id=${clientId}`
 * @param debug to show the timer status in console
 * @returns
 */
export default function ReactIdleTimer({ adminPage, debug }: Props) {
    const classes = useStyles(adminPage)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const access_token = getToken('access_token')

    const [isStarted, setIsStarted] = useState(false) // A state that used as a flag that the timer already started
    const [isPaused, setIsPaused] = useState(false) // A state that used as a flag that the timer already paused
    const [isIdle, setIsIdle] = useState(false) // A state that used as a flag to indicate there's nothing activity in browser
    const [isShowTimeoutModal, setIsShowTimeoutModal] = useState(false) // A state that used to determine when the popup should be shown
    const [logoutTimer, setLogoutTimer] = useState(-1) // A state that used as a counter in the popup

    const { isExpired, loginAt, loginBy } = useSelector((state: RootState) => state.user || {})

    const selectedTimeout = loginBy === 'FINITIVE_ACCOUNT' ? TIMEOUT_FINITIVE_ACCOUNT : TIMEOUT
    const refreshTokenLifetime = loginBy === 'FINITIVE_ACCOUNT' ? FINITIVE_REFRESH_TOKEN_LIFETIME : REFRESH_TOKEN_LIFETIME

    const hostname = getHostname()
    const { data: whitelabelInfoData } = masterApiSlice.endpoints.getWhiteLabelInfo.useQueryState(
        { website: hostname },
        { skip: !hostname },
    )
    const [refreshToken, { isLoading: isLoadingRefreshToken }] = useRefreshTokenMutation()

    /**
     * Prioritizing clientId from whitelabel instead of environment
     */
    const clientId = whitelabelInfoData?.webConfiguration?.clientId || config.CLIENT_ID

    /**
     * Clearing a logout timer when some conditions, such as:
     * 1. Logged out
     * 2. clicked continue button in timeout modal
     */
    const clearLogoutTimer = () => {
        setLogoutTimer(-1)
        clearInterval(timeOutInterval)
    }

    /**
     * Function that called inside an interval function to decrease a time (count down)
     */
    const setTimer = () => {
        setLogoutTimer(time => time - 1)
    }

    const handleOnIdle = _event => {
        setIsIdle(true)
    }

    const handleOnActive = _event => {
        setIsIdle(false)
    }

    const handleOnAction = _event => {
        if (!isShowTimeoutModal) {
            setIsIdle(false)
        }
    }

    const { start, reset, pause, getRemainingTime } = useIdleTimer({
        timeout: selectedTimeout,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500,
        crossTab: {
            type: 'localStorage',
            emitOnAllTabs: true,
        },
        startManually: true,
        startOnMount: false,
    })

    const date = new Date()
    const getRemainingTimeSecond = Math.floor(getRemainingTime() / 1000)

    /**
     * Activate this debug feature by add props "debug"
     *
     * @example <ReactIdleTimer debug />
     *
     * Debugging timeout based on
     * 1. Is popup show in the correct time?
     * 2. Is automatically logout when user ignoring countdown in the popup?
     * 3. Is popup will disappear when user click continue?
     */
    if (debug) {
        if (isShowTimeoutModal) {
            console.log(`SESSION TIMEOUT PAUSED - WAITING POPUP`)
        } else if (getRemainingTimeSecond > 0) {
            console.log(`SESSION TIMEOUT REMAINING TIME: ${getRemainingTimeSecond}s`)
            console.log('-- start at: ', date)
            console.log('-- popup at: ', new Date(date.setMilliseconds(date.getMilliseconds() + selectedTimeout)))
        } else if (!isStarted) {
            console.log(`SESSION TIMEOUT STOPPED`)
        }
    }

    /**
     * Start the main timer with fresh condition
     */
    const _start = useCallback(() => {
        if (debug) {
            console.log('SESSION TIMEOUT STARTED')
        }

        /**
         * IMPORTANT
         * Reset the state.
         * Make sure the order is correct
         * We need setIsShowTimeoutModal to "false" first than setIsPaused.
         * Why?
         * If we do setIsPaused to "false" first it will trigger the useEffect,
         * and running the pause action again because the IsShowTimeoutModal still true
         */
        setIsShowTimeoutModal(false)
        setIsPaused(false)
        setIsIdle(false)

        setIsStarted(true)
        start()
        reset()
    }, [debug, reset, start])

    /**
     * Continuing current session.
     * This function called in timeout modal.
     */
    const onContinue = () => {
        // Clear first to prevent force logout
        clearLogoutTimer()

        const refreshTokenVal = getToken('refresh_token')

        if (refreshTokenVal)
            refreshToken({ refresh_token: refreshTokenVal, client_id: clientId }).then(response => {
                // @ts-ignore
                const { access_token, refresh_token } = response?.data || {}
                if (access_token && refresh_token) {
                    setToken(
                        JSON.stringify({
                            // @ts-ignore
                            access_token: response.data.access_token,
                            // @ts-ignore
                            refresh_token: response.data.refresh_token,
                            // @ts-ignore
                            token_type: response.data.token_type,
                        }),
                    )
                }
                _start()
            })
        else if (typeof window !== 'undefined') {
            // Save the previous path before redirecting
            dispatch(generalSlice.actions.setRedirectToPath(location.pathname))

            window.location.href = `${config.AUTH_URL}/login?client_id=${clientId}`
        }
    }

    const onLogout = useCallback(() => {
        if (debug) {
            console.log('STOPPING SESSION TIMEOUT')
        }

        // TODO: add API call for logout
        AuthUtil.logout(dispatch)
        dispatch(userSlice.actions.setExpired(true))

        history.push('/')
    }, [debug, dispatch, history])

    /**
     * TIMER - Managing main timer status
     */
    useEffect(() => {
        // Start the main timer when user logged in with condition timer not started yet
        if (access_token && !isStarted && loginBy) {
            _start()
        }

        /**
         * When user logged out,
         * We need to:
         * 1. Stop the main timer
         * 2. Clear the logout timer
         * 3. Makesure the timeout modal closed
         */
        if (!access_token) {
            // Stop main timer when the timer started
            if (isStarted && !isShowTimeoutModal) {
                setIsStarted(false)
            }

            /**
             * just for stop the main timer
             * when the main timer started we will reset time timer instead of continue/resume
             */
            pause()
            clearLogoutTimer()
            setIsShowTimeoutModal(false)
            setIsPaused(true)
            setIsIdle(false)
        }

        /**
         * Pause timer when timeout modal appeared.
         * Fyi, There's another counter when popup appear called "logout timer"
         */
        if (isShowTimeoutModal && !isPaused) {
            pause()
            setIsPaused(true)
        }
    }, [isPaused, isShowTimeoutModal, isStarted, pause, reset, access_token, _start, loginBy])

    // LOGOUT TIMER - Start logout timer when modal opened
    useEffect(() => {
        if (isShowTimeoutModal) {
            setLogoutTimer(LOGOUT_TIMER_IN_SECOND)
            timeOutInterval = setInterval(() => {
                setTimer()
            }, 1000)
        }
    }, [isShowTimeoutModal])

    // LOGOUT TIMER - Force logout when logout timer is 0 in timeout modal
    useEffect(() => {
        if (timeOutInterval && isShowTimeoutModal && logoutTimer === 0) {
            console.log('Force logout because user not answered the timeout modal')
            onLogout()
        }
    }, [onLogout, isShowTimeoutModal, logoutTimer])

    // Force logout when "isExpired" true from localstorage
    useEffect(() => {
        // token expired
        if (access_token && isExpired) {
            console.log('Force logout because token already expired')
            onLogout()
        }
    }, [access_token, isExpired, onLogout])

    // MAIN TIMER - Handle idle timeout here
    useEffect(() => {
        if (isIdle) {
            if (access_token && loginAt && loginAt > 0) {
                const nowDate = new Date().getTime() / 1000
                const diffDate = nowDate - loginAt

                const refreshTokenLifetimeInSecond = refreshTokenLifetime / 1000

                if (diffDate > refreshTokenLifetimeInSecond) {
                    // Force logout user when refresh token lifetime is over
                    console.log('Force logout because life time token is over')
                    onLogout()
                } else {
                    setIsShowTimeoutModal(true)
                }
            }
        }
    }, [access_token, dispatch, isIdle, loginAt, onLogout, refreshTokenLifetime, selectedTimeout])

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isShowTimeoutModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isShowTimeoutModal}>
                    <Box className={classes.modal}>
                        <Box display="flex" alignItems="center" mb={4}>
                            <Typography id="transition-modal-title" variant="h6">
                                Your session is about to timeout. Click continue to extend your session
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="end">
                            <Button disabled={isLoadingRefreshToken} variant="outlined" className={classes.modalButton} onClick={onLogout}>
                                Logout {isLoadingRefreshToken || logoutTimer < 0 ? '' : <>&nbsp; {logoutTimer}s</>}
                            </Button>
                            <Button
                                disabled={isLoadingRefreshToken}
                                variant="contained"
                                className={classes.modalButton}
                                onClick={onContinue}
                            >
                                {isLoadingRefreshToken ? (
                                    <>
                                        <CircularProgress size={14} />
                                        &nbsp;&nbsp;
                                    </>
                                ) : (
                                    ''
                                )}
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}
