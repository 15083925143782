import React, { memo, useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery, useTheme, Divider, Tooltip, CircularProgress, FormControlLabel, Switch } from '@material-ui/core'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { colorPalette } from 'styles/mainTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { SearchInput } from 'app/components/SearchInput/SearchInput'
import ProgressIndicator from 'app/components/Progress/ProgressIndicator'
import { cognitiveSearchApi } from 'store/modules/cognitive-search'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root1: {
            border: '1px solid #D8D8D8',
            backgroundColor: colorPalette.white,
            borderRadius: theme.spacing(1),
        },
        title: {
            display: 'flex',
            gap: theme.spacing(1.25),
            fontSize: 12,
        },
        notice: {
            display: 'flex',
            gap: theme.spacing(1.25),
            marginLeft: theme.spacing(0.75),
            fontSize: 8,
        },
        content: {
            display: 'flex',
            fontSize: 12,
        },
        subtitle: {
            fontSize: 12,
            fontWeight: 600,
            marginLeft: theme.spacing(4.25),
            cursor: 'pointer',
        },
    }),
)

const tooltipTitleFormatting = (sourceNode, matches, index) => {
    const file_name =
        sourceNode && sourceNode[parseInt(matches[index].replace(/\[|\]/g, '')) - 1]?.node.extra_info.file_name.replace(/%2/g, ' ') && 'N/A'
    const page_label = sourceNode && sourceNode[parseInt(matches[index].replace(/\[|\]/g, '')) - 1]?.node.extra_info.page_label && 'N/A'
    const text = sourceNode && sourceNode[parseInt(matches[index].replace(/\[|\]/g, '')) - 1]?.node.text && 'N/A'

    if (file_name !== 'N/A') {
        return (
            <span>
                <strong>Filename:</strong> {file_name}
                <br /> <strong>Page:</strong> {page_label !== 'N/A' ? page_label : '...' + text.split(':')[1]}
            </span>
        )
    } else {
        return text
    }
}

const formatTextWithCitations = (text, responseContent) => {
    const _sources = responseContent.source_nodes
    const citationRegex = /\[\d+\]/g
    const parts = text.split(citationRegex)
    const matches = text.match(citationRegex)

    return parts.map((part, index) => (
        <React.Fragment key={index}>
            {part}
            {matches && matches[index] && (
                <Tooltip title={tooltipTitleFormatting(_sources, matches, index)}>
                    <sup style={{ cursor: 'default' }}>{matches[index]}</sup>
                </Tooltip>
            )}
        </React.Fragment>
    ))
}

const basicHighlights = (line, index, elementName, responseContent) => {
    const numericPattern = /((\$|€|£|¥)?\d{1,3}(,\d{3})*(\.\d+)?(\s*(documents listed|units owned|months|million))?)\b/g
    let match,
        lastEndIndex = 0
    const parts = []

    while ((match = numericPattern.exec(line))) {
        const textBefore = line.slice(lastEndIndex, match.index)
        if (textBefore) {
            parts.push(textBefore)
        }

        if (
            match[0].includes('$') ||
            match[0].includes('€') ||
            match[0].includes('£') ||
            match[0].includes('¥') ||
            match[0].includes('documents listed') ||
            match[0].includes('units owned') ||
            match[0].includes('months') ||
            match[0].includes('million')
        ) {
            if (elementName) {
                let parentName = elementName // replace with the actual name
                let parentElement = document.querySelector(`div[name='${parentName}']`)
                let inputField = parentElement.querySelector('input')
                parts.push(
                    <Tooltip key={index} title="Click to add this value" arrow>
                        <strong
                            style={{ cursor: 'pointer' }}
                            onClick={event => (inputField.value = event.target.innerHTML)}
                            title="Click to add this value"
                            key={index}
                        >
                            {match[0]}
                        </strong>
                    </Tooltip>,
                )
            } else {
                parts.push(<strong>{match[0]}</strong>)
            }
        } else {
            parts.push(match[0])
        }
        lastEndIndex = match.index + match[0].length
    }
    if (lastEndIndex < line.length) {
        parts.push(line.slice(lastEndIndex))
    }
    if (responseContent && parts.length > 0) {
        return <span key={index}>{parts}</span>
    }
}

const formatCitationsToHTML = sourceNodes => {
    // Add tooltips for source_nodes

    if (sourceNodes) {
        const formattedLines: JSX.Element[] = []
        let _index = 1
        sourceNodes.forEach(
            (sourceNode: {
                node: {
                    text: any
                    id_: any
                    extra_info: any
                }
            }) => {
                const file_name = sourceNode.node.extra_info.file_name?.replace(/%2/g, ' ') ?? 'N/A'
                const page_label = sourceNode.node.extra_info.page_label ?? 'N/A'
                if (file_name !== 'N/A') {
                    formattedLines.push(
                        <Tooltip
                            key={sourceNode.node.id_}
                            title={
                                <span>
                                    <strong>Filename:</strong> {file_name}
                                    <br /> <strong>Page:</strong> {page_label}
                                </span>
                            }
                            arrow
                        >
                            <span style={{ paddingLeft: '5px', cursor: 'default' }}> [ {_index} ] </span>
                        </Tooltip>,
                    )
                }
                _index += 1 // Increment _index inside the loop
            },
        )

        return <span>{formattedLines}</span>
    }
}

const formatTextToHTML = (text, elementName, responseContent) => {
    const lines = text.split('\n')

    const formattedLines = lines.map((line, index) => {
        if (line.startsWith('-')) {
            return (
                <li key={index} style={{ marginLeft: '20px' }}>
                    {basicHighlights(formatTextWithCitations(line.slice(1), responseContent), index, elementName, responseContent)}
                </li>
            )
        } else if (line.includes(':')) {
            const [label, value] = line.split(':')
            return (
                <div key={index}>
                    <strong>{label.trim()}:</strong>{' '}
                    {basicHighlights(formatTextWithCitations(value.trim(), responseContent), index, elementName, responseContent)}
                </div>
            )
        } else {
            return basicHighlights(formatTextWithCitations(line, responseContent), index, elementName, responseContent)
        }
    })

    return <ul style={{ paddingLeft: '5px' }}>{formattedLines}</ul>
}

export default memo(function DDQAICard(props: {
    isOverview?: boolean
    isBSSDDq?: boolean
    ddq_id?: string
    initialQuestion?: string
    elementName?: string
}) {
    const classes = useStyles()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const max1500 = useMediaQuery('(max-width:1500px)')
    const [execAI, setexecAI] = React.useState<boolean>(false)
    const [plainTextContent, setPlainTextContent] = React.useState<string>('')
    const [responseContent, setResponseContent] = React.useState<any>({})

    const [citationTextContent, setCitationTextContent] = React.useState<string>('')
    // const [includeCitations, setIncludeCitations] = useState(false)

    const [searched, setSearched] = React.useState<string>('')
    const [includeCitations, setIncludeCitations] = useState(false)
    const [question, setQuestion] = React.useState<string>(
        props.isOverview
            ? ''
            : props.initialQuestion
            ? props.initialQuestion
            : 'Can you summarize the following in bullet form: the company name, the company objectives and highlights, the Team, balanace sheet total assets and liabilities, and the company offering?',
    )
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeCitations(event.target.checked)
    }

    const cognitiveOpportunityAIAnswerQuery = cognitiveSearchApi.useGetOpportunityAIAnswerQuery(
        {
            question: question,
            ddq_id: props.ddq_id,
            include_citations: includeCitations,
        },
        { refetchOnMountOrArgChange: true, skip: !props.ddq_id || (props.isOverview && question === '') },
    )

    const { data: AIStatus, refetch: refetchAIStatus } = cognitiveSearchApi.useGetOpportunityAIStatusQuery(props.ddq_id, {
        skip: !props.ddq_id,
    })

    const isLoadingAI = cognitiveOpportunityAIAnswerQuery.isLoading
    const defaultAnswer = !props.isOverview ? 'Upload documents for this application in order to see results.' : ''

    // const isLoadingAI = cognitiveOpportunityAIAnswerQuery.isLoading
    const requestSearch = (searchedVal: string) => {
        setSearched(searchedVal)
    }

    const handleEnterSearch = e => {
        if (e.key === 'Enter') {
            fetchAIAnswer(searched, false)
        }
    }

    // ...

    const [isProcessing, setIsProcessing] = useState<boolean>(false)

    useEffect(() => {
        const interval = setInterval(() => {
            refetchAIStatus()
        }, 20000)

        return () => {
            clearInterval(interval)
        }
    }, [refetchAIStatus])

    useEffect(() => {
        if (AIStatus) {
            setIsProcessing(AIStatus && AIStatus.status === 'processing')
        }
    }, [AIStatus])

    useEffect(() => {
        setexecAI(isLoadingAI || cognitiveOpportunityAIAnswerQuery.status === 'pending')
    }, [isLoadingAI, cognitiveOpportunityAIAnswerQuery])

    useEffect(() => {
        setPlainTextContent(cognitiveOpportunityAIAnswerQuery.data?.answer?.response ?? defaultAnswer)
        setResponseContent(cognitiveOpportunityAIAnswerQuery.data?.answer ?? defaultAnswer)
    }, [cognitiveOpportunityAIAnswerQuery.data, defaultAnswer])

    useEffect(() => {
        setCitationTextContent(cognitiveOpportunityAIAnswerQuery.data?.answer?.source_nodes)
    }, [cognitiveOpportunityAIAnswerQuery.data])

    const fetchAIAnswer = async (question, is_default = true) => {
        // POST request using fetch inside useEffect React hook
        setexecAI(true)
        if (props.ddq_id !== '') {
            try {
                await setQuestion(question)
                // Wait for the API response

                setPlainTextContent(cognitiveOpportunityAIAnswerQuery.data?.answer?.response ?? defaultAnswer)
            } catch {
                //setPlainTextContent('Upload documents to see a summary here.')
            }
        }
        // setexecAI(false)
    }

    const cancelSearch = () => {
        setSearched('')
    }

    const getBody = () => {
        return (
            <ThemeProvider theme={theme}>
                <Box paddingTop={2.5}>
                    {execAI ? (
                        <div
                            style={{
                                zIndex: 9999,
                                backgroundColor: 'rgba(255,255,255,0.5)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingBottom: '35px',
                            }}
                        >
                            <ProgressIndicator />
                        </div>
                    ) : (
                        <>
                            <Typography color="primary" className={classes.content}>
                                {formatTextToHTML(plainTextContent, props.elementName, responseContent)}
                            </Typography>
                            {includeCitations && plainTextContent ? (
                                <Typography color="primary" className={classes.content}>
                                    Sources:
                                    {formatCitationsToHTML(citationTextContent)}
                                </Typography>
                            ) : null}
                            {plainTextContent ? (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={includeCitations}
                                            onChange={handleToggleChange}
                                            // size="small"
                                            // style={{ transform: 'scale(0.75)' }}
                                        />
                                    }
                                    label={includeCitations ? 'Show Sources' : 'Show Sources'}
                                />
                            ) : null}
                        </>
                    )}
                </Box>
            </ThemeProvider>
        )
    }

    if (props.isOverview) {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography component={'span'} variant="h2">
                            Deal Q&A
                        </Typography>
                        {isProcessing ? (
                            <Box display="flex" alignItems="center">
                                <CircularProgress size={10} />
                                <Typography color="primary" className={classes.notice}>
                                    AI Processing Documents
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                    <div style={{ marginLeft: '-16px', marginRight: '16px', marginTop: '5px' }}>
                        <SearchInput
                            value={searched}
                            onChange={searchVal => requestSearch(searchVal)}
                            onClose={cancelSearch}
                            onKeyDown={e => handleEnterSearch(e)}
                            placeholder={props.initialQuestion ? props.initialQuestion : 'Ask AI'}
                            style={{ maxWidth: '100%' }}
                        />
                    </div>
                </Box>

                {getBody()}
            </div>
        )
    } else if (props.isBSSDDq && !props.initialQuestion) {
        return (
            <div style={{ paddingBottom: '20px' }}>
                <Box
                    data-testid="ddq-ai-card"
                    className={classes.root1}
                    padding={2.5}
                    margin="auto"
                    width={isSmall ? 'auto' : 255}
                    position={(props?.isBSSDDq as any) ? 'inherit' : isSmall ? 'initial' : max1500 ? 'inherit' : 'absolute'}
                >
                    <Box paddingBottom={2.5}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography color="primary" className={classes.title}>
                                Deal Q&A
                            </Typography>
                            {isProcessing ? (
                                <Box display="flex" alignItems="center">
                                    <CircularProgress size={10} />
                                    <Typography color="primary" className={classes.notice}>
                                        AI Processing Documents
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                        <div style={{ marginLeft: '-16px', marginRight: '16px', marginTop: '5px' }}>
                            <SearchInput
                                value={searched}
                                onChange={searchVal => requestSearch(searchVal)}
                                onClose={cancelSearch}
                                onKeyDown={e => handleEnterSearch(e)}
                                placeholder={props.initialQuestion ? props.initialQuestion : 'Ask AI'}
                            />
                        </div>
                    </Box>
                    <Divider />
                    {getBody()}
                </Box>
            </div>
        )
    } else {
        return (
            <div style={{ padding: '20px' }}>
                {' '}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <circle cx="12" cy="12" r="11.5" fill="#ffffff" stroke="#013c4d" />
                    <circle cx="12" cy="8.5" r="3.5" fill="#013c4d" />
                    <circle cx="8" cy="15" r="1.5" fill="#013c4d" />
                    <circle cx="16" cy="15" r="1.5" fill="#013c4d" />
                    <path d="M12 18.5c2.45 0 4.5-1.677 4.5-3.75h-9c0 2.073 2.05 3.75 4.5 3.75z" fill="#013c4d" />
                </svg>
                {getBody()}
            </div>
        )
    }
})
