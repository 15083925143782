import { ModalProvider } from './modal/provider'
import { DrawerProvider } from './drawer/provider'
import { CommentProvider } from './comment/provider'

export const Context = ({ children }) => {
    return (
        <ModalProvider>
            <DrawerProvider>
                <CommentProvider>{children}</CommentProvider>
            </DrawerProvider>
        </ModalProvider>
    )
}
