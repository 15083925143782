import React, { useCallback } from 'react'

import { getDrawerContext } from '../provider'
import { ICommentDrawerPropsData } from '../definition'

import { CommentRoomDrawer } from 'app/components/Comment/CommentRoomDrawer'

export const CommentDrawer = () => {
    const drawer = React.useContext(getDrawerContext<ICommentDrawerPropsData>())

    const onClose = useCallback(() => {
        drawer.onClose()
    }, [drawer])

    if (drawer && drawer.props && drawer.isOpen && drawer.category === 'comment') {
        return (
            <CommentRoomDrawer
                filter={{
                    query: drawer.props.query || '',
                    id: drawer.props.id,
                    sourceId: drawer.props.sourceId,
                    status: drawer.props.status,
                    type: drawer.props.type,
                }}
                onClose={onClose}
            />
        )
    }

    return null
}
