import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IReduxSurvey } from 'store/modules/survey/survey'
import { surveyApi } from 'store/modules/survey/survey.query'
import { toSliceMatcher } from 'utils/survey/transformer'
import { userSlice } from '../user'

const reducerName = 'survey'

export const initialState: IReduxSurvey.IInitialState = {
    status: '',
    title: '',
    description: '',
    progress: 0,
    activePageName: '',
    page: {},
    pages: [],
}

export const surveySlice = createSlice({
    name: reducerName,
    initialState,
    reducers: {
        navigate: (state, { payload: isNext = true }: PayloadAction<boolean>) => {
            state.activePageName = state.pages[state.pages.indexOf(state.activePageName) + (isNext ? 1 : -1)]
        },
        setActivePage: (state, payload: PayloadAction<string>) => {
            state.activePageName = payload.payload
        },
        setMainProgress: (state, { payload }: PayloadAction<number>) => {
            state.progress = payload
        },
        setProgress: (state, { payload }: PayloadAction<{ name: string; progress: number }>) => {
            state.page = {
                ...state.page,
                [payload.name]: {
                    ...state.page[payload.name],
                    progress: payload.progress,
                },
            }
        },
        clear: state => {
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key] || ''
            })
        },
    },
    extraReducers: builder => {
        builder.addCase(userSlice.actions.logout, (state, action) => {
            Object.keys(initialState).forEach(keyState => {
                state[keyState] = initialState[keyState]
            })
        })
        builder.addMatcher(surveyApi.endpoints.getStatusDDQ.matchFulfilled, (state, { payload }) => {
            state.status = payload.status
            state.progress = payload.percentageComplete
        })
        builder.addMatcher(surveyApi.endpoints.getSurveyDDQ.matchFulfilled, (state, { payload }) => {
            toSliceMatcher(state, payload)
        })
        builder.addMatcher(surveyApi.endpoints.getSurveyInvestorPreference.matchFulfilled, (state, { payload }) => {
            toSliceMatcher(state, payload)
        })
        builder.addMatcher(surveyApi.endpoints.getSurveyPatentLitigation.matchFulfilled, (state, { payload }) => {
            toSliceMatcher(state, payload)
        })
        builder.addMatcher(surveyApi.endpoints.getSurveyOpportunityDDQ.matchFulfilled, (state, { payload }) => {
            toSliceMatcher(state, payload)
        })
    },
})

export const surveySelector = (state: any) => state[reducerName] as IReduxSurvey.IInitialState
export const surveySliceReducer = { [reducerName]: surveySlice.reducer }
