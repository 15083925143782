/**
 * Backend named this as TypeChoices.
 * Ref: https://github.com/Finitive-LLC/Finitive-API-Services/blob/develop/comments/models.py
 */
enum CommentContentType {
    Opportunity = 'opportunity',
    'Application Submission' = 'applicationsubmission',
}

enum CommentStatus {
    Draft = 'Draft',
    Publish = 'Publish',
    Archive = 'Archive',
    Resolve = 'Resolve',
}

// If this dynamic, what's the endpoint or where is the source
enum ParticipatnContentType {
    Team = 'team',
    User = 'finitiveuser',
}

export { CommentContentType, CommentStatus, ParticipatnContentType }
