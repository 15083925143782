import { createApi } from '@reduxjs/toolkit/query/react'
import config from 'config/index'
import { default as moment } from 'moment'
import http from 'utils/http'
import { IReduxSchedule } from './schedule'
import _ from 'lodash'

const reducerPath = 'scheduleApi'

export const scheduleApi = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ALGO_API_URL),
    tagTypes: ['SCHEDULE', 'AVAILABILITY'],
    endpoints: builder => ({
        getSchedules: builder.query({
            query: ({ schedule_endpoint }) => ({
                url: _.isEmpty(schedule_endpoint) ? '/scheduling/schedule/' : schedule_endpoint,
                method: 'GET',
            }),
            transformResponse: (response: IReduxSchedule.IGetScheduleResponse) => {
                const myEventList: Array<IReduxSchedule.IMeetDetails> = []
                response.meetings.forEach(item => {
                    return item.meetingTimes?.forEach(time => {
                        const rawEndTime = moment(time?.datetimeUtc).add(time.durationMinutes, 'm').format()

                        myEventList.push({
                            datetimeUtc: time.datetimeUtc,
                            end: moment(rawEndTime).add(moment(rawEndTime).utcOffset(), 'm').utc().format(),
                            transactionId: item.transactionId,
                            meetingId: item.id,
                            investorSetCompanyName: item.investorSetCompanyName,
                            meetingStatus: item.status,
                            statusComments: item.statusComments,
                            meetingTimeId: time.id,
                            description: item.description,
                            joinUrl: item.joinUrl,
                            myRoles: item.myRoles,
                            durationMinutes: time.durationMinutes,
                            meetingParticipants: item.meetingParticipants,
                        })
                    })
                })
                return myEventList
            },
            providesTags: ['SCHEDULE'],
        }),
        createSchedules: builder.mutation({
            query: body => ({
                url: '/scheduling/schedule/',
                method: 'POST',
                body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(
                        scheduleApi.util.updateQueryData('getSchedules', { schedule_endpoint: '/scheduling/schedule/' }, (draft: any) => {
                            const formatted: Array<IReduxSchedule.IMeetDetails> = []

                            const payload = data.meetings[data.meetings.length - 1]
                            payload.meetingTimes?.forEach(time => {
                                const rawEndTime = moment(time?.datetimeUtc).add(time.durationMinutes, 'm').format()

                                formatted.push({
                                    datetimeUtc: time.datetimeUtc,
                                    end: moment(rawEndTime).add(moment(rawEndTime).utcOffset(), 'm').utc().format(),
                                    transactionId: payload.transactionId,
                                    meetingId: payload.id,
                                    investorSetCompanyName: payload.investorSetCompanyName,
                                    meetingStatus: payload.status,
                                    myRoles: payload.myRoles,
                                    meetingTimeId: time.id,
                                    description: payload.description,
                                    joinUrl: payload.joinUrl,
                                    durationMinutes: time.durationMinutes,
                                    meetingParticipants: payload.meetingParticipants,
                                })
                            })

                            formatted.forEach(item => draft.push(item))
                        }),
                    )
                } catch (err) {
                    console.log(err, 'err')
                }
            },
        }),
        confirmMeetingOriginator: builder.mutation({
            query: body => ({
                url: `/scheduling/confirm/`,
                method: 'POST',
                body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    dispatch(
                        scheduleApi.util.updateQueryData('getSchedules', { schedule_endpoint: '/scheduling/schedule/' }, (draft: any) => {
                            const confirmed = draft.filter(item => item.meetingId === body.meetingId)

                            if (confirmed) {
                                return confirmed.forEach((item: any) => {
                                    if (item.meetingTimeId === body.meetingTimeId) {
                                        item.meetingStatus = 'Confirmed'
                                    } else {
                                        item.meetingStatus = 'Rejected'
                                    }
                                })
                            }
                        }),
                    )
                } catch (err) {
                    console.log(err, 'err')
                }
            },
        }),
        updateMeetingStatus: builder.mutation({
            query: body => ({
                url: `/scheduling/status/`,
                method: 'POST',
                body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    if (body.status === 'Cancelled') {
                        dispatch(
                            scheduleApi.util.updateQueryData(
                                'getSchedules',
                                { schedule_endpoint: '/scheduling/schedule/' },
                                (draft: any) => {
                                    const cancelled = draft.filter(item => item.meetingId === body.meetingId)
                                    if (cancelled) {
                                        cancelled.map((item: any) => {
                                            return (item.meetingStatus = 'Cancelled')
                                        })
                                    }
                                },
                            ),
                        )
                    }
                    if (body.status === 'Rejected') {
                        dispatch(
                            scheduleApi.util.updateQueryData(
                                'getSchedules',
                                { schedule_endpoint: '/scheduling/schedule/' },
                                (draft: any) => {
                                    const rejected = draft.filter(item => item.meetingId === body.meetingId)
                                    if (rejected) {
                                        rejected.map((item: any) => {
                                            return (item.meetingStatus = 'Rejected')
                                        })
                                    }
                                },
                            ),
                        )
                    }
                    if (body.status === 'ProposeNewTime') {
                        dispatch(
                            scheduleApi.util.updateQueryData(
                                'getSchedules',
                                { schedule_endpoint: '/scheduling/schedule/' },
                                (draft: any) => {
                                    const proposeTime = draft.filter(item => item.meetingId === body.meetingId)
                                    if (proposeTime) {
                                        proposeTime.forEach((item: any) => {
                                            item.meetingStatus = 'ProposeNewTime'
                                            item.statusComments = body.comment
                                        })
                                    }
                                },
                            ),
                        )
                    }
                } catch (err) {
                    console.log(err, 'err')
                }
            },
        }),
        getFreeBusy: builder.query({
            query: transactionId => ({
                url: `/free-busy/${transactionId}/`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxSchedule.IGetAvailabilityResponse) => {
                const availabilityBlocks = response.availabilityBlocks.map(item => {
                    const cleanedStartTime = item.startTime.split(':')
                    item.startTime = `${cleanedStartTime[0]}:${cleanedStartTime[1]}`
                    const cleanedEndTime = item.endTime.split(':')
                    item.endTime = `${cleanedEndTime[0]}:${cleanedEndTime[1]}`
                    return item
                })
                return {
                    availabilityBlocks: availabilityBlocks,
                    scheduledItems: response.scheduledItems,
                }
            },
            providesTags: ['AVAILABILITY'],
        }),
        updateFreeBusy: builder.mutation({
            query: arg => ({
                url: `/free-busy/${arg.transactiondId}/`,
                method: 'POST',
                body: arg.body,
            }),
            transformResponse: (response: IReduxSchedule.IGetAvailabilityResponse) => {
                return response
            },
            invalidatesTags: ['AVAILABILITY'],
        }),
    }),
})

export const {
    useGetSchedulesQuery,
    useCreateSchedulesMutation,
    useConfirmMeetingOriginatorMutation,
    useUpdateMeetingStatusMutation,
    useGetFreeBusyQuery,
    useUpdateFreeBusyMutation,
} = scheduleApi

export const scheduleQueryReducer = { [reducerPath]: scheduleApi.reducer }
export const scheduleQuerySelector = state => state[reducerPath]
