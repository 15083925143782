import { IReduxMatching } from 'store/modules/matching/matching'

export const transformFormJsonSchemaResponse = (
    response?: IReduxMatching.IFormJsonSchemaResponse,
): IReduxMatching.IFormJsonSchemaTransactionFormTransformed => {
    if (!response) {
        return {
            type: '',
            required: [],
            additionalProperties: false,
            properties: {},
            movedProperties: {},
        }
    }

    let movedProperties = {}
    let newProperties = {}

    const mainObject = response.definitions.TransactionFormJsonSchema

    const properties = mainObject.properties
    const propertyKeys = Object.keys(properties)

    propertyKeys.forEach(propertyKey => {
        const property = properties[propertyKey]
        if (property?.['ui:widget']) {
            newProperties = {
                ...newProperties,
                [propertyKey]: {
                    ...property,
                    inputName: propertyKey,
                },
            }
        } else {
            movedProperties = {
                ...movedProperties,
                [propertyKey]: property,
            }
        }
    })

    return {
        ...mainObject,
        properties: newProperties,
        movedProperties,
    }
}
