import { CommentContentType, CommentStatus } from 'store/modules/comment/enum'

export type CategoryDrawer = '' | 'general' | 'investor-preferences' | 'user' | 'company' | 'team' | 'comment' | 'messenger' // Register reusable modal category here

export type Props = {
    title?: string
    modal?: any
    data?: any
    category?: string
}

export interface Options {
    onClose?: Function
}

interface IHandleDrawer {
    (deal: 'investor-preferences'): (props: IInvestorPreferenceDrawerPropsData, options?: Options) => void
    (deal: 'team'): (props: ITeamDrawerPropsData, options?: Options) => void
    (deal: 'company'): (props: ICompanyDrawerPropsData, options?: Options) => void
    (deal: 'user'): (props: IUserDrawerPropsData, options?: Options) => void
    (deal: 'messenger'): (props: IMessengerDrawerPropsData, options?: Options) => void
    (deal: 'comment'): (props: ICommentDrawerPropsData, options?: Options) => void
}

export interface IDrawerContext<TProps = Props> {
    isOpen: boolean
    content: any
    category: CategoryDrawer
    onClose: () => void
    onHandle: IHandleDrawer
    props?: TProps
    options?: Options
}

export interface IGeneralContentDrawer {
    title?: string
}

export interface IInvestorPreferenceDrawerPropsData {
    title?: string
    userId: string
    viewAs: boolean
}

export interface IMessengerDrawerPropsData {
    opportunityId: string
    title: string
    messengerType?: string
    chatroomId?: string
}

export interface IUserDrawerPropsData {
    title?: string
    userId: string
    params: any
}

export interface ICompanyDrawerPropsData {
    title?: string
    companyId
}

export interface ITeamDrawerPropsData {
    title?: string
    teamId: string
}

export enum CommentDrawerFilterType {
    All = 'All',
    Opportunity = 'Opportunity',
    'Application Submission' = 'Application Submission',
    'Approval & Rejection' = 'Approval & Rejection',
}

export interface ICommentDrawerPropsData {
    id: string
    sourceId: CommentContentType
    status?: Array<CommentStatus>
    query?: string
    type?: CommentDrawerFilterType
}
