import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'

import { ReduxCommentTypeNamespace } from './comment'

const reducerName = 'comment'

export const initialState: ReduxCommentTypeNamespace.ISlice = {
    currentOpenedElementId: '',
}

export const commentSlice = createSlice({
    name: reducerName,
    initialState,
    reducers: {
        setCurrentOpenedEelementId: (state: ReduxCommentTypeNamespace.ISlice, { payload = '' }) => {
            state.currentOpenedElementId = payload
        },
    },
})

export const commentSliceReducer = { [reducerName]: commentSlice.reducer }
export const commentSelector = (state: RootState): ReduxCommentTypeNamespace.ISlice => state.comment
