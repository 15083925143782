import React from 'react'
import { ModalContext } from '../provider'

import { Box, Button, makeStyles, Modal } from '@material-ui/core'
import { colorPalette } from 'styles/mainTheme'

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: '0px 2px 18px rgb(13 59 105 / 15%)',
        backgroundColor: colorPalette.white,
        border: 'solid 1px lightgray',
        borderRadius: 8,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 64px)',
            height: 'calc(100% - 64px)',
        },
    },
    modalButton: {
        marginRight: '5px',
        marginLeft: '5px',
    },
}))

export const GeneralModal = () => {
    const classes = useStyles()
    const { content, onClose, isOpen, category, props } = React.useContext(ModalContext)

    const onNegativeAction = () => {
        props?.onNegativeAction?.() ?? onClose()
    }

    const onPositiveAction = () => {
        props?.onPositiveAction?.() ?? onClose()
    }

    if (isOpen && category === 'general') {
        return (
            <Modal
                open={isOpen}
                onClose={() => onClose()}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                {...(props?.modal ?? {})}
            >
                <>
                    <Box className={classes.modal}>
                        <div>{content}</div>
                        {props?.options?.hasAction && (
                            <Box display="flex" alignItems="center" justifyContent="end" mt={4}>
                                {!props?.options?.hideNegative && (
                                    <Button variant="outlined" className={classes.modalButton} onClick={onNegativeAction}>
                                        {props?.options?.negativeText ?? 'Cancel'}
                                    </Button>
                                )}
                                <Button variant="contained" className={classes.modalButton} onClick={onPositiveAction}>
                                    {props?.options?.positiveText ?? 'Cancel'}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </>
            </Modal>
        )
    }

    return null
}
