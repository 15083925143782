import React from 'react'

import { getDrawerContext } from '../provider'
import { IUserDrawerPropsData } from '../definition'

import { default as UserDrawerPage } from 'app/pages/AdminPage/UserDrawer/UserDrawer'
import { useGetUserDetailsQuery } from 'store/modules/management/management.query'
import { getToken } from 'utils/localStorage/token'

export const UserDrawer = () => {
    const drawer = React.useContext(getDrawerContext<IUserDrawerPropsData>())

    const { userId, params } = drawer.props || {}

    const access_token = getToken('access_token')

    const { data: userDetails, isLoading: isLoadingUserDetails } = useGetUserDetailsQuery(
        { userId: userId },
        {
            skip: !access_token || !userId,
        },
    )

    const onClose = () => {
        drawer.onClose()
    }

    if (drawer.isOpen && drawer.category === 'user') {
        return (
            <UserDrawerPage
                params={params}
                userDetails={userDetails}
                isLoadingUserDetails={isLoadingUserDetails}
                onChangeOpenDrawer={onClose}
                userId={userId}
            ></UserDrawerPage>
        )
    }

    return null
}
