import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxMatching } from 'store/modules/matching/matching'
import config from 'config/index'
import { transformFormJsonSchemaResponse } from 'utils/matching/transformer'

const reducerPath = 'matchingApi'
export const matchingApiSlice = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ALGO_API_URL),
    endpoints: builder => ({
        getFormJsonSchema: builder.query({
            query: () => ({
                url: `matching/form-json-schema/`,
                method: 'GET',
            }),
            transformResponse: (
                response: IReduxMatching.IFormJsonSchemaResponse,
            ): IReduxMatching.IFormJsonSchemaTransactionFormTransformed => transformFormJsonSchemaResponse(response),
        }),
        getMInvestors: builder.mutation({
            query: body => ({
                url: `matching/campaign/`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: IReduxMatching.ITeamsResponse): IReduxMatching.ITeamsResponse => {
                return response
            },
        }),
        getQInvestors: builder.query({
            query: body => ({
                url: `matching/match/`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: IReduxMatching.ITeamsResponse): IReduxMatching.ITeamsResponse => {
                return response
            },
        }),
        getParameters: builder.query({
            query: (id: string) => ({
                url: `matching/parameters/?id=${id}`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxMatching.IParameterResponse): IReduxMatching.IParameterResponse => {
                return response
            },
        }),
        getOrganizations: builder.query({
            query: () => ({
                url: `matching/organizations/`,
                method: 'GET',
            }),
        }),
        getTeamsByOrganizationID: builder.query({
            query: (id: string) => ({
                url: `matching/teams/organizations/${id}/`,
                method: 'GET',
            }),
        }),
        getOpportunities: builder.query({
            query: () => ({
                url: `matching/platform-data/filtered-opportunities/`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxMatching.IGetOpportunitiesResponse): IReduxMatching.opportunity[] => {
                return response?.data ?? []
            },
        }),
        getEmailTemplate: builder.mutation({
            query: body => ({
                url: `email/template/`,
                method: 'POST',
                body,
            }),
        }),
        getEmailPromo: builder.query({
            query: id => ({
                url: `platform-data/opp-marketing-data/${id}/`,
                method: 'GET',
                params: {
                    gpt3_only: true,
                },
            }),
        }),
    }),
})

export const {
    useGetParametersQuery,
    useGetMInvestorsMutation,
    useGetOpportunitiesQuery,
    useGetOrganizationsQuery,
    useGetFormJsonSchemaQuery,
    useGetTeamsByOrganizationIDQuery,
    useGetQInvestorsQuery,
    useGetEmailTemplateMutation,
    useGetEmailPromoQuery,
} = matchingApiSlice

export const matchingQueryReducer = { [reducerPath]: matchingApiSlice.reducer }
export const matchingSelector = state => state[reducerPath]
