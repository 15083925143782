import { ReduxCommentTypeNamespace } from 'store/modules/comment/comment'
import { userApi } from 'store/modules/user'

interface IUseGetCommentator {
    data?: ReduxCommentTypeNamespace.ICommentItem
}

export const useGetCommentator = (props?: IUseGetCommentator) => {
    const currentUserName = userApi.endpoints.getUserProfile.useQueryState({})?.data?.profilePart?.fullName ?? ''
    const currentUserId = userApi.endpoints.getUserProfile.useQueryState({})?.data?.user?.contentItemId ?? ''
    const currentCompanyName = userApi.endpoints.getUserProfile.useQueryState({})?.data?.profilePart?.company?.DisplayText ?? ''

    let authorName = currentUserName
    let companyName = currentCompanyName

    if (props?.data?.commentator) {
        authorName = props?.data?.commentator.name
        companyName = props?.data?.commentator.company_name
    }

    return {
        currentUserName,
        currentUserId,
        currentCompanyName,
        authorName,
        companyName,
    }
}
