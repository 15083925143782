import { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { colorPalette } from 'styles/mainTheme'
// import { useLocation } from 'react-router-dom'
// import logo from '../../../../assets/logo_finitive_square.png'
// import logoLinkedin from '../../../../assets/icon_linkedin.png'
//import { Link as RouterLink } from 'react-router-dom'
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { BuildInfo } from 'app/components/BuildInfo'
// import {
//     useGetFooterDisclaimerQuery,
//     useGetFooterMarketplaceDisclaimerQuery,
//     useGetFooterTransactionDisclaimerQuery,
// } from 'store/modules/content/content.query'

// CSS Styling
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        backgroundColor: colorPalette.white,
        width: '100%',
        bottom: 0,
        borderTop: '1px solid #D8D8D8',
        zIndex: 1201,
    },
    container: {
        flexDirection: 'column',
    },
    textContainer: {
        gap: theme.spacing(2),
    },
    contacts: {
        display: 'flex',
        gap: theme.spacing(3),
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    title: {
        color: colorPalette.black,
        textAlign: 'center',
        fontWeight: 300,
        width: '100%',
    },
    paragraph: {
        fontWeight: 400,
        color: colorPalette.gray1Secondary,
        lineHeight: '24px',
        fontSize: 12,
    },
    link: {
        fontWeight: 500,
        color: colorPalette.primary,
    },
    info: {
        color: colorPalette.gray1Secondary,
        fontWeight: 300,
        lineHeight: '24px',
        fontSize: 12,
    },
    titleContainer: {
        margin: 'auto',
    },
    hyperlink: {
        textDecoration: 'none',
    },
    textHyperlink: {
        color: colorPalette.gray2,
    },
    contactContainer: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    logo: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '16px',
        },
    },
}))

export default memo(function Footer() {
    // Initiate CSS classes
    const classes = useStyles()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    // const location = useLocation()
    // const { data: footerData } = useGetFooterDisclaimerQuery({})
    // const { data: footerMarketplaceData } = useGetFooterMarketplaceDisclaimerQuery({})
    // const { data: footerTransactionData } = useGetFooterTransactionDisclaimerQuery({})

    return (
        <Box className={classes.root}>
            <Box className={classes.container} display="flex" mx={isSmall ? 4 : 10} my={5}>
                <Grid container justifyContent="space-between" className={classes.contactContainer}>
                    {/* <Grid item className={classes.logo}>
                        <img src={logo} alt="Logo" height="41" />
                    </Grid> */}
                    <Grid item className={classes.contacts}>
                        {/* Same domain redirect
                        <Link component={RouterLink} to="#" underline="none">
                            <Typography className={classes.link}>Privacy Policy</Typography>
                        </Link>*/}
                        {/* <a className={classes.hyperlink} href="/terms-and-conditions">
                            <Typography className={classes.link}>Terms and Conditions</Typography>
                        </a>
                        <a className={classes.hyperlink} href="/privacy-policy">
                            <Typography className={classes.link}>Privacy Policy</Typography>
                        </a>
                        <a className={classes.hyperlink} target="_blank" href="https://dealbridge.bamboohr.com/jobs/" rel="noreferrer">
                            <Typography className={classes.link}>Careers</Typography>
                        </a>
                        <a className={classes.hyperlink} href="https://www.dealbridge.ai/#Team">
                            <Typography className={classes.link}>About Us</Typography>
                        </a>
                        <a className={classes.hyperlink} href="https://www.dealbridge.ai/#Contact-Us">
                            <Typography className={classes.link}>Contact Us</Typography>
                        </a>
                        <a
                            target="_blank"
                            className={classes.hyperlink}
                            href="https://www.linkedin.com/company/dealbridge.ai/mycompany/"
                            rel="noreferrer"
                        >
                            <img src={logoLinkedin} alt="Logo" height="32" width="32" />
                        </a> */}
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                {/* <Grid container className={classes.textContainer}>
                    <Grid item className={classes.titleContainer}>
                        {footerData && <div dangerouslySetInnerHTML={{ __html: footerData?.[0].html }}></div>}
                    </Grid>
                    {(location.pathname === '/marketplace' || location.pathname.includes('/transaction/')) && (
                        <Grid container className={classes.textContainer}>
                            <Grid item>
                                <Typography className={classes.paragraph}>
                                    {footerMarketplaceData && (
                                        <div dangerouslySetInnerHTML={{ __html: footerMarketplaceData?.[0].html }}></div>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.paragraph}>
                                    {footerTransactionData && (
                                        <div dangerouslySetInnerHTML={{ __html: footerTransactionData?.[0].html }}></div>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.paragraph}>PRIVATE & CONFIDENTIAL - FOR INSTITUTIONAL USE ONLY</Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid> */}
                The information presented here may include forward-looking statements of the issuer that represent the opinions,
                expectations, beliefs, intentions, estimates or strategies regarding the future, which may not be realized. These statements
                may be identified by the use of words like “anticipate,” “believe,” “estimate,” “expect,” “intend,” “may,” “plan,” “will,”
                “should,” “seek,” and similar expressions. The forward-looking statements reflect the issuer’s views and assumptions with
                respect to future events as of the date of this document and are subject to risks and uncertainties. Actual and future
                results and trends could differ materially from those described by such statements due to various factors, including those
                beyond their ability to control or predict. Given these uncertainties, you should not rely upon forward-looking statements.
                PRIVATE & CONFIDENTIAL - FOR INSTITUTIONAL USE ONLY
                <BuildInfo />
            </Box>
        </Box>
    )
})
