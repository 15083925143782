import { memo } from 'react'
import { lazyLoad } from 'utils/loadable'

import { useHistory, useParams } from 'react-router-dom'

const SalesModuleModal = lazyLoad(
    () => import('../../components/Matching/SalesModuleModal'),
    module => module.default,
)

export default memo(function TransactionCampaigDetailPage() {
    const history = useHistory()
    const { contentItemId, campaignId } = useParams<{ contentItemId: string; campaignId: string }>()

    return (
        <>
            <SalesModuleModal
                campaignId={campaignId}
                open
                onClose={() => history.push(`/transaction/originator/${contentItemId}/access`)}
            />
        </>
    )
})
