import { Drawer, makeStyles } from '@material-ui/core'
import * as React from 'react'

type Anchor = 'right'

const useStyles = makeStyles(theme => ({
    notificationsDrawer: {
        top: '93px !important',
        '& .MuiDrawer-paperAnchorRight': {
            top: '93px',
            minWidth: 480,
            [theme.breakpoints.down('sm')]: {
                mindWidth: 'auto',
                top: 0,
                left: 0,
                right: 0,
            },
        },
        '& .MuiBackdrop-root': {
            top: '93px',
        },
    },
    generalDrawerContainer: {
        maxWidth: 480,
        marginBottom: '10rem',
    },
    messengerDrawerContainer: {
        overflowY: 'hidden',
    },
}))

interface IGeneralDrawer {
    isOpen: boolean
    onChangeOpenDrawer: (open) => void
    title?: string
    children: any
    category?: string
}

export default React.memo(function GeneralDrawer(props: IGeneralDrawer) {
    const classes = useStyles()

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return
        }
        props.onChangeOpenDrawer(open)
    }

    const isMessengerDrawer = props.category === 'messenger'

    return (
        <div style={{ zIndex: 1000, right: 0, top: 200, position: 'absolute' }}>
            <React.Fragment key={'right'}>
                <Drawer className={classes.notificationsDrawer} anchor={'right'} open={props.isOpen} onClose={toggleDrawer('right', false)}>
                    <div className={isMessengerDrawer ? classes.messengerDrawerContainer : classes.generalDrawerContainer}>
                        {props.children}
                    </div>
                </Drawer>
            </React.Fragment>
        </div>
    )
})
