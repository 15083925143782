import React, { useEffect, useState } from 'react'
import {
    Typography,
    Button,
    TextareaAutosize,
    makeStyles,
    CircularProgress,
    Avatar,
    Divider,
    // BottomNavigation,
    // BottomNavigationAction,
} from '@material-ui/core'
import { getDrawerContext } from '../provider'
import { IMessengerDrawerPropsData } from '../definition'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'
import MessengerIcon from '../../../../assets/images/illustrations/messenger-icon.svg'
import Send from '@material-ui/icons/Send'
import CloseIcon from '@material-ui/icons/Close'
import { colorPalette } from 'styles/mainTheme'
import {
    useGetInvestorChatroomListQuery,
    useGetCommentListByChatroomIdQuery,
    useCreateInvestorChatroomMutation,
    useCreateMessageMutation,
} from 'store/modules/messenger/messenger.query'
import { getToken } from 'utils/localStorage/token'
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'
import SortIcon from '@material-ui/icons/Sort'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { userApi } from 'store/modules/user'
// import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined'
// import FolderSharedOutlinedIcon from '@material-ui/icons/FolderSharedOutlined'
import InboundDataroomSection from './InboundDataroomSection'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // overflow: 'hidden',
    },
    messageInput: {
        width: '100%',
        borderRadius: '4px',
        boxShadow: '0px 2px 5px rgba(13, 59, 105, 0.15)',
        marginTop: 'auto',
    },

    emptyStateIcon: {
        width: '128px',
        padding: '3rem 0',
        alignSelf: 'center',
    },
    emptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
    },
    input: {
        padding: '30px 10px',
    },
    emptyStateTitle: {
        color: colorPalette.secondary,
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '143%',
        padding: '16px 0px',
    },
    description: {
        fontWeight: 400,
        fontSize: '14px',
        width: '350px',
        color: 'rgba(0, 0, 0, 0.6)',
        textAlign: 'center',
    },
    sendIcon: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    loaderContainer: {
        marginTop: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        width: 'inherit',
    },
    messageTextArea: {
        width: '100%',
        padding: '8px',
        fontSize: '12px',
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgba(0, 0, 0, 0.26)',
        borderRadius: '3px',
        fontFamily: 'Inter',
    },
    message: {
        fontSize: '12px',
        backgroundColor: '#e1f1f9',
        padding: '12px',
        display: 'block',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        borderTopRightRadius: '12px',
        marginBottom: 0,
        width: '90%',
    },
    bottomMessageInput: {
        bottom: '56px',
    },
    topMessageInput: {
        top: 'calc(183px + 93px)',
        [theme.breakpoints.down('sm')]: {
            top: '183px',
        },
    },
    messageContainer: {
        display: 'flex',
        // marginBottom: '70px',
        width: 'inherit',
        // height: '280px',
        // height of screen - header + message input + bottom nav
        height: 'calc(100vh - (183px + 119px + 93px + 56px))',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - (183px + 119px + 56px))',
        },
        //93 is for navbar
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: '0px 15px',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '420px',
        marginLeft: '30px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '0px 30px',
        },
    },
    bottomNavigation: {
        width: '480px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& .MuiBottomNavigationAction-root': {
            maxWidth: 'none',
            height: '70px',
        },
        '& .MuiBottomNavigationAction-wrapper': {
            marginTop: '21px',
            marginBottom: '21px',
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            borderTop: `4px solid ${colorPalette.primary}`,
        },
    },
}))

export const MessengerDrawer = () => {
    const userProfile = userApi.endpoints.getUserProfile.useQueryState({})

    const coreTeamId = userProfile?.data?.coreTeamIds?.[0]

    const currentUserId = userProfile.currentData?.user.contentItemId

    const drawer = React.useContext(getDrawerContext<IMessengerDrawerPropsData>())

    const { opportunityId, title, messengerType, chatroomId } = drawer.props || {}

    const access_token = getToken('access_token')
    const [chatRoomId, setChatRoomId] = useState<any>('')

    const { data: chatRoomList, isLoading: isLoadingChatroomList } = useGetInvestorChatroomListQuery(
        {},
        { skip: !access_token || messengerType === 'borrower' },
    )

    // console.log(chatRoomId?.length, 'chatroomId')

    const { data: commentList, isLoading: isLoadingCommentList } = useGetCommentListByChatroomIdQuery(
        { chatroomId: chatRoomId },
        { skip: !access_token || !chatRoomId },
    )

    //  const [requestDataRoomAccess, { data: survey, isLoading }] = usePostOpportunityDataRoomRequestAccessMutation()
    const [createChatRoom, { isLoading: isCreatingChatroom }] = useCreateInvestorChatroomMutation()
    const [sendMessage, { isLoading: isSendingMessage }] = useCreateMessageMutation()
    // const [errorMessage, setErrorMessage] = useState('')
    const [errorMessage] = useState('')

    const [isSortByNewest, setIsSortByNewest] = useState(false)
    const [sortedComments, setSortedComments] = useState<any>([])

    useEffect(() => {
        if (isSortByNewest) {
            const latestMessageId = sortedComments?.[0]?.id
            const lastMessage = document.getElementById(latestMessageId)
            lastMessage?.scrollIntoView({
                behavior: 'smooth',
            })
        }
        if (!isSortByNewest) {
            const latestMessageId = sortedComments?.[sortedComments.length - 1]?.id
            const lastMessage = document.getElementById(latestMessageId)
            lastMessage?.scrollIntoView({
                behavior: 'smooth',
            })
        }
    }, [sortedComments, isSortByNewest])

    useEffect(() => {
        if (opportunityId && !isLoadingChatroomList) {
            const currentChatRoom = chatRoomList?.filter(room => room.context_content_item_id === opportunityId)

            if (currentChatRoom?.length === 0) {
                createChatRoom({
                    subject_content_type: 'opportunity',
                    context_id: opportunityId,
                    requestor_context_id: coreTeamId,
                })
            }

            if (messengerType === 'borrower') {
                setChatRoomId(chatroomId)
            } else {
                setChatRoomId(currentChatRoom?.[0]?.id)
            }
        }
    }, [chatRoomList, createChatRoom, opportunityId, coreTeamId, messengerType, chatroomId, isLoadingChatroomList])

    const classes = useStyles()

    const [currentMessage, setCurrentMessage] = useState('')

    const handleChange = e => {
        setCurrentMessage(e.target.value)
    }

    const shouldDisableAction = isCreatingChatroom || isSendingMessage

    const handleSend = () => {
        sendMessage({
            text: currentMessage,
            object_id: chatRoomId,
            content_type: 'chatroom',
        })
        setCurrentMessage('')
    }

    const handleSort = () => {
        setIsSortByNewest(prev => !prev)
    }

    useEffect(() => {
        if (commentList) {
            if (isSortByNewest) {
                const sortByNewest = commentList?.slice(0)?.sort(function (x, y) {
                    return y.created_on_ts - x.created_on_ts
                })
                setSortedComments(sortByNewest)
            }

            if (!isSortByNewest) {
                const sortByOldest = commentList?.slice(0)?.sort(function (x, y) {
                    return x.created_on_ts - y.created_on_ts
                })

                setSortedComments(sortByOldest)
            }
        }
    }, [commentList, isSortByNewest])
    const activeTab = 0
    // const [activeTab, setActiveTab] = React.useState<number>(0)

    if (drawer.isOpen && drawer.category === 'messenger') {
        if (isLoadingChatroomList || isLoadingCommentList) {
            return (
                <div className={classes.loaderContainer}>
                    <Skeleton animation="wave" style={{ transform: 'none' }} variant="circle" width={128} height={128} />
                    <Skeleton animation="wave" width={200} />
                    <Skeleton animation="wave" width={300} />
                    <Skeleton animation="wave" width={300} />
                </div>
            )
        }

        return (
            <>
                <div className={classes.root}>
                    {activeTab === 0 && (
                        <>
                            <div style={{ position: 'fixed', zIndex: 99, backgroundColor: '#fff', width: 'inherit' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        margin: '15px',
                                    }}
                                >
                                    <div>
                                        <Typography variant="h2" color="primary" style={{ fontWeight: '600' }}>
                                            Conversation
                                            <VerifiedUserOutlinedIcon color="primary" style={{ margin: '0px 10px' }} />
                                        </Typography>
                                    </div>

                                    <div>
                                        <Button onClick={() => drawer.onClose()}>
                                            <CloseIcon />
                                        </Button>
                                    </div>
                                </div>

                                <Typography
                                    color="primary"
                                    style={{
                                        fontWeight: '600',
                                        lineHeight: '140%',
                                        // padding: '16px 32px',
                                        margin: '15px',
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Divider
                                    style={{
                                        width: '100%',
                                        height: '1.5px',
                                    }}
                                />

                                {!isLoadingCommentList && !isLoadingChatroomList && (
                                    <Button onClick={handleSort} style={{ margin: '10px' }}>
                                        <div style={{ display: 'flex', gap: '5px' }}>
                                            {isSortByNewest ? 'Newest first' : 'Oldest first'}
                                            {isSortByNewest ? <SortIcon style={{ transform: 'rotate(180deg)' }} /> : <SortIcon />}
                                            {isSortByNewest ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                        </div>
                                    </Button>
                                )}
                            </div>

                            {!isLoadingChatroomList && !isLoadingCommentList && (
                                <div
                                    className={!isSortByNewest ? classes.bottomMessageInput : classes.topMessageInput}
                                    style={{
                                        position: 'fixed',
                                        zIndex: 99,
                                        backgroundColor: '#fff',
                                        width: '100%',
                                        display: 'flex',
                                        // justifyContent: 'center',
                                    }}
                                >
                                    {!isLoadingCommentList && !isLoadingChatroomList && (
                                        <div className={classes.inputContainer}>
                                            <div style={{ width: '100%', paddingTop: '10px' }}>
                                                <div>
                                                    <TextareaAutosize
                                                        className={classes.messageTextArea}
                                                        minRows={3}
                                                        value={currentMessage}
                                                        placeholder="Write your message..."
                                                        onChange={handleChange}
                                                        // onFocus={onFocusMessageInput}
                                                        style={errorMessage ? { borderColor: '#F15656' } : {}}
                                                        disabled={shouldDisableAction}
                                                    />

                                                    <div
                                                        style={{
                                                            position: 'relative',
                                                            left: '90%',
                                                            bottom: '55px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        {shouldDisableAction && <CircularProgress size={16} />}
                                                        <Button
                                                            disabled={shouldDisableAction || !currentMessage.trim()}
                                                            style={{ background: 'transparent' }}
                                                            // onClick={onSave}
                                                            onClick={handleSend}
                                                        >
                                                            <Send color={currentMessage.trim() ? 'primary' : 'disabled'} fontSize="small" />
                                                        </Button>
                                                    </div>
                                                </div>
                                                <Typography color="error" variant="body2">
                                                    {errorMessage}
                                                </Typography>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div
                                style={{
                                    marginTop: !isSortByNewest ? '183px' : 'calc(183px + 118px)',
                                }}
                                className={classes.messageContainer}
                            >
                                {!isLoadingChatroomList && !isLoadingCommentList && commentList?.length === 0 ? (
                                    <div className={classes.emptyStateContainer}>
                                        <img src={MessengerIcon} alt="messenger icon" className={classes.emptyStateIcon} />
                                        <Typography variant="body2" className={classes.emptyStateTitle}>
                                            No conversations yet
                                        </Typography>
                                        <Typography className={classes.description}>
                                            You can share messages and share files to engage and move on with the transaction negotiation
                                        </Typography>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            // display: 'flex',
                                            // justifyContent: 'center',
                                            // alignItems: 'center',
                                            padding: '0px 15px',
                                            // flexDirection: 'column',
                                            // gap: '30px',
                                            width: 'inherit',
                                        }}
                                    >
                                        {sortedComments?.map((item, index) => {
                                            const isCurrentUser =
                                                item.commentator.content_item_id === currentUserId ? 'flex-end' : 'flex-start'
                                            const isAvatarFirst = item.commentator.content_item_id === currentUserId ? 'row' : 'row-reverse'

                                            const howLongAgo = moment(item.created_on).from(new Date())

                                            return (
                                                <div
                                                    id={item.id}
                                                    key={item.id}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '8px',
                                                        width: '100%',
                                                        alignItems: isCurrentUser,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gridGap: '8px',
                                                            gap: '8px',
                                                            flexDirection: isAvatarFirst,
                                                        }}
                                                    >
                                                        <div>
                                                            <Typography variant="body2" style={{ fontWeight: 700, fontSize: '12px' }}>
                                                                {item.commentator.name}
                                                            </Typography>
                                                            <Typography variant="caption">{item.commentator.company_name}</Typography>
                                                        </div>
                                                        <Avatar
                                                            style={{
                                                                backgroundColor: colorPalette.bluePrimary,
                                                                width: '32px',
                                                                height: '32px',
                                                            }}
                                                            children={item.commentator.name
                                                                .split(' ')
                                                                .map(n => n[0])
                                                                .join('')}
                                                        />
                                                    </div>

                                                    <div
                                                        className={classes.message}
                                                        dangerouslySetInnerHTML={{ __html: item.text.replaceAll(/\n/g, '<br/>') }}
                                                    ></div>

                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            color: '#4F4F4F',
                                                            fontSize: '10px',
                                                            fontFamily: 'Inter',
                                                            fontWeight: 400,
                                                            lineHeight: '24px',
                                                        }}
                                                    >
                                                        {howLongAgo}
                                                    </Typography>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {activeTab === 1 && <InboundDataroomSection opportunityId={opportunityId} />}
                </div>

                {!isLoadingCommentList && !isLoadingChatroomList && (
                    <></>
                    // <div style={{ position: 'fixed', zIndex: 99, backgroundColor: '#fff', width: '100%', bottom: 0 }}>
                    //     <BottomNavigation
                    //         value={activeTab}
                    //         onChange={(event, newValue) => {
                    //             setActiveTab(newValue)
                    //         }}
                    //         showLabels
                    //         className={classes.bottomNavigation}
                    //     >
                    //         <BottomNavigationAction
                    //             // style={{ activeTab === 0 ? borderTop: `4px solid ${colorPalette.primary}` : borderTop:'none' }}
                    //             label="Conversation"
                    //             icon={<QuestionAnswerOutlinedIcon />}
                    //         />
                    //         {/* <BottomNavigationAction label="Shared Documents" icon={<FolderSharedOutlinedIcon />} /> */}
                    //     </BottomNavigation>
                    // </div>
                )}
            </>
        )
    }

    return null
}
