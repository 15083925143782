import { commentSliceReducer } from './comment.slice'
import { commentQueryReducer } from './comment.query'

const combinedReducer = {
    ...commentSliceReducer,
    ...commentQueryReducer,
}

export * from './comment.slice'
export * from './comment.query'
export default combinedReducer
