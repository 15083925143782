import CloseIcon from '@material-ui/icons/Close'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { Backdrop, Box, Fade, Modal, Typography } from '@material-ui/core'

import UploadFiles, { IUploadFiles } from './UploadFiles'

import './UploadFiles.css'
import { colorPalette } from 'styles/mainTheme'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: 600,
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(4),
            backgroundColor: colorPalette.white,
            borderRadius: theme.spacing(0.5),
        },
    }),
)

export interface IUploadImageWithModal extends IUploadFiles {
    testId?: string
    title: string
    onClose: Function
    isOpen: boolean
}

const UploadImageWithModal = ({
    title,
    isOpen,
    onClose,
    children,
    testId = 'upload-files-with-modal',
    ...UploadFilesProps
}: IUploadImageWithModal) => {
    const classes = useStyles()

    const closeModal = () => {
        onClose()
    }

    return (
        <Modal
            id="upload-files-with-modal"
            data-testid={testId}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpen}>
                <Box className={classes.modal}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                        <Typography
                            id="transition-modal-title"
                            variant="h3"
                            style={{ fontSize: 24, fontWeight: 600 }}
                            data-testid={`${testId}-title-text`}
                        >
                            {title}
                        </Typography>
                        <CloseIcon onClick={closeModal} color="primary" data-testid={`${testId}-close-button`} />
                    </Box>
                    <UploadFiles isOpen={isOpen} {...UploadFilesProps}>
                        {children}
                    </UploadFiles>
                </Box>
            </Fade>
        </Modal>
    )
}

export default UploadImageWithModal
