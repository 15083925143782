import React from 'react'

import { getDrawerContext } from '../provider'
import { IGeneralContentDrawer } from '../definition'

export const GeneralContentDrawer = () => {
    const drawer = React.useContext(getDrawerContext<IGeneralContentDrawer>())

    if (drawer.isOpen && drawer.category === 'general') {
        return drawer.content
    }

    return null
}
