import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxSurvey } from 'store/modules/survey/survey'
import { toModifiedResponse } from 'utils/survey/transformer'
import { investor_preferences } from 'app/components/DDQ/SurveyQuestion/investor-preferences'
import patent_litigation from 'app/components/DDQ/SurveyQuestion/patent-litigation'

const reducerPath = 'surveyApi'
export const surveyApi = createApi({
    reducerPath,
    baseQuery: http(),
    tagTypes: ['SURVEY_DDQ_BASIC_INFORMATION'],
    endpoints: builder => ({
        getSurveyDDQ: builder.mutation<IReduxSurvey.ISurveyModifiedResponse, Object | void>({
            query: (ddqType: string = '') => ({
                url: `/survey/ddq/${ddqType}`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxSurvey.ISurveyResponse): IReduxSurvey.ISurveyModifiedResponse => {
                return toModifiedResponse(response)
            },
        }),
        getStatusDDQ: builder.query<IReduxSurvey.ISurveyStatusResponse, Object | void>({
            query: (ddqType: string = '') => ({
                url: `/survey/ddq/status/${ddqType}`,
                method: 'GET',
            }),
            providesTags: ['SURVEY_DDQ_BASIC_INFORMATION'],
        }),
        getSurveyOpportunityDDQ: builder.mutation<IReduxSurvey.ISurveyModifiedResponse, Object | void>({
            query: (oppId: string) => ({
                url: `/v1/opportunities/${oppId}/ddq/`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxSurvey.ISurveyResponse): IReduxSurvey.ISurveyModifiedResponse => {
                return toModifiedResponse(response)
            },
        }),
        getSubmissionId: builder.mutation<string, Object | void>({
            query: (oppId: string) => ({
                url: `/v1/opportunities/${oppId}/ddq/`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxSurvey.ISurveyResponse): string => {
                return toModifiedResponse(response).meta.submissionId
            },
        }),
        getOpportunityDDQ: builder.query<IReduxSurvey.ISurveyModifiedResponse, Object | void>({
            query: (oppId: string) => ({
                url: `/v1/opportunities/${oppId}/ddq/`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxSurvey.ISurveyResponse): IReduxSurvey.ISurveyModifiedResponse => {
                return toModifiedResponse(response)
            },
        }),
        getStatusOpportunityDDQ: builder.query<IReduxSurvey.ISurveyStatusResponse, Object | void>({
            query: (oppId: string) => ({
                url: `/v1/opportunities/${oppId}/ddq/status/`,
                method: 'GET',
            }),
        }),
        getHistoryOpportunityDDQ: builder.query({
            query: (oppId: string) => ({
                url: `/survey/opportunities/${oppId}/ddq/history/`,
                method: 'GET',
            }),
            providesTags: ['SURVEY_DDQ_BASIC_INFORMATION'],
        }),
        postStatusOpportunityDDQ: builder.mutation({
            query: body => ({
                url: `/v1/opportunities/${body.opportunityId}/ddq/status/`,
                method: 'POST',
                body: {
                    approved: body.approved,
                },
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
                await queryFulfilled
                dispatch(surveyApi.util.invalidateTags(['SURVEY_DDQ_BASIC_INFORMATION']))
            },
        }),
        getSurveyInvestorPreference: builder.mutation({
            query: arg => ({
                url: arg.userId ? `/survey/ip/${arg.userId}` : `/survey/ip`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxSurvey.ISurveyResponse): IReduxSurvey.ISurveyModifiedResponse => {
                return toModifiedResponse({
                    ...response,
                    survey: JSON.stringify(investor_preferences),
                })
            },
        }),
        getSurveyPatentLitigation: builder.mutation<IReduxSurvey.ISurveyModifiedResponse, Object | void>({
            query: () => ({
                url: `/survey/ddq/patent-alexey/`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxSurvey.ISurveyResponse): IReduxSurvey.ISurveyModifiedResponse => {
                return toModifiedResponse({
                    ...response,
                    survey: JSON.stringify(patent_litigation),
                })
            },
        }),
        save: builder.mutation({
            query: (body: IReduxSurvey.ISave) => ({
                url: body.userId ? `/v1/submission${body.userId}` : `/v1/submission`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
                await queryFulfilled
                dispatch(surveyApi.util.invalidateTags(['SURVEY_DDQ_BASIC_INFORMATION']))
            },
        }),
        savePartial: builder.mutation({
            query: (body: IReduxSurvey.ISave) => ({
                url: `/v1/submission`,
                method: 'PATCH',
                body,
            }),
        }),
        uploadFile: builder.mutation({
            query: (body: IReduxSurvey.IUploadFile) => {
                const formData = new FormData()

                ;[...body.files].forEach((file: any) => {
                    formData.append('files', file)
                })

                return {
                    url: `/v1/survey/files`,
                    method: 'POST',
                    body: formData,
                    params: {
                        contextId: body.ContextId,
                        formId: body.FormContentItemId,
                    },
                }
            },
        }),
    }),
})

export const {
    useSavePartialMutation,
    useSaveMutation,
    useGetSurveyInvestorPreferenceMutation,
    useGetSurveyDDQMutation,
    useGetSurveyOpportunityDDQMutation,
    useGetStatusOpportunityDDQQuery,
    useGetStatusDDQQuery,
    useGetSurveyPatentLitigationMutation,
    useUploadFileMutation,
    usePostStatusOpportunityDDQMutation,
    useGetHistoryOpportunityDDQQuery,
    useGetOpportunityDDQQuery,
    useGetSubmissionIdMutation,
} = surveyApi

export const surveyQueryReducer = { [reducerPath]: surveyApi.reducer }
export const surveyQuerySelector = state => state[reducerPath]
