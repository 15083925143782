import React, { memo, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
    makeStyles,
    Box,
    Grid,
    Typography,
    Select,
    Checkbox,
    MenuItem,
    Divider,
    InputAdornment,
    Button,
    TextField,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    CircularProgress,
} from '@material-ui/core'
import TreeView from '@material-ui/lab/TreeView'
import { getToken } from 'utils/localStorage/token'
// import { DropzoneArea } from 'material-ui-dropzone'
import {
    geographyChoices,
    currencyChoices,
    maxTermChoices,
    investmentChoices,
    capitalStructuresChoices,
    investmentStructuresChoices,
    additionalDataRequired,
    additionalDataRequiredNAICS,
} from './IPQuestions'
import { renderTree } from './IPTreeCheckbox'
import { Controller, useForm, SubmitHandler, useWatch } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useHistory, useLocation } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'

import SideBarProfile from 'app/components/Layout/SideBarProfile'
import ContactUsCard from 'app/components/DDQ/ContactUsCard'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import * as yup from 'yup'
import { userApi } from 'store/modules/user'

import ScrollToPageTop from 'app/components/PageScroller/PageScroller'
import { surveySlice, useGetSurveyInvestorPreferenceMutation, useSaveMutation, useUploadFileMutation } from 'store/modules/survey'
import { colorPalette } from 'styles/mainTheme'
import { NumberFormatCustom } from 'utils/form/input/inputform'
import './IPStyle.css'

import $ from 'jquery'
import { useGetTaxonomiesQuery } from 'store/modules/taxonomy/taxonomy.query'
import ProgressIndicator from 'app/components/Progress/ProgressIndicator'
import { renderTreeTaxonomy } from './IPTreeCheckboxTaxonomy'
window['$'] = window['jQuery'] = $

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    gridMain: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            height: 'auto',
        },
        width: '100%',
        justifyContent: 'left',
        margin: 'auto',
        height: '100%',

        top: 0,
    },
    errorInput: {
        fontSize: '12px',
    },
    containerMain: {
        [theme.breakpoints.down('md')]: {
            marginLeft: 4,
            marginRight: 4,
        },
        maxWidth: '700px',
    },
    containerContactUs: {
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
        },
    },
    buttonContainer: {
        marginTop: theme.spacing(4),
    },
    capitalFieldEndAdornment: {
        marginRight: -16,
    },
    capitalField: {
        marginRight: theme.spacing(2),
    },
    capitalLabel: {
        marginBottom: theme.spacing(3.5),
    },
    subLabel: {
        marginTop: 16,
        marginBottom: 8,
        fontSize: 12,
    },
    mainLabel: {
        color: '#013c4d',
        fontWeight: 600,
        marginBottom: 16,
    },
    errorMessage: {
        marginTop: 15,
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: '15px',
        color: '#FFFFFF',
    },
    sideBarContainer: {
        height: 'fit-content',
        position: 'sticky',
        top: theme.spacing(10),
        paddingLeft: theme.spacing(5),
        float: 'right',
        [theme.breakpoints.down('md')]: {
            float: 'left',
        },
    },
}))

type Inputs = {
    Currency: string
    InvestmentSizeMax: number
    InvestmentSizeMin: number
    ReturnMin: number
    TotalPortfolioAum: string
    AnnualRevenue: number
    EBITDA: number
    EnterpriseValue: number
    Comments: string
    InvestmentStructuresOther: string
    SelectedNAICS: Array<string>
}

interface IInvestorPreferences {
    viewAs?: boolean
    userId?: string
}

export default memo(function InvestorPreferencesPage(props: IInvestorPreferences) {
    // Initialization
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const filesRef = useRef<HTMLInputElement>(null)
    const labelFilesRef = useRef<HTMLLabelElement>(null)
    const history = useHistory()
    const location = useLocation()
    const [localError, setLocalError] = React.useState<{ [key: string]: string }>({ key: '' })
    const [uploadedFiles, setUploadedFiles] = useState<{ name: string; content: string; type: string }[]>([])
    const access_token = getToken('access_token')

    const { data: dataCountry } = useGetTaxonomiesQuery('country', {
        skip: !access_token,
    })

    const { data: dataNAICS } = useGetTaxonomiesQuery('NAICS', {
        skip: !access_token,
    })

    const [surveyUploadFile] = useUploadFileMutation()
    const [saveSubmission, { isLoading: isLoadingSubmission, error, isError }] = useSaveMutation()
    // const [getInvestorPreference, { data: survey, isLoading }] = useGetSurveyInvestorPreferenceMutation()
    const [getInvestorPreference, { data: survey, isLoading }] = useGetSurveyInvestorPreferenceMutation()
    // props.viewAs ? { userId: `?userId=${props.userId}` } : { userId: '' },
    const { data: userData, isLoading: isGetUserProfileLoading } = userApi.endpoints.getUserProfile.useQueryState({})

    // Investor preferences Tree
    const [selectedMaxTerms, setSelectedMaxTerms] = React.useState<string[]>([])
    const [otherInvestmentTypes, setOtherInvestmentTypes] = React.useState<any>('')
    const [selectedGeographies, setSelectedGeographies] = React.useState<string[]>([])
    const [selectedCapitalStructures, setSelectedCapitalStructures] = React.useState<string[]>([])
    const [selectedInvestmentStructures, setSelectedInvestmentStructures] = React.useState<string[]>([])

    // Investment types Tree
    const [selectedInvestments, setSelectedInvestments] = React.useState<string[]>([])

    // NAICS types Tree
    const [selectedNAICS, setSelectedNAICS] = React.useState<string[]>([])

    // Unit
    const [minInvestmentSizeUnit, setMinInvestmentSizeUnit] = React.useState<string>('Million')
    const [maxInvestmentSizeUnit, setMaxInvestmentSizeUnit] = React.useState<string>('Million')

    // Additional Data Unit
    const [ebitdaUnit, setEBITDAUnit] = React.useState<string>('Million')
    const [annualRevenueUnit, setAnnualRevenueUnit] = React.useState<string>('Million')
    const [enterpriseValueUnit, setEnterpriseValueUnit] = React.useState<string>('Million')

    const handleChange = (event, setter) => {
        setter(event.target.value as string)
    }

    const unitPair = {
        Thousand: 10 ** 3,
        Million: 10 ** 6,
        Billion: 10 ** 9,
    }

    const maxMinValidationMessage = 'Min Investment should be less than Max Investment'

    const IPSchema = yup.object().shape({
        Currency: yup.string().required('Required'),
        InvestmentSizeMin: yup.string().when('InvestmentSizeNoMin', {
            is: false,
            then: yup.string().required('Please input investment size minimum or select no minimum'),
        }),
        InvestmentSizeMax: yup.string().when('InvestmentSizeNoMax', {
            is: false,
            then: yup.string().required('Please input investment size maximum or select no maximum'),
        }),
        ReturnMin: yup.string().when('ReturnNoMin', {
            is: false,
            then: yup
                .string()
                .required(
                    'Please input minimum marginal return or select no minimum (assume margin over SOFR for variable rate transactions)',
                ),
        }),
        TotalPortfolioAum: yup.string().required('Required'),
        // Additional Data Schema
        AnnualRevenue: yup
            .string()
            .nullable()
            .when('AdditionalData', {
                is: true,
                then: yup.string().required('Required Annual Revenue').nullable(),
            }),
        EBITDA: yup
            .string()
            .nullable()
            .when('AdditionalData', {
                is: true,
                then: yup.string().required('Required EBITDA').nullable(),
            }),
        EnterpriseValue: yup
            .string()
            .nullable()
            .when('AdditionalData', {
                is: true,
                then: yup.string().required('Required Enterprise Value').nullable(),
            }),
        // Additional Data NAICS Schema
        SelectedNAICS: yup.array().when('AdditionalDataNAICS', {
            is: true,
            then: yup.array().min(1).required('Required Enterprise Value'),
        }),
    })

    let treeSchema = yup.object().shape({
        selectedGeographies: yup.array().min(1).required(),
        selectedMaxTerms: yup.array().min(1).required(),
        selectedCapitalStructures: yup.array().min(1).required(),
        selectedInvestmentStructures: yup.array().min(1).required(),
        selectedInvestments: yup.array().min(1).required(),
    })

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(IPSchema),
    })

    const onSubmit: SubmitHandler<Inputs> = async payload => {
        const isValidateTree = await treeSchema
            .validate(
                {
                    selectedGeographies,
                    selectedMaxTerms,
                    selectedCapitalStructures,
                    selectedInvestmentStructures,
                    selectedInvestments,
                },
                {
                    abortEarly: false,
                },
            )
            .then(() => {
                setLocalError({})
                return true
            })
            .catch(e => {
                const currentLocalError = {}
                e.inner.forEach(e => {
                    currentLocalError[e.path] = e.message
                })
                setLocalError(currentLocalError)
                return false
            })

        if (!isValidateTree) return

        // Payload Interface
        const InvestmentSize = () => {
            let minimum: any = null
            let maximum: any = null
            if (!investmentSizeNoMax) {
                maximum = {
                    value: maxInvestmentSizeUnit,
                    numeric: parseFloat(payload.InvestmentSizeMax.toString().replace(/,/g, '')),
                }
            }
            if (!investmentSizeNoMin) {
                minimum = {
                    value: minInvestmentSizeUnit,
                    numeric: parseFloat(payload.InvestmentSizeMin.toString().replace(/,/g, '')),
                }
            }
            if (investmentSizeNoMin && investmentSizeNoMax) {
                return { minimum, maximum, answerGuard: true }
            } else return { minimum, maximum }
        }

        const MinReturn = () => {
            let minimum: any = null
            if (!returnNoMin) {
                minimum = {
                    value: 'percent',
                    numeric: payload.ReturnMin,
                }
            }
            if (returnNoMin) {
                return { minimum, answerGuard: true }
            } else return { minimum }
        }

        const NumericValueObj = (numeric, value) => {
            return {
                numeric: numeric,
                value: value,
            }
        }

        const fixedPayload = {
            Geographies: { ids: [...new Set(selectedGeographies)], inputs: [{}] },
            MaxTerm: { ids: [...new Set(selectedMaxTerms)], inputs: [{}] },
            InvestmentTypes: { ids: [...new Set(selectedInvestments)], inputs: otherInvestmentTypes ?? {} },
            CapitalStructures: { ids: [...new Set(selectedCapitalStructures)], inputs: [{}] },
            InvestmentStructures: {
                ids: [...new Set(selectedInvestmentStructures)],
                inputs: payload.InvestmentStructuresOther ? { Other: payload.InvestmentStructuresOther } : {},
            },
            NAICS: additionalDataNAICS ? { ids: [...new Set(selectedNAICS)], inputs: [{}] } : {},
            Currency: payload.Currency,
            TotalPortfolioAum: payload.TotalPortfolioAum,
            Comments: payload.Comments,
            InvestmentSize: InvestmentSize(),
            MinReturn: MinReturn(),
            AnnualRevenue: additionalData
                ? NumericValueObj(parseFloat(payload.AnnualRevenue.toString().replace(/,/g, '')), annualRevenueUnit)
                : {},
            EBITDA: additionalData ? NumericValueObj(parseFloat(payload.EBITDA.toString().replace(/,/g, '')), ebitdaUnit) : {},
            EnterpriseValue: additionalData
                ? NumericValueObj(parseFloat(payload.EnterpriseValue.toString().replace(/,/g, '')), enterpriseValueUnit)
                : {},
            Files: uploadedFiles,
        }

        if (survey)
            saveSubmission({
                ContextId: survey.meta.contextId,
                FormContentItemId: survey.meta.formContentItemId,
                FormContentItemVersionId: survey.meta.formContentItemVersionId,
                IsComplete: true,
                Json: JSON.stringify(fixedPayload),
                PercentageComplete: 100,
            })
                .then(response => {
                    // @ts-ignore
                    if (!response?.error) enqueueSnackbar('Questionnaire saved successfully', { variant: 'success' })
                })
                .catch((error: any) => {
                    enqueueSnackbar('Questionnaire did not save, Error: ' + error, { variant: 'error' })
                })
    }

    const currency = useWatch({
        control,
        name: 'Currency',
        defaultValue: survey?.initialData.Currency,
    })

    const investmentSizeNoMin = useWatch({
        control,
        name: 'InvestmentSizeNoMin',
    })

    const investmentSizeNoMax = useWatch({
        control,
        name: 'InvestmentSizeNoMax',
    })

    const InvestmentSizeMin = useWatch({
        control,
        name: 'InvestmentSizeMin',
    })

    const InvestmentSizeMax = useWatch({
        control,
        name: 'InvestmentSizeMax',
    })

    const returnNoMin = useWatch({
        control,
        name: 'ReturnNoMin',
    })

    const additionalData = useWatch({
        control,
        name: 'AdditionalData',
    })

    const additionalDataNAICS = useWatch({
        control,
        name: 'AdditionalDataNAICS',
    })

    const currencySymbol = currencyChoices.find(cur => {
        if (currency) {
            return cur.text === currency
        } else {
            return cur.text === survey?.initialData.Currency
        }
    })?.value

    useEffect(() => {
        if (isLoading) {
            dispatch(surveySlice.actions.clear())
        }
    }, [dispatch, isLoading])

    useEffect(() => {
        // Data initialization
        if (survey) {
            // Geographies
            setSelectedGeographies(survey?.initialData?.Geographies?.ids ?? [])

            // Max Terms
            setSelectedMaxTerms(survey?.initialData?.MaxTerm?.ids ?? [])

            // Investment Structures
            setSelectedInvestmentStructures(survey?.initialData?.InvestmentStructures?.ids ?? [])
            setValue('InvestmentStructuresOther', survey?.initialData?.InvestmentStructures?.inputs?.Other ?? undefined)

            // Capital Structures
            setSelectedCapitalStructures(survey?.initialData?.CapitalStructures?.ids ?? [])

            // Investment Max and Min
            setValue('InvestmentSizeNoMin', survey?.initialData?.InvestmentSize?.minimum === null)
            setValue('InvestmentSizeNoMax', survey?.initialData?.InvestmentSize?.maximum === null)
            setValue('InvestmentSizeMin', survey?.initialData?.InvestmentSize?.minimum?.numeric ?? undefined)
            setValue('InvestmentSizeMax', survey?.initialData?.InvestmentSize?.maximum?.numeric ?? undefined)
            setMinInvestmentSizeUnit(survey?.initialData?.InvestmentSize?.minimum?.value ?? 'Million')
            setMaxInvestmentSizeUnit(survey?.initialData?.InvestmentSize?.maximum?.value ?? 'Million')
            setValue('ReturnNoMin', survey?.initialData?.MinReturn?.minimum === null)
            setValue('ReturnMin', survey?.initialData?.MinReturn?.minimum?.numeric ?? undefined)

            // Additional Data for NAICS
            setValue(
                'AdditionalDataNAICS',
                additionalDataRequiredNAICS.some(r => survey?.initialData?.InvestmentStructures?.ids.includes(r)),
            )
            setSelectedNAICS(survey?.initialData?.NAICS?.ids ?? [])

            // Additional Data (Annual Revenue, EBITDA, Enterprise Value)
            setValue(
                'AdditionalData',
                additionalDataRequired.some(r => survey?.initialData?.InvestmentTypes?.ids.includes(r)),
            )
            setValue('EBITDA', survey?.initialData?.EBITDA?.numeric ?? null)
            setValue('EnterpriseValue', survey?.initialData?.EnterpriseValue?.numeric ?? null)
            setAnnualRevenueUnit(survey?.initialData?.AnnualRevenue?.value ?? undefined)
            setEBITDAUnit(survey?.initialData?.EBITDA?.value ?? undefined)
            setEnterpriseValueUnit(survey?.initialData?.EnterpriseValue?.value ?? undefined)

            // Investment Types
            setSelectedInvestments(survey?.initialData?.InvestmentTypes?.ids ?? [])
            setOtherInvestmentTypes(survey?.initialData?.InvestmentTypes?.inputs ?? {})

            // Comments
            setValue('Comments', survey?.initialData?.Comments ?? undefined)

            // Files
            setUploadedFiles(survey?.initialData?.Files ?? [])
        }
    }, [survey, setValue])

    useEffect(() => {
        getInvestorPreference(props.viewAs ? { userId: `?userId=${props.userId}` } : { userId: '' })

        return () => {
            getInvestorPreference(props.viewAs ? { userId: `?userId=${props.userId}` } : { userId: '' })
        }
    }, [getInvestorPreference, dataCountry, props.viewAs, props.userId])

    // Additional Data realtime update

    useEffect(() => {
        // Additional Data validator
        setValue(
            'AdditionalData',
            additionalDataRequired.some(r => selectedInvestments.includes(r)),
        )
    }, [selectedInvestments, setValue])

    useEffect(() => {
        // Additional Data NAICS validator
        setValue(
            'AdditionalDataNAICS',
            additionalDataRequiredNAICS.some(r => selectedInvestmentStructures.includes(r)),
        )
        // Move Selected NAICS state to payload
        setValue('SelectedNAICS', selectedNAICS)
    }, [selectedInvestmentStructures, selectedNAICS, setValue])

    const errorResponse = (error as any)?.data?.errors ?? {}
    const errorMessageKeys = Object.keys(errorResponse)

    const onDragOver = () => {
        if (labelFilesRef && labelFilesRef.current) {
            labelFilesRef.current.className = 'label-file-input ondrag'
        }
    }

    const onDragLeave = () => {
        if (labelFilesRef && labelFilesRef.current) {
            labelFilesRef.current.className = 'label-file-input'
        }
    }

    const onClean = () => {
        setUploadedFiles([])
    }

    const onRemove = removedFile => () => {
        const newUploadedFiles = uploadedFiles.filter(file => file.content !== removedFile.content)
        setUploadedFiles(newUploadedFiles)
    }

    // check login
    useEffect(() => {
        if (userData && !(userData?.userType.isInvestor || userData?.userType.isMixed)) {
            history.push('/dashboard')
        }
    }, [location, history, userData])

    useEffect(() => {
        if (survey) {
            $(`#files`).off('change drop')
            $(`#files`).on('change drop', function (e: any) {
                $(`#label-files`).html('Uploading...')
                $(`#files`).attr('disabled', 'true')

                e.preventDefault()
                e.stopPropagation()

                const files = e.type === 'drop' ? e.originalEvent.dataTransfer.files : e.target.files

                const onFinish = () => {
                    $(`#label-files`).html('DROP FILES OR CLICK TO UPLOAD')
                    $(`#files`).removeAttr('disabled')
                    $(`#label-files`).removeClass('ondrag')
                }

                const onError = (error?: any) => {
                    enqueueSnackbar(error?.message ?? 'Upload failed, Please try again!', { variant: 'error' })
                }

                const onSuccess = response => {
                    if (response?.data?.urls) {
                        const newValue = Array.isArray(uploadedFiles) ? [...uploadedFiles] : []

                        ;[...files].forEach((file: any) => {
                            newValue.push({
                                name: file.name,
                                content: response.data.urls[file.name],
                                type: file.type,
                            })
                        })

                        setUploadedFiles(newValue)
                        $(this).val('')
                    } else {
                        onError()
                    }
                }

                surveyUploadFile({
                    files: files,
                    ContextId: survey.meta.contextId,
                    FormContentItemId: survey.meta.formContentItemId,
                    FormContentItemVersionId: survey.meta.formContentItemVersionId,
                })
                    .then(onSuccess)
                    .catch(onError)
                    .finally(onFinish)
            })
        }
    }, [enqueueSnackbar, survey, surveyUploadFile, uploadedFiles])

    // Add Realtime Min Max Error
    const minMaxRealtimeError =
        !InvestmentSizeMax ||
        !InvestmentSizeMin ||
        investmentSizeNoMax ||
        investmentSizeNoMin ||
        parseFloat(InvestmentSizeMax?.toString().replace(/,/g, '')) * unitPair[maxInvestmentSizeUnit] >
            parseFloat(InvestmentSizeMin?.toString().replace(/,/g, '')) * unitPair[minInvestmentSizeUnit]

    const dataNAICSOptions = [
        {
            id: '0',
            value: 'Select All',
            children: dataNAICS ? [...dataNAICS] : [],
        },
    ]

    return (
        <Grid container spacing={3}>
            {!props.viewAs && (
                <Grid item sm={12} md={4} lg={3}>
                    <div className={classes.sideBarContainer}>
                        <SideBarProfile />
                    </div>
                </Grid>
            )}

            <Grid item sm={12} md={8} lg={props.viewAs ? 12 : 6} style={{ paddingLeft: '40px', paddingRight: '40px', paddingTop: '30px' }}>
                <Grid container className={classes.gridMain}>
                    <Grid container item xs={12} md={12} lg={props.viewAs ? 12 : 9}>
                        <Box className={classes.containerMain}>
                            {
                                //survey && <SurveyQuestion survey={survey} surveyType="INVESTOR_PREFERENCES" />
                            }
                            <Typography variant="h2" style={{ color: '#013c4d', fontWeight: 600 }}>
                                Investor Preferences
                            </Typography>
                            {isLoading || isGetUserProfileLoading ? (
                                <ProgressIndicator />
                            ) : (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div style={{ margin: '32px 0' }}>
                                        <Typography className={classes.mainLabel}>In which geographies do you invest? *</Typography>
                                        <Typography color="error" className={classes.errorInput} data-testid="question-geographies-error">
                                            {localError['selectedGeographies'] ? 'Please select at least 1 geography' : null}
                                        </Typography>
                                        <Typography className={classes.subLabel}>Select all that apply.</Typography>
                                        <TreeView
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpanded={['0']}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                        >
                                            {renderTree(geographyChoices, selectedGeographies, setSelectedGeographies)}
                                        </TreeView>
                                    </div>
                                    <div style={{ margin: '32px 0' }} data-testid="question-currency-wrapper">
                                        <Typography className={classes.mainLabel}>
                                            Please select the currency in which you will report your answers. *
                                        </Typography>
                                        <Controller
                                            name="Currency"
                                            control={control}
                                            defaultValue={survey?.initialData.Currency}
                                            render={({ field }) => (
                                                <Select
                                                    id="standard-full-width question-currency-input"
                                                    inputProps={{
                                                        'data-testid': 'question-currency-input',
                                                    }}
                                                    label=""
                                                    fullWidth
                                                    startAdornment={<InputAdornment position="start">{currencySymbol}</InputAdornment>}
                                                    {...field}
                                                >
                                                    {currencyChoices.map(currency => (
                                                        <MenuItem key={currency.value} value={currency.text}>
                                                            {currency.text}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        <Typography color="error" className={classes.errorInput} data-testid="question-currency-error">
                                            {errors?.Currency?.message}
                                        </Typography>
                                    </div>
                                    <div style={{ margin: '32px 0' }}>
                                        <Typography className={classes.mainLabel}>
                                            What is your investment size per transaction? *
                                        </Typography>
                                        <Typography className={classes.subLabel}>
                                            <Typography
                                                color="error"
                                                className={classes.errorInput}
                                                data-testid="question-min-investment-size-max-error"
                                            >
                                                {minMaxRealtimeError ? '' : 'Max Investment should be more than Min Investment'}
                                            </Typography>
                                        </Typography>
                                        <Typography className={classes.subLabel}>
                                            <span style={{ fontWeight: 600 }}>Minimum</span> Investment Size
                                            <Typography
                                                color="error"
                                                className={classes.errorInput}
                                                data-testid="question-min-investment-size-error"
                                            >
                                                {errors?.InvestmentSizeMin?.message}
                                            </Typography>
                                        </Typography>
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
                                            data-testid="question-min-investment-wrapper"
                                        >
                                            {!investmentSizeNoMin && (
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    className={classes.capitalField}
                                                    defaultValue={survey?.initialData?.InvestmentSize?.minimum?.numeric ?? null}
                                                    inputProps={{
                                                        'data-testid': 'question-min-investment-input',
                                                    }}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                        inputComponent: NumberFormatCustom,
                                                        endAdornment: (
                                                            <Select
                                                                value={minInvestmentSizeUnit ?? 'Million'}
                                                                className={classes.capitalFieldEndAdornment}
                                                                displayEmpty
                                                                inputProps={{
                                                                    'aria-label': 'Without label',
                                                                    'data-testid': 'question-min-investment-unit',
                                                                }}
                                                                onChange={e => handleChange(e, setMinInvestmentSizeUnit)}
                                                            >
                                                                <MenuItem value="Thousand">Thousand</MenuItem>
                                                                <MenuItem value="Million">Million</MenuItem>
                                                                <MenuItem value="Billion">Billion</MenuItem>
                                                            </Select>
                                                        ),
                                                    }}
                                                    placeholder="Enter here"
                                                    {...register('InvestmentSizeMin')}
                                                />
                                            )}
                                            <Controller
                                                name="InvestmentSizeNoMin"
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        data-testid="question-no-min-investment-input"
                                                        defaultChecked={survey?.initialData?.InvestmentSize?.minimum === null}
                                                    />
                                                )}
                                            />
                                            <Typography>No minimum</Typography>
                                        </div>
                                        <Divider />
                                        <Typography className={classes.subLabel}>
                                            <span style={{ fontWeight: 600 }}>Maximum</span> Investment Size
                                            <Typography
                                                color="error"
                                                className={classes.errorInput}
                                                data-testid="question-max-investment-size-error"
                                            >
                                                {errors?.InvestmentSizeMax?.message}
                                                {errors?.InvestmentSizeMin?.message === maxMinValidationMessage &&
                                                    'Max Investment should be more than Min Investment'}
                                            </Typography>
                                        </Typography>
                                        <div
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            data-testid="question-max-investment-wrapper"
                                        >
                                            {!investmentSizeNoMax && (
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    className={classes.capitalField}
                                                    defaultValue={survey?.initialData?.InvestmentSize?.maximum?.numeric ?? null}
                                                    inputProps={{
                                                        'data-testid': 'question-max-investment-input',
                                                    }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                        startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                        endAdornment: (
                                                            <Select
                                                                value={maxInvestmentSizeUnit ?? 'Million'}
                                                                className={classes.capitalFieldEndAdornment}
                                                                displayEmpty
                                                                inputProps={{
                                                                    'data-testid': 'question-max-investment-unit',
                                                                    'aria-label': 'Without label',
                                                                }}
                                                                onChange={e => handleChange(e, setMaxInvestmentSizeUnit)}
                                                            >
                                                                <MenuItem value="Thousand">Thousand</MenuItem>
                                                                <MenuItem value="Million">Million</MenuItem>
                                                                <MenuItem value="Billion">Billion</MenuItem>
                                                            </Select>
                                                        ),
                                                    }}
                                                    placeholder="Enter here"
                                                    {...register('InvestmentSizeMax')}
                                                />
                                            )}
                                            <Controller
                                                name="InvestmentSizeNoMax"
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        data-testid="question-no-max-investment-input"
                                                        defaultChecked={survey?.initialData?.InvestmentSize?.maximum === null}
                                                    />
                                                )}
                                            />{' '}
                                            <Typography>No maximum</Typography>
                                        </div>
                                    </div>
                                    <div style={{ margin: '32px 0' }}>
                                        <Typography className={classes.mainLabel}>
                                            {`What is your minimum return requirement or cost of capital (assume margin over SOFR for variable
                                        rate transactions)? *`}
                                        </Typography>
                                        <Typography
                                            color="error"
                                            className={classes.errorInput}
                                            style={{ marginBottom: 20 }}
                                            data-testid="question-min-return-investment-error"
                                        >
                                            {errors?.ReturnMin?.message}
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {!returnNoMin && (
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    className={classes.capitalField}
                                                    type="number"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                    }}
                                                    placeholder="Enter here"
                                                    {...register('ReturnMin')}
                                                />
                                            )}
                                            <Controller
                                                name="ReturnNoMin"
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        defaultChecked={survey?.initialData?.MinReturn?.minimum === null}
                                                    />
                                                )}
                                            />{' '}
                                            <Typography>No minimum</Typography>
                                        </div>
                                    </div>
                                    <div style={{ margin: '32px 0' }}>
                                        <Typography className={classes.mainLabel}>
                                            In which maturity ranges will you invest (select all that apply)? *
                                        </Typography>
                                        <Typography color="error" className={classes.errorInput} data-testid="question-maturity-error">
                                            {localError['selectedMaxTerms'] ? 'Please select at least 1 maturity range' : null}
                                        </Typography>
                                        <Typography className={classes.subLabel}>Select all that apply.</Typography>
                                        <TreeView
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpanded={['0']}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                        >
                                            {renderTree(maxTermChoices, selectedMaxTerms, setSelectedMaxTerms)}
                                        </TreeView>
                                    </div>
                                    <div style={{ margin: '32px 0' }}>
                                        <Typography className={classes.mainLabel}>
                                            Where in the capital structure do you invest? *
                                        </Typography>
                                        <Typography
                                            color="error"
                                            className={classes.errorInput}
                                            data-testid="question-capital-structure-error"
                                        >
                                            {localError['selectedCapitalStructures'] ? 'Please select at least 1 capital structure' : null}
                                        </Typography>
                                        <Typography className={classes.subLabel}>Select all that apply.</Typography>
                                        <TreeView
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpanded={['0']}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                        >
                                            {renderTree(capitalStructuresChoices, selectedCapitalStructures, setSelectedCapitalStructures)}
                                        </TreeView>
                                    </div>
                                    <div style={{ margin: '32px 0' }}>
                                        <Typography className={classes.mainLabel}>In which structures do you invest? *</Typography>
                                        <Typography
                                            color="error"
                                            className={classes.errorInput}
                                            data-testid="question-investment-structure-error"
                                        >
                                            {localError['selectedInvestmentStructures']
                                                ? 'Please select at least 1 investment structure'
                                                : null}
                                        </Typography>
                                        <Typography className={classes.subLabel}>Select all that apply.</Typography>
                                        <TreeView
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpanded={['0']}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                        >
                                            {renderTree(
                                                investmentStructuresChoices,
                                                selectedInvestmentStructures,
                                                setSelectedInvestmentStructures,
                                            )}
                                        </TreeView>
                                        {selectedInvestmentStructures.includes('Other') ? (
                                            <TextField
                                                id="outlined-start-adornment"
                                                className={classes.capitalField}
                                                style={{ marginLeft: 40, marginTop: 16 }}
                                                placeholder="Enter here"
                                                {...register('InvestmentStructuresOther')}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div style={{ margin: '32px 0' }}>
                                        <Typography className={classes.mainLabel}>
                                            What is the approximate total AUM of the portfolios managed by you/your group (in USD)? *
                                        </Typography>
                                        <Typography
                                            color="error"
                                            className={classes.errorInput}
                                            data-testid="question-total-porto-aum-error"
                                        >
                                            {errors?.TotalPortfolioAum?.message}
                                        </Typography>
                                        <FormControl>
                                            <Controller
                                                rules={{ required: true }}
                                                control={control}
                                                name="TotalPortfolioAum"
                                                defaultValue={survey?.initialData.TotalPortfolioAum}
                                                render={({ field }) => (
                                                    <RadioGroup {...field}>
                                                        <FormControlLabel
                                                            value="Under $50 Million"
                                                            control={<Radio />}
                                                            label="Under $50 Million"
                                                        />
                                                        <FormControlLabel
                                                            value="$50 Million - $250 Million"
                                                            control={<Radio />}
                                                            label="$50 Million - $250 Million"
                                                        />
                                                        <FormControlLabel
                                                            value="$250 Million - $1 Billion"
                                                            control={<Radio />}
                                                            label="$250 Million - $1 Billion"
                                                        />
                                                        <FormControlLabel
                                                            value="$1 Billion - $10 Billion"
                                                            control={<Radio />}
                                                            label="$1 Billion - $10 Billion"
                                                        />
                                                        <FormControlLabel
                                                            value="$10 Billion - $100 Billion"
                                                            control={<Radio />}
                                                            label="$10 Billion - $100 Billion"
                                                        />
                                                        <FormControlLabel
                                                            value="Over $100 Billion"
                                                            control={<Radio />}
                                                            label="Over $100 Billion"
                                                        />
                                                    </RadioGroup>
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                    <div style={{ margin: '32px 0' }}>
                                        <Typography className={classes.mainLabel}>What types of investments interest you? *</Typography>
                                        <Typography
                                            color="error"
                                            className={classes.errorInput}
                                            data-testid="question-investment-type-error"
                                        >
                                            {localError['selectedInvestments'] ? 'Please select at least 1 Investment types' : null}
                                        </Typography>
                                        <Typography className={classes.subLabel}>Select all that apply.</Typography>
                                        <TreeView
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpanded={['0']}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                        >
                                            {renderTree(
                                                investmentChoices,
                                                selectedInvestments,
                                                setSelectedInvestments,
                                                otherInvestmentTypes,
                                                setOtherInvestmentTypes,
                                            )}
                                        </TreeView>
                                    </div>
                                    {additionalData && (
                                        <div data-testid="question-revenue-ebitda-enterprise-wrapper">
                                            <Typography className={classes.mainLabel}>
                                                {' '}
                                                Please describe the minimum thresholds for companies in which you invest.
                                            </Typography>

                                            <Grid container>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <Typography className={classes.subLabel}>Annual Revenue *</Typography>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                                                        <TextField
                                                            id="outlined-start-adornment"
                                                            className={classes.capitalField}
                                                            inputProps={{
                                                                'data-testid': 'question-annual-revenue-input',
                                                            }}
                                                            defaultValue={survey?.initialData?.AnnualRevenue?.numeric ?? null}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">{currencySymbol}</InputAdornment>
                                                                ),
                                                                inputComponent: NumberFormatCustom,
                                                                endAdornment: (
                                                                    <Select
                                                                        value={annualRevenueUnit ?? 'Million'}
                                                                        className={classes.capitalFieldEndAdornment}
                                                                        displayEmpty
                                                                        inputProps={{
                                                                            'data-testid': 'question-annual-revenue-unit',
                                                                            'aria-label': 'Without label',
                                                                        }}
                                                                        onChange={e => handleChange(e, setAnnualRevenueUnit)}
                                                                    >
                                                                        <MenuItem value="Thousand">Thousand</MenuItem>
                                                                        <MenuItem value="Million">Million</MenuItem>
                                                                        <MenuItem value="Billion">Billion</MenuItem>
                                                                    </Select>
                                                                ),
                                                            }}
                                                            placeholder="Enter here"
                                                            {...register('AnnualRevenue')}
                                                        />
                                                    </div>
                                                    <Typography
                                                        color="error"
                                                        className={classes.errorInput}
                                                        data-testid="question-annual-revenue-error"
                                                    >
                                                        {errors?.AnnualRevenue?.message}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <Typography className={classes.subLabel}>EBITDA *</Typography>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                                                        <TextField
                                                            id="outlined-start-adornment"
                                                            className={classes.capitalField}
                                                            defaultValue={survey?.initialData?.EBITDA?.numeric ?? null}
                                                            inputProps={{
                                                                'data-testid': 'question-ebitda-input',
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">{currencySymbol}</InputAdornment>
                                                                ),
                                                                inputComponent: NumberFormatCustom,
                                                                endAdornment: (
                                                                    <Select
                                                                        value={ebitdaUnit ?? 'Million'}
                                                                        className={classes.capitalFieldEndAdornment}
                                                                        displayEmpty
                                                                        inputProps={{
                                                                            'data-testid': 'question-ebitda-unit',
                                                                            'aria-label': 'Without label',
                                                                        }}
                                                                        onChange={e => handleChange(e, setEBITDAUnit)}
                                                                    >
                                                                        <MenuItem value="Thousand">Thousand</MenuItem>
                                                                        <MenuItem value="Million">Million</MenuItem>
                                                                        <MenuItem value="Billion">Billion</MenuItem>
                                                                    </Select>
                                                                ),
                                                            }}
                                                            placeholder="Enter here"
                                                            {...register('EBITDA')}
                                                        />
                                                    </div>
                                                    <Typography
                                                        color="error"
                                                        className={classes.errorInput}
                                                        data-testid="question-ebitda-error"
                                                    >
                                                        {errors?.EBITDA?.message}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <Typography className={classes.subLabel}>Enterprise Value *</Typography>
                                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                                                        <TextField
                                                            id="outlined-start-adornment"
                                                            className={classes.capitalField}
                                                            defaultValue={survey?.initialData?.AnnualRevenue?.numeric ?? null}
                                                            inputProps={{
                                                                'data-testid': 'question-enterprise-value-input',
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">{currencySymbol}</InputAdornment>
                                                                ),
                                                                inputComponent: NumberFormatCustom,
                                                                endAdornment: (
                                                                    <Select
                                                                        value={enterpriseValueUnit ?? 'Million'}
                                                                        className={classes.capitalFieldEndAdornment}
                                                                        displayEmpty
                                                                        inputProps={{
                                                                            'aria-label': 'Without label',
                                                                            'data-testid': 'question-enterprise-value-unit',
                                                                        }}
                                                                        onChange={e => handleChange(e, setEnterpriseValueUnit)}
                                                                    >
                                                                        <MenuItem value="Thousand">Thousand</MenuItem>
                                                                        <MenuItem value="Million">Million</MenuItem>
                                                                        <MenuItem value="Billion">Billion</MenuItem>
                                                                    </Select>
                                                                ),
                                                            }}
                                                            placeholder="Enter here"
                                                            {...register('EnterpriseValue')}
                                                        />
                                                    </div>
                                                    <Typography
                                                        color="error"
                                                        className={classes.errorInput}
                                                        data-testid="question-enterprise-value-error"
                                                    >
                                                        {errors?.EnterpriseValue?.message}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                    {additionalDataNAICS && (
                                        <div style={{ margin: '32px 0' }}>
                                            <Typography className={classes.mainLabel}>What types of NAICS interest you? *</Typography>
                                            <Typography
                                                color="error"
                                                className={classes.errorInput}
                                                data-testid="question-enterprise-value-error"
                                            >
                                                {errors?.SelectedNAICS?.message}
                                            </Typography>
                                            <Typography className={classes.subLabel}>Select all that apply.</Typography>
                                            <TreeView
                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                defaultExpanded={['0']}
                                                defaultExpandIcon={<ChevronRightIcon />}
                                            >
                                                {typeof dataNAICS !== 'undefined' &&
                                                    dataNAICSOptions.map(data => renderTreeTaxonomy(data, selectedNAICS, setSelectedNAICS))}
                                            </TreeView>
                                        </div>
                                    )}
                                    <div style={{ margin: '32px 0' }}>
                                        <Typography className={classes.mainLabel}>
                                            Please provide any additional comments you would like us to consider.
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            placeholder="Type here"
                                            multiline
                                            rows={4}
                                            maxRows={4}
                                            {...register('Comments')}
                                        />
                                        <Typography className={classes.subLabel}>
                                            You can also upload files containing additional investment criteria and details using the button
                                            below. Please reach out to{' '}
                                            <a href="mailto:info@dealbridge.net" style={{ color: colorPalette.primary }}>
                                                info@dealbridge.net
                                            </a>{' '}
                                            with any questions.
                                        </Typography>
                                        <Divider />
                                        <Typography className={classes.mainLabel} style={{ marginTop: 8 }}>
                                            Additional Files
                                        </Typography>
                                        <Typography className={classes.subLabel}>
                                            Please upload investment preferences and details here.
                                        </Typography>
                                        {/* <DropzoneArea
                                                    acceptedFiles={['image/*', 'video/*', 'application/*']}
                                                    onChange={handleFileUpload}
                                                    showFileNames
                                                    dropzoneText="Drop files or click to upload"
                                                    showAlerts={false}
                                                    filesLimit={20}
                                                /> */}
                                        <div className="drag-zone">
                                            <div style={{ position: 'relative' }}>
                                                <input
                                                    className="file-input"
                                                    onDragOver={onDragOver}
                                                    onDragLeave={onDragLeave}
                                                    ref={filesRef}
                                                    multiple
                                                    id="files"
                                                    type="file"
                                                />
                                                <label ref={labelFilesRef} htmlFor="files" id="label-files" className="label-file-input">
                                                    <p>DROP FILES OR CLICK TO UPLOAD</p>
                                                </label>
                                            </div>
                                            {uploadedFiles.length > 0 && (
                                                <Button className="clean" onClick={onClean} variant="outlined" size="medium" type="submit">
                                                    Clean
                                                </Button>
                                            )}
                                            <div className="container-preview">
                                                {uploadedFiles.map(file => {
                                                    return (
                                                        <div className="file-preview">
                                                            <div className="file-preview-name">
                                                                <a
                                                                    href={file.content}
                                                                    title={file.name}
                                                                    download={file.name}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {file.name}
                                                                </a>
                                                            </div>
                                                            <div className="file-preview-remove">
                                                                <span onClick={onRemove(file)}>Remove this file</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <Grid className={classes.buttonContainer} container>
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center">
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    type="submit"
                                                    disabled={isLoadingSubmission || !minMaxRealtimeError}
                                                >
                                                    {isLoadingSubmission && (
                                                        <CircularProgress className={classes.circularProgress} size={20} />
                                                    )}
                                                    Submit
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid>
                                            {isError && (
                                                <Grid item xs={12}>
                                                    {errorMessageKeys?.map((errorMessageKey, index) => (
                                                        <Typography
                                                            key={index + errorMessageKey}
                                                            className={classes.errorMessage}
                                                            color="error"
                                                            data-testid={`question-submit-error question-submit-error-${index}`}
                                                        >
                                                            {errorMessageKey}: &nbsp;
                                                            {errorResponse?.[errorMessageKey]?.join(', ') ?? 'Something wrong.'}
                                                        </Typography>
                                                    )) ?? (
                                                        <Typography className={classes.errorMessage} color="error">
                                                            Something wrong.
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <ScrollToPageTop />
            </Grid>

            {!props.viewAs && (
                <Grid item sm={12} md={12} lg={3} className={classes.containerContactUs}>
                    <ContactUsCard />
                </Grid>
            )}
        </Grid>
    )
})
