import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import config from 'config/index'

const usePageTracking = () => {
    const location = useLocation()
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!window.location.href.includes('localhost') && config.GA_TRACKING_ID) {
            ReactGA.initialize(config.GA_TRACKING_ID ?? '')
            setInitialized(true)
        }
    }, [])

    useEffect(() => {
        if (initialized) {
            ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
        }
    }, [initialized, location])
}

export default usePageTracking
