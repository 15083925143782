import { createSlice } from '@reduxjs/toolkit'

import { IReduxGeneral } from 'store/modules/general/general'

const reducerName = 'general'

export const initialState: IReduxGeneral.IInitialState = {
    redirectToPath: '',
    marketplaceTransactionViewType: 'Grid',
    bssTransactionViewType: 'Table',
    marketplaceGridCard: 10,
    marketplaceTableRow: 10,
    bssGridCard: 10,
    bssTableRow: 10,
    galTableRow: 5,
    campaignModalOpen: false,
    campaignDetailsName: false,
    adminTableRow: 10,
    userInviteTableRow: 10,
    companyUserTableRow: 10,
    chatroomTableRow: 10,
    siteAccessTableRow: 10,
}

export const generalSlice = createSlice({
    name: reducerName,
    initialState,
    reducers: {
        setRedirectToPath: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.redirectToPath = payload
        },
        setMarketplaceTransactionViewType: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.marketplaceTransactionViewType = payload
        },
        setBSSTransactionViewType: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.bssTransactionViewType = payload
        },
        setMarketplaceGridCard: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.marketplaceGridCard = payload
        },
        setMarketplaceTableRow: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.marketplaceTableRow = payload
        },
        setBSSGridCard: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.bssGridCard = payload
        },
        setBSSTableRow: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.bssTableRow = payload
        },
        setGALTableRow: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.galTableRow = payload
        },
        setCampaignModalOpen: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.campaignModalOpen = payload
        },
        setCampaignDetailsName: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.campaignDetailsName = payload
        },
        setAdminTableRow: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.adminTableRow = payload
        },
        setUserInviteTableRow: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.userInviteTableRow = payload
        },
        setCompanyUserTableRow: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.companyUserTableRow = payload
        },
        setChatroomTableRow: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.chatroomTableRow = payload
        },
        setSiteAccessTableRow: (state: IReduxGeneral.IInitialState, { payload = '' }) => {
            state.siteAccessTableRow = payload
        },
    },
})

export const generalSliceReducer = { [reducerName]: generalSlice.reducer }
