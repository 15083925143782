import { Box, CircularProgress, makeStyles, Theme } from '@material-ui/core'
import { createStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        progressWrapper: {
            width: '100%',
            marginTop: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
)

const ProgressIndicator = () => {
    const classes = useStyles()
    return (
        <>
            <Box data-testid="progress-indicator-wrapper" className={classes.progressWrapper}>
                <CircularProgress size={40} />
            </Box>
        </>
    )
}

export default ProgressIndicator
