import { createApi } from '@reduxjs/toolkit/query/react'
import config from 'config/index'
import http from 'utils/http'
import { activityApiSlice } from '../activity/activity.query'
const reducerPath = 'managementApi'

export const managementApi = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.API_URL),
    tagTypes: ['USER_DETAILS', 'COMPANY_DETAILS', 'USER_ACCESS_LIST'],
    endpoints: builder => ({
        getUserDetails: builder.query({
            query: arg => ({
                url: `/v1/useradmin/user/${arg.userId}/`,
                method: 'GET',
            }),
            providesTags: ['USER_DETAILS'],
        }),
        updateUserDetails: builder.mutation({
            query: arg => ({
                url: `/v1/useradmin/user/${arg.userId}/`,
                method: 'PATCH',
                body: arg.payload,
            }),

            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    activityApiSlice.util.updateQueryData('getContacts', { params: body.params }, (draft: any) => {
                        const newData = draft.results.filter(item => item.content_item_id === body.userId)[0]

                        if (body.payload.firstName && body.payload.lastName) {
                            newData.first_name = body.payload.firstName
                            newData.last_name = body.payload.lastName
                        }

                        if (typeof body.payload.isEnabled !== 'undefined') {
                            newData.is_active = body.payload.isEnabled
                        }

                        if (typeof body.payload.roleNames !== 'undefined') {
                            newData.user_roles = body.payload.roleNames
                        }

                        Object.assign(draft, newData)
                    }),
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
        getCompanyDetails: builder.query({
            query: arg => ({
                url: `/v1/useradmin/company/${arg.companyId}/`,
                method: 'GET',
            }),
            providesTags: ['COMPANY_DETAILS'],
        }),
        updateCompanyDetails: builder.mutation({
            query: arg => ({
                url: `/v1/admin/company/${arg.companyId}/`,
                method: 'PATCH',
                body: arg.payload,
            }),
            invalidatesTags: ['COMPANY_DETAILS'],
        }),
        uploadCompanyLogo: builder.mutation({
            query: body => ({
                url: `/v1/admin/company/${body.companyId}/logo`,
                method: 'POST',
                body: body.data,
            }),
            invalidatesTags: ['COMPANY_DETAILS'],
        }),
        getSiteAccessUsers: builder.query({
            query: () => ({
                url: `/v1/commercialentity/whitelabel/access/`,
                method: 'GET',
            }),
            providesTags: ['USER_ACCESS_LIST'],
        }),
        updateSiteAccess: builder.mutation({
            query: arg => ({
                url: `/v1/commercialentity/whitelabel/access/${arg.contentItemId}/${arg.role}`,
                method: 'PATCH',
                body: arg.payload,
            }),
            invalidatesTags: ['USER_ACCESS_LIST'],
        }),
        requestSiteAccess: builder.mutation({
            query: arg => ({
                url: `/v1/commercialentity/whitelabel/access/`,
                method: 'POST',
                body: arg.payload,
            }),
            invalidatesTags: ['USER_ACCESS_LIST'],
        }),
        inviteSiteAccess: builder.mutation({
            query: arg => ({
                url: `/v1/inviteuser/`,
                method: 'POST',
                body: arg.payload,
            }),
            invalidatesTags: ['USER_ACCESS_LIST'],
        }),
    }),
})

export const {
    useGetSiteAccessUsersQuery,
    useGetUserDetailsQuery,
    useUpdateUserDetailsMutation,
    useGetCompanyDetailsQuery,
    useUpdateCompanyDetailsMutation,
    useUploadCompanyLogoMutation,
    useUpdateSiteAccessMutation,
    useRequestSiteAccessMutation,
    useInviteSiteAccessMutation,
} = managementApi

export const managementQueryReducer = { [reducerPath]: managementApi.reducer }
export const managementQuerySelector = state => state[reducerPath]
