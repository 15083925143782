import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxNDA } from 'store/modules/nda/nda'

const reducerPath = 'ndaApi'
export const ndaApiSlice = createApi({
    reducerPath,
    baseQuery: http(),
    endpoints: builder => ({
        extendNDA: builder.mutation<any, IReduxNDA.IReduxNDAExtend>({
            query: body => ({
                url: `/v1/nda/extend`,
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const { useExtendNDAMutation } = ndaApiSlice
export const ndaQueryReducer = { [reducerPath]: ndaApiSlice.reducer }
