import { createApi } from '@reduxjs/toolkit/query/react'
import config from 'config/index'

import http from 'utils/http'
import { ReduxCognitiveSearchTypeNamespace } from './cognitive-search'

const reducerPath = 'cognitiveSearchApi'

export const cognitiveSearchApi = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.BOX_URL),
    refetchOnMountOrArgChange: true,
    tagTypes: [],
    endpoints: builder => ({
        getOpportunity: builder.query<
            ReduxCognitiveSearchTypeNamespace.IGetOpportunityResponse,
            ReduxCognitiveSearchTypeNamespace.ICognitiveBody
        >({
            query: body => ({
                url: `/search/index/opportunity/`,
                method: 'POST',
                body,
            }),
            extraOptions: { maxRetries: 0 },
        }),
        getOpportunityAIAnswer: builder.query<ReduxCognitiveSearchTypeNamespace.IAIQResponse, ReduxCognitiveSearchTypeNamespace.IAIAnswer>({
            query: body => ({
                url: `/ai/ddq/`,
                method: 'POST',
                body,
            }),
            extraOptions: { maxRetries: 0 },
        }),
        getOpportunityAIStatus: builder.query<ReduxCognitiveSearchTypeNamespace.IAIStatusResponse, Object | void>({
            query: ddq_id => ({
                url: `/ai/status/${ddq_id}`,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 0 },
        }),
        getGeneralAIAnswer: builder.query<ReduxCognitiveSearchTypeNamespace.IAIQResponse, ReduxCognitiveSearchTypeNamespace.IAIAnswer>({
            query: body => ({
                url: `/ai/ai/`,
                method: 'POST',
                body,
            }),
            extraOptions: { maxRetries: 0 },
        }),
        getOpportunityFacets: builder.query<ReduxCognitiveSearchTypeNamespace.IGetOpportunityFacetsResponse, void>({
            query: () => ({
                url: `/search/index/opportunity/`,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 0 },
        }),
    }),
})

export const {
    useGetOpportunityQuery,
    useGetOpportunityAIAnswerQuery,
    useGetOpportunityAIStatusQuery,
    useGetGeneralAIAnswerQuery,
    useGetOpportunityFacetsQuery,
} = cognitiveSearchApi

export const cognitiveSearchQueryReducer = { [reducerPath]: cognitiveSearchApi.reducer }
export const cognitiveSearchQuerySelector = state => state[reducerPath]
