import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import config from 'config/index'
import { ReduxCommentTypeNamespace } from './comment'
import { CommentContentType, CommentStatus } from './enum'
import { toSecondUnixFormat } from 'utils/time'

const reducerPath = 'commentApi'

export const commentApi = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ACTIVITY_URL),
    tagTypes: [],
    endpoints: builder => ({
        getComments: builder.query<ReduxCommentTypeNamespace.ICommentResponseTransformed, { id: string; contentType: CommentContentType }>({
            query: ({ id, contentType }) => ({
                url: `/comment/v1/comments/${contentType}/${id}/`,
                method: 'GET',
            }),
            transformResponse: (response: ReduxCommentTypeNamespace.ICommentResponse, _, params) => {
                const comments =
                    response?.results?.map(comment => ({ ...comment, contentType: params.contentType, contentTypeId: params.id })) || []

                const thread: { [parentId: string]: ReduxCommentTypeNamespace.IThread } = {}
                const elementIdToThreadId: { [elementId: string]: string } = {}
                const comment: { [commentId: string]: ReduxCommentTypeNamespace.ICommentItem } = {}

                const threads: Array<ReduxCommentTypeNamespace.IThread> = comments
                    .filter(comment => comment.sub_comment_object && !comment.comment_parent_id)
                    .map(comment => {
                        const newObj = {
                            ...comment,
                            justResolved: false,
                            comments: [],
                        }

                        /**
                         * only for a thread that published (not resolved), it will connected to elementId
                         */
                        if (comment.status === CommentStatus['Publish']) {
                            elementIdToThreadId[comment.sub_comment_object] = newObj.id
                        }

                        thread[comment.id] = newObj

                        return newObj
                    })

                comments.forEach(_comment => {
                    _comment['created_on_ts'] = toSecondUnixFormat(_comment['created_on_ts'])
                    _comment['modified_on_ts'] = toSecondUnixFormat(_comment['modified_on_ts'])
                    // Group comment (not thread) to their parent (thread)
                    if (_comment.comment_parent_id && thread[_comment.comment_parent_id]) {
                        // Group comment based on parent (thread id)
                        thread[_comment.comment_parent_id] = {
                            ...thread[_comment.comment_parent_id],
                            comments: [...thread[_comment.comment_parent_id].comments, _comment],
                        }

                        comment[_comment.id] = _comment
                    }
                })

                return {
                    elementIdToThreadId,
                    comment,
                    thread,
                    threads,
                }
            },
            extraOptions: { maxRetries: 1 },
        }),
        createComment: builder.mutation<
            ReduxCommentTypeNamespace.ICreateCommentResponse,
            {
                body: ReduxCommentTypeNamespace.ICreateCommentBodyRequest
                commentator: ReduxCommentTypeNamespace.ICommentItem['commentator']
            }
        >({
            query: ({ body }) => ({
                url: `/comment/v1/comments/`,
                method: 'POST',
                body: body,
            }),
            async onQueryStarted({ body, commentator }, { dispatch, queryFulfilled, getState }) {
                const currentData = (getState().commentApi?.queries[
                    `getComments({"contentType":"${body.content_type}","id":"${body.object_id}"})`
                ]?.data as ReduxCommentTypeNamespace.ICommentResponseTransformed) || { thread: {}, threads: [] }

                const response = await queryFulfilled

                try {
                    const newData: ReduxCommentTypeNamespace.ICommentItem = {
                        id: response.data.id,
                        comment_parent_id: '',
                        commentator: commentator,
                        text: body.text,
                        is_banned: false,
                        status: body.status,
                        sub_comment_object: body.sub_comment_object,
                        contentType: body.content_type,
                        contentTypeId: body.object_id,
                        created_on: '',
                        created_on_ts: new Date().getTime(),
                        modified_on: '',
                        modified_on_ts: new Date().getTime(),
                        extra_data: body.extra_data,
                    }

                    dispatch(
                        commentApi.util.updateQueryData('getComments', { id: body.object_id, contentType: body.content_type }, draft => {
                            Object.assign(draft, {
                                ...currentData,
                                thread: {
                                    ...currentData.thread,
                                    [newData.id]: {
                                        ...newData,
                                        justResolved: false,
                                        comments: [],
                                    },
                                },
                                threads: [...currentData.threads, newData],
                                newThreadId: newData.id,
                                elementIdToThreadId: {
                                    ...currentData.elementIdToThreadId,
                                    [newData.sub_comment_object]: newData.id,
                                },
                            })
                        }),
                    )
                } catch (error) {
                    dispatch(
                        commentApi.util.updateQueryData('getComments', { id: body.object_id, contentType: body.content_type }, draft => {
                            Object.assign(draft, currentData)
                        }),
                    )
                }
            },
        }),
        replyComment: builder.mutation<
            ReduxCommentTypeNamespace.ICreateCommentResponse,
            {
                body: ReduxCommentTypeNamespace.IReplyCommentBodyRequest
                commentator: ReduxCommentTypeNamespace.ICommentItem['commentator']
            }
        >({
            query: ({ body }) => ({
                url: `/comment/v1/comments/`,
                method: 'POST',
                body,
            }),
            async onQueryStarted({ body, commentator }, { dispatch, queryFulfilled, getState }) {
                const currentData = (getState().commentApi?.queries[
                    `getComments({"contentType":"${body.content_type}","id":"${body.object_id}"})`
                ]?.data as ReduxCommentTypeNamespace.ICommentResponseTransformed) || { thread: {}, threads: [] }

                const response = await queryFulfilled

                try {
                    const newData: ReduxCommentTypeNamespace.ICommentItem = {
                        id: response.data.id,
                        comment_parent_id: body.comment_parent_id,
                        commentator: commentator,
                        contentType: body.content_type,
                        contentTypeId: body.object_id,
                        text: body.text,
                        is_banned: false,
                        status: body.status,
                        sub_comment_object: '',
                        created_on: '',
                        created_on_ts: new Date().getTime(),
                        modified_on: '',
                        modified_on_ts: new Date().getTime(),
                        extra_data: body.extra_data,
                    }

                    const selectedThread = currentData.thread[body.comment_parent_id]

                    dispatch(
                        commentApi.util.updateQueryData('getComments', { id: body.object_id, contentType: body.content_type }, draft => {
                            Object.assign(draft, {
                                ...currentData,
                                thread: {
                                    ...currentData.thread,
                                    [body.comment_parent_id]: {
                                        ...selectedThread,
                                        comments: [...selectedThread.comments, newData],
                                    },
                                },
                                threads: currentData.threads,
                                comment: {
                                    ...currentData.comment,
                                    [response.data.id]: newData,
                                },
                            })
                        }),
                    )
                } catch (error) {
                    dispatch(
                        commentApi.util.updateQueryData('getComments', { id: body.object_id, contentType: body.content_type }, draft => {
                            Object.assign(draft, currentData)
                        }),
                    )
                }
            },
        }),
        deleteComment: builder.mutation<
            any,
            {
                id: string
                content: {
                    parentId: string
                    content_type: CommentContentType
                    object_id: string
                }
                queryParams?: ReduxCommentTypeNamespace.IDeleteCommentQueryParams
            }
        >({
            query: ({ id, queryParams }) => ({
                url: `/comment/v1/comments/${id}`,
                method: 'DELETE',
                params: queryParams,
            }),
            async onQueryStarted({ id, content }, { dispatch, queryFulfilled, getState }) {
                const currentData = (getState().commentApi?.queries[
                    `getComments({"contentType":"${content.content_type}","id":"${content.object_id}"})`
                ]?.data as ReduxCommentTypeNamespace.ICommentResponseTransformed) || { thread: {}, threads: [] }

                try {
                    if (content.parentId) {
                        const newComment = { ...currentData.comment }
                        const selectedThread = currentData.thread[content.parentId]
                        delete newComment[id]

                        dispatch(
                            commentApi.util.updateQueryData(
                                'getComments',
                                { id: content.object_id, contentType: content.content_type },
                                draft => {
                                    Object.assign(draft, {
                                        ...currentData,
                                        thread: {
                                            ...currentData.thread,
                                            [content.parentId]: {
                                                ...selectedThread,
                                                comments: selectedThread.comments.filter(com => com.id !== id),
                                            },
                                        },
                                        threads: currentData.threads,
                                        comment: newComment,
                                    })
                                },
                            ),
                        )
                    } else {
                        // Thread
                        dispatch(
                            commentApi.util.updateQueryData(
                                'getComments',
                                { id: content.object_id, contentType: content.content_type },
                                draft => {
                                    Object.assign(draft, {
                                        ...currentData,
                                        thread: {
                                            ...currentData.thread,
                                            [id]: undefined,
                                        },
                                        threads: currentData.threads.filter(th => th.id !== id),
                                        elementIdToThreadId: {
                                            ...currentData.elementIdToThreadId,
                                            [currentData.thread[id].sub_comment_object]: undefined,
                                        },
                                    })
                                },
                            ),
                        )
                    }

                    await queryFulfilled
                } catch (error) {
                    dispatch(
                        commentApi.util.updateQueryData(
                            'getComments',
                            { id: content.object_id, contentType: content.content_type },
                            draft => {
                                Object.assign(draft, currentData)
                            },
                        ),
                    )
                }
            },
        }),
        partialUpdateComment: builder.mutation<
            ReduxCommentTypeNamespace.ICommentItem,
            {
                id: string
                queryParams?: ReduxCommentTypeNamespace.IPartialUpdateCommentQueryParams
                body: ReduxCommentTypeNamespace.IPartialUpdateCommentBodyRequest
                content: {
                    /**
                     * If the comment has parent, put the parentId, otherwise let null/empty string
                     */
                    parentId?: string
                    content_type: CommentContentType
                    object_id: string
                }
            }
        >({
            query: ({ id, queryParams, body }) => ({
                url: `/comment/v1/comments/${id}/`,
                method: 'PATCH',
                params: queryParams,
                body,
            }),
            async onQueryStarted({ id, body, content }, { dispatch, queryFulfilled, getState }) {
                const currentData = (getState().commentApi?.queries[
                    `getComments({"contentType":"${content.content_type}","id":"${content.object_id}"})`
                ]?.data as ReduxCommentTypeNamespace.ICommentResponseTransformed) || {
                    thread: {},
                    threads: [],
                    comment: {},
                    elementIdToThreadId: {},
                }

                try {
                    const parentId = content.parentId || ''
                    if (parentId) {
                        // Comment
                        const currentComment = currentData.comment[id]

                        // Possible thread/comment
                        const newData = {
                            ...currentComment,
                            ...body,
                            modified_on_ts: new Date().getTime(),
                        }

                        const selectedThread = currentData.thread[parentId]

                        dispatch(
                            commentApi.util.updateQueryData(
                                'getComments',
                                { id: content.object_id, contentType: content.content_type },
                                draft => {
                                    Object.assign(draft, {
                                        ...currentData,
                                        thread: {
                                            ...currentData.thread,
                                            [parentId]: {
                                                ...selectedThread,
                                                comments: selectedThread.comments.map(com => (com.id === id ? newData : com)),
                                            },
                                        },
                                        threads: currentData.threads,
                                        comment: {
                                            ...currentData.comment,
                                            [id]: newData,
                                        },
                                    })
                                },
                            ),
                        )
                    } else {
                        // Thread
                        const currentThread = currentData.thread[id]
                        const newData = {
                            ...currentThread,
                            ...body,
                            // Just resolved will be false when the modal closed, see the comment provider
                            justResolved: body.status === CommentStatus['Resolve'],
                            modified_on_ts: new Date().getTime(),
                        }

                        dispatch(
                            commentApi.util.updateQueryData(
                                'getComments',
                                { id: content.object_id, contentType: content.content_type },
                                draft => {
                                    Object.assign(draft, {
                                        ...currentData,
                                        thread: {
                                            ...currentData.thread,
                                            [id]: newData,
                                        },
                                        elementIdToThreadId: {
                                            ...currentData.elementIdToThreadId,
                                            /**
                                             * We should add the threadId again, because when we resolve and close the modal
                                             * We will remove this value
                                             */
                                            [newData.sub_comment_object]: id,
                                        },
                                        threads: currentData.threads.map(th => (th.id === id ? newData : th)),
                                        comment: currentData.comment,
                                    })
                                },
                            ),
                        )
                    }

                    await queryFulfilled
                } catch (error) {
                    dispatch(
                        commentApi.util.updateQueryData(
                            'getComments',
                            { id: content.object_id, contentType: content.content_type },
                            draft => {
                                Object.assign(draft, currentData)
                            },
                        ),
                    )
                }
            },
        }),
        updateComment: builder.mutation<
            ReduxCommentTypeNamespace.IUpdateCommentBodyRequest,
            {
                id: string
                queryParams: ReduxCommentTypeNamespace.IUpdateCommentQueryParams
                body: ReduxCommentTypeNamespace.IUpdateCommentBodyRequest
            }
        >({
            query: ({ id, queryParams, body }) => ({
                url: `/comment/v1/comments/${id}/`,
                method: 'PUT',
                params: queryParams,
                body,
            }),
        }),
        createChatRoom: builder.mutation<
            ReduxCommentTypeNamespace.ICreateChatRoomResponse,
            ReduxCommentTypeNamespace.ICreateChatRoomBodyRequest
        >({
            query: body => ({
                url: `/comment/v1/chat-room/`,
                method: 'POST',
                body: body,
            }),
        }),
    }),
})

export const {
    useCreateChatRoomMutation,
    useReplyCommentMutation,
    useDeleteCommentMutation,
    usePartialUpdateCommentMutation,
    useGetCommentsQuery,
    useCreateCommentMutation,
} = commentApi

export const commentQueryReducer = { [reducerPath]: commentApi.reducer }
export const commentQuerySelector = state => state[reducerPath]
