import React from 'react'

import { getDrawerContext } from '../provider'
import { ITeamDrawerPropsData } from '../definition'
import { useGetTeamDetailsQuery } from 'store/modules/commercialentity/commercialentity.query'
import { default as TeamDrawerPage } from 'app/pages/AdminPage/TeamDrawer/TeamDrawer'
import { getToken } from 'utils/localStorage/token'

export const TeamDrawer = () => {
    const drawer = React.useContext(getDrawerContext<ITeamDrawerPropsData>())

    const { teamId } = drawer.props || {}
    const access_token = getToken('access_token')

    const { data: teamDetails, isLoading: isLoadingTeamDetails } = useGetTeamDetailsQuery(
        {
            teamId: teamId,
        },
        {
            skip: !access_token || !teamId,
        },
    )

    const onClose = () => {
        drawer.onClose()
    }

    if (drawer.isOpen && drawer.category === 'team') {
        return (
            <TeamDrawerPage
                onChangeOpenDrawer={onClose}
                teamDetails={teamDetails}
                isLoadingTeamDetails={isLoadingTeamDetails}
                teamId={teamId}
            ></TeamDrawerPage>
        )
    }

    return null
}
