import { createApi } from '@reduxjs/toolkit/query/react'
import config from 'config/index'

import http from 'utils/http'
import { ReduxReportTypeNamespace } from './report'

const reducerPath = 'reportApi'

export const reportApi = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.PROXY_URL, 'platform-authorization'),
    tagTypes: [],
    endpoints: builder => ({
        getReportConfigAndAccess: builder.query<ReduxReportTypeNamespace.IGetReportConfigAndAccess, { groupId: string; reportId: string }>({
            query: param => ({
                url: `/core/reporting/group/${param.groupId}/report/${param.reportId}/`,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 1 },
        }),
        getGroups: builder.query<ReduxReportTypeNamespace.IGetGroupResponse[], {}>({
            query: () => ({
                url: `/core/reporting/groups/`,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 1 },
        }),
        getReports: builder.mutation<ReduxReportTypeNamespace.IGetReportResponse[], string>({
            query: groupId => ({
                url: `/core/reporting/group/${groupId}`,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 1 },
        }),
        getReportByGroup: builder.query<ReduxReportTypeNamespace.IGetReportResponse[], string>({
            query: groupId => ({
                url: `/core/reporting/group/${groupId}`,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 1 },
        }),
    }),
})

export const { useGetGroupsQuery, useGetReportsMutation, useGetReportByGroupQuery, useGetReportConfigAndAccessQuery } = reportApi

export const reportQueryReducer = { [reducerPath]: reportApi.reducer }
export const reportQuerySelector = state => state[reducerPath]
