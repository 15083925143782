import { Helmet } from 'react-helmet-async'

import Layout from 'app/components/Layout'
import { lazyLoad } from 'utils/loadable'

const Page = lazyLoad(
    () => import('./index'),
    module => module.default,
)

export const CampaignTemplatesPage = props => {
    return (
        <>
            <Helmet>
                <title>Campaign Templates Page</title>
                <meta name="CampaignTemplatesPage" content="Campaign Templates Page" />
            </Helmet>
            <Layout footer fullPage>
                <Page {...props} />
            </Layout>
        </>
    )
}
