export const formatSchedule = schedulesData => {
    if (schedulesData) {
        return Object.values(
            schedulesData?.reduce((acc: any, el: any) => {
                acc[el.meetingId] = acc[el.meetingId] || {
                    meetingId: el?.meetingId,
                    description: el?.description,
                    investorSetCompanyName: el?.investorSetCompanyName,
                    meetingTimes: [],
                    meetingStatus: el?.meetingStatus,
                    joinUrl: el?.joinUrl,
                    transactionId: el?.transactionId,
                    meetingParticipants: el?.meetingParticipants,
                    durationMinutes: el?.durationMinutes,
                    statusComments: el.statusComments,
                    myRoles: el.myRoles,
                }
                acc[el?.meetingId]?.meetingTimes?.push({
                    datetimeUtc: el?.datetimeUtc,
                    end: el.end,
                    meetingTimeId: el.meetingTimeId,
                })
                return acc
            }, {}),
        )
    } else {
        return []
    }
}

export const filteredOpportunitiesDisplayText = (oppList, transactionId) => {
    const _opportunities = oppList?.items.filter(item => item.contentItemId === transactionId)
    if (_opportunities) {
        return _opportunities[0]?.displayText
    } else {
        return null
    }
}
