import React from 'react'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import CancelIcon from '@material-ui/icons/Cancel'

class AppSnackbarProvider extends SnackbarProvider {
    notistackRef: React.RefObject<HTMLDivElement> = React.createRef()

    onClickClose = (key: SnackbarKey) => {
        // @ts-ignore
        this.notistackRef?.current.closeSnackbar(key)
    }

    render() {
        return (
            <SnackbarProvider
                // @ts-ignore
                ref={this.notistackRef}
                {...this.props}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={3000}
                maxSnack={5}
                preventDuplicate
                action={key => <CancelIcon onClick={() => this.onClickClose(key)} />}
            />
        )
    }
}

export default AppSnackbarProvider
