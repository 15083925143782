import { Helmet } from 'react-helmet-async'

import Layout from 'app/components/Layout'
import { lazyLoad } from 'utils/loadable'

const Page = lazyLoad(
    () => import('./index'),
    module => module.default,
)

export const SignupPage = props => {
    return (
        <>
            <Helmet>
                <title>Signup Page</title>
                <meta name="description" content="Signup page" />
            </Helmet>
            <Layout footer isSignup={false}>
                <Page {...props} />
            </Layout>
        </>
    )
}
