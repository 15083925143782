import { Box, MenuItem, MenuList } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ProgressIndicator from 'app/components/Progress/ProgressIndicator'
import { useReports } from 'app/components/Report/useReport'
import { memo } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { masterApiSlice } from 'store/modules/master/master.query'
import { userApi } from 'store/modules/user'
import { colorPalette } from 'styles/mainTheme'
import { getHostname } from 'utils/general'

// CSS Styling
const useStyles = makeStyles(theme => ({
    menuItem: {
        color: colorPalette.secondary,
        whiteSpace: 'pre-line',
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: 4,
        justifyContent: 'left',
        '&.Mui-selected': {
            backgroundColor: colorPalette.lightBluePrimary,
        },
    },
    drawerContainer: {
        paddingTop: theme.spacing(1),
        backgroundColor: colorPalette.white,
        borderRadius: 4,
        top: theme.spacing(10),
        paddingLeft: theme.spacing(5),
        width: '325px',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            width: 'auto',
        },
    },
}))

export default memo(function SideBarProfile() {
    const classes = useStyles()
    const location = useLocation()
    const hostname = getHostname()
    const { data, isFetching } = userApi.endpoints.getUserProfile.useQueryState({})
    const { data: whitelabelInfoData } = masterApiSlice.endpoints.getWhiteLabelInfo.useQueryState(
        { website: hostname },
        { skip: !hostname },
    )
    const { data: userProfile } = userApi.endpoints.getUserProfile.useQueryState({})
    const currentUserCompanyId = userProfile?.profilePart?.company?.ContentItemId
    const isOwner = whitelabelInfoData?.companyId === currentUserCompanyId

    const reports = useReports()

    if (isFetching) {
        return <ProgressIndicator />
    }

    return (
        <Box className={classes.drawerContainer}>
            <MenuList>
                <MenuItem component={RouterLink} to="/profile" className={classes.menuItem}>
                    My Settings
                </MenuItem>
                <MenuItem
                    selected={location?.pathname === '/profile' ? true : false}
                    to="/profile"
                    component={RouterLink}
                    className={classes.menuItem}
                    style={{ paddingLeft: '30px' }}
                >
                    Profile
                </MenuItem>

                {(data?.userType.isAdmin || window.location.hostname.includes('dev') || window.location.hostname.includes('localhost')) && (
                    <MenuItem
                        selected={location?.pathname === '/notification-preferences' ? true : false}
                        to="/notification-preferences"
                        component={RouterLink}
                        className={classes.menuItem}
                        style={{ paddingLeft: '30px' }}
                    >
                        Notifications
                    </MenuItem>
                )}
                {/* {data?.userType.isInvestor || data?.userType.isMixed ? (
                    <MenuItem
                        selected={location?.pathname === '/investor-preferences' ? true : false}
                        component={RouterLink}
                        to="/investor-preferences"
                        className={classes.menuItem}
                        style={{ paddingLeft: '30px' }}
                    >
                        Investor Preferences
                    </MenuItem>
                ) : null} */}
                {/* {data?.userType.isOriginator || data?.userType.isMixed ? ( */}
                <MenuItem
                    selected={location?.pathname === '/schedule-preferences' ? true : false}
                    component={RouterLink}
                    to="/schedule-preferences"
                    className={classes.menuItem}
                    style={{ paddingLeft: '30px' }}
                >
                    Scheduling
                </MenuItem>
                {/* ) : null} */}

                {data?.userType.isWLP ? (
                    <>
                        <div style={{ height: '20px' }}></div>
                        <MenuItem component={RouterLink} to="/investor-segments" className={classes.menuItem}>
                            Deals
                        </MenuItem>
                        {data?.userType.isOriginator || data?.userType.isMixed ? (
                            <MenuItem
                                selected={location?.pathname === '/global-access' ? true : false}
                                component={RouterLink}
                                to="/global-access"
                                className={classes.menuItem}
                                style={{ paddingLeft: '30px' }}
                            >
                                Data Rooms
                            </MenuItem>
                        ) : null}
                        <MenuItem
                            selected={location?.pathname === '/investor-segments' ? true : false}
                            to="/investor-segments"
                            component={RouterLink}
                            className={classes.menuItem}
                            style={{ paddingLeft: '30px' }}
                        >
                            Investor Segments
                        </MenuItem>

                        <MenuItem
                            selected={location?.pathname === '/campaign-templates' ? true : false}
                            to="/campaign-templates"
                            component={RouterLink}
                            className={classes.menuItem}
                            style={{ paddingLeft: '30px' }}
                        >
                            Campaign Templates
                        </MenuItem>

                        <MenuItem
                            selected={location?.pathname === '/campaigns' ? true : false}
                            to="/campaigns"
                            component={RouterLink}
                            className={classes.menuItem}
                            style={{ paddingLeft: '30px' }}
                        >
                            Campaigns
                        </MenuItem>
                    </>
                ) : null}
                {/* {data?.userType.isWLP ? ( */}

                {data?.permissions?.includes('wlp_administration') || data?.permissions?.includes('team_administration') ? (
                    <>
                        <div style={{ height: '20px' }}></div>
                        <MenuItem component={RouterLink} to="/company-user-management" className={classes.menuItem}>
                            Account - {userProfile?.profilePart?.company?.DisplayText}
                        </MenuItem>
                        <MenuItem
                            selected={location?.pathname === '/company-user-management' ? true : false}
                            component={RouterLink}
                            to="/company-user-management"
                            className={classes.menuItem}
                            style={{ paddingLeft: '30px' }}
                        >
                            Users
                        </MenuItem>

                        {data?.permissions?.includes('wlp_administration') ? (
                            <MenuItem
                                selected={location?.pathname === '/site-access' ? true : false}
                                component={RouterLink}
                                to="/site-access"
                                disabled={!isOwner}
                                className={classes.menuItem}
                                style={{ paddingLeft: '30px' }}
                            >
                                External Users
                            </MenuItem>
                        ) : null}
                    </>
                ) : null}
                {data?.permissions?.includes('platform_admin') ? (
                    <>
                        <div style={{ height: '20px' }}></div>
                        <MenuItem component={RouterLink} to="/admin/users" className={classes.menuItem}>
                            Administration
                        </MenuItem>
                        <MenuItem
                            selected={location?.pathname === '/admin/users'}
                            component={RouterLink}
                            to="/admin/users"
                            className={classes.menuItem}
                            style={{ paddingLeft: '30px' }}
                        >
                            All Users
                        </MenuItem>
                        {!!reports.length && (
                            <MenuItem className={classes.menuItem} style={{ paddingLeft: '30px' }}>
                                Reports
                            </MenuItem>
                        )}
                        {reports.map(report => {
                            const basePathName = `/admin/reports/${report.group.id}/${report.id}`

                            return (
                                <MenuItem
                                    key={report.id}
                                    selected={location?.pathname === basePathName}
                                    component={RouterLink}
                                    to={basePathName}
                                    className={classes.menuItem}
                                    style={{ paddingLeft: '45px' }}
                                >
                                    {report.name}
                                </MenuItem>
                            )
                        })}
                    </>
                ) : null}
            </MenuList>
        </Box>
    )
})
