export const getTransactionIdromUrl = () => {
    let id = ''
    if (window.location) {
        const pathname = window.location.pathname
        if (pathname.includes('transaction/originator/')) {
            id = pathname.split('/')[3]
        } else if (pathname.includes('transaction/')) {
            id = window.location.pathname.split('/')[2]
        }
    }

    return id
}
