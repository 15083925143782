import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxCommercialEntity } from 'store/modules/commercialentity/commercialentity'

const reducerPath = 'commercialentityApi'
export const commercialEntityApiSlice = createApi({
    reducerPath,
    baseQuery: http(),
    tagTypes: ['TEAM_DETAILS'],
    endpoints: builder => ({
        getOriginatorDdqs: builder.query<IReduxCommercialEntity.IReduxOriginatorDdqResponse[], void>({
            query: () => ({
                url: `/v1/commercialentity/originatorddqs/`,
                method: 'GET',
            }),
        }),
        partialUpdateTeam: builder.mutation({
            query: (body: IReduxCommercialEntity.IReduxTeamPartialUpdateRequest) => ({
                url: `/v1/commercialentity/team/${body.contentItemId}`,
                method: 'PATCH',
                body: {
                    defaultDdqAlias: body.defaultDdqAlias,
                },
            }),
        }),
        getTeamDetails: builder.query({
            query: body => ({
                url: `/v1/commercialentity/team/${body.teamId}/`,
                method: 'GET',
            }),
            providesTags: ['TEAM_DETAILS'],
        }),
        updateTeamDetails: builder.mutation({
            query: arg => ({
                url: `/v1/commercialentity/team/${arg.teamId}/`,
                method: 'PATCH',
                body: arg.payload,
            }),
            invalidatesTags: ['TEAM_DETAILS'],
        }),
    }),
})

export const { useGetOriginatorDdqsQuery, usePartialUpdateTeamMutation, useGetTeamDetailsQuery, useUpdateTeamDetailsMutation } =
    commercialEntityApiSlice
export const commercialEntityQueryReducer = { [reducerPath]: commercialEntityApiSlice.reducer }
