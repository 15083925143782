import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxCampaign } from 'store/modules/campaign/campaign'
import config from 'config/index'

const reducerPath = 'campaignApi'

export const campaignApiSlice = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ACTIVITY_URL),
    tagTypes: ['CAMPAIGNS', 'CAMPAIGN-JOBS', 'CAMPAIGN-DETAIL'],
    endpoints: builder => ({
        createCampaign: builder.mutation({
            query: (body: IReduxCampaign.IReduxCreateCampaign) => ({
                url: `/campaignservice/v1/campaigns/`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['CAMPAIGNS'],
        }),
        getCampaigns: builder.query<IReduxCampaign.IGetCampaignResponse, IReduxCampaign.IGetCampaignParams>({
            query: params => ({
                url: `/campaignservice/v1/campaigns/`,
                method: 'GET',
                params,
            }),
            providesTags: ['CAMPAIGNS'],
        }),
        getCampaignById: builder.query<IReduxCampaign.ICampaignItem, string>({
            query: id => ({
                url: `/campaignservice/v1/campaigns/${id}/`,
                method: 'GET',
            }),
            providesTags: ['CAMPAIGN-DETAIL'],
        }),
        updateCampaignById: builder.mutation({
            query: body => ({
                url: `/campaignservice/v1/campaigns/${body.id}/`,
                method: 'PATCH',
                body: body.payload,
            }),
            invalidatesTags: ['CAMPAIGNS', 'CAMPAIGN-DETAIL'],
        }),
        getCampaignTemplates: builder.query<IReduxCampaign.IReduxCampaignTemplatesResponse, Object | void>({
            query: () => ({
                url: `/campaignservice/v1/campaign-templates/`,
                method: 'GET',
            }),
        }),
        getCampaignDetails: builder.query({
            query: () => ({
                url: `/campaignservice/v1/campaigns/01985b97-7eec-4f4b-962f-a67dd196f398/campaign-item-jobs/`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response.results
            },
        }),
        getCampaignTemplateById: builder.query({
            query: id => ({
                url: `/campaignservice/v1/campaign-templates/${id}/`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response
            },
        }),
        getEmailTemplates: builder.query<any, Object | void>({
            query: () => ({
                url: `/campaignservice/v1/email-templates/`,
                method: 'GET',
            }),
        }),
        getCampaignItemJobs: builder.query<IReduxCampaign.IGetCampaignItemJobResponse, string>({
            query: id => ({
                url: `/campaignservice/v1/campaigns/${id}/campaign-item-jobs/`,
                method: 'GET',
            }),
            providesTags: ['CAMPAIGN-JOBS'],
        }),
        getCampaignEvents: builder.query({
            query: id => ({
                url: `/campaignservice/v1/campaigns/${id}/events/`,
                method: 'GET',
            }),
        }),
        getDistributionGroupById: builder.query({
            query: id => ({
                url: `/campaignservice/v1/distribution-groups/${id}/`,
                method: 'GET',
            }),
        }),
        partialUpdateCampaignJobById: builder.mutation<any, IReduxCampaign.IPartialUpdateCampaignJobRequest>({
            query: ({ id, body }) => ({
                url: `/campaignservice/v1/campaign-item-jobs/${id}/`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['CAMPAIGN-JOBS'],
        }),
        createCampaignEvent: builder.mutation<any, IReduxCampaign.ICreateCampaignEvent>({
            query: body => ({
                url: `/campaignservice/v1/campaign-events/`,
                method: 'POST',
                body,
            }),
        }),
        getEmailTemplateById: builder.mutation({
            query: id => ({
                url: `/campaignservice/v1/email-templates/${id}/`,
                method: 'GET',
            }),
        }),
        getCampaignJobById: builder.mutation({
            query: id => ({
                url: `/campaignservice/v1/campaign-item-jobs/${id}/`,
                method: 'GET',
            }),
        }),
        getCampaignTemplateTypes: builder.query<IReduxCampaign.ICampaignTemplateTypeResponse, Object | void>({
            query: () => ({
                url: `/campaignservice/v1/campaign-template-types/`,
                method: 'GET',
            }),
        }),
    }),
})

export const {
    useCreateCampaignMutation,
    useGetCampaignTemplatesQuery,
    useGetCampaignsQuery,
    useUpdateCampaignByIdMutation,
    useGetCampaignByIdQuery,
    useGetEmailTemplatesQuery,
    useGetCampaignItemJobsQuery,
    useGetCampaignEventsQuery,
    useGetCampaignTemplateByIdQuery,
    useGetCampaignDetailsQuery,
    useGetDistributionGroupByIdQuery,
    usePartialUpdateCampaignJobByIdMutation,
    useCreateCampaignEventMutation,
    useGetCampaignJobByIdMutation,
    useGetEmailTemplateByIdMutation,
    useGetCampaignTemplateTypesQuery,
} = campaignApiSlice

export const campaignQueryReducer = { [reducerPath]: campaignApiSlice.reducer }
export const campaignQuerySelector = state => state[reducerPath]
