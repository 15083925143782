type TokenData = {
    access_token: string
    refresh_token: string
    token_type: string
}

/**
 *  Adds token object to local storage
 */
export const setToken = (value: string) => {
    localStorage.setItem('token', value)
}

/**
 *  Removes token object from local storage
 */
export const removeToken = () => {
    localStorage.removeItem('token')
}

/**
 *  Retreives property from token object in local storage by name
 *  valid names: access_token, refresh_token, token_type
 */
export const getToken = (name: string) => {
    const rawTokenData = localStorage.getItem('token')
    const tokenData = JSON.parse(rawTokenData!) as TokenData

    const token = tokenData && tokenData[name]

    if (!token) {
        return null
    }

    return token
}

export const getFullToken = () => {
    const rawTokenData = localStorage.getItem('token')
    const tokenData = JSON.parse(rawTokenData!) as TokenData

    const token = tokenData?.access_token ?? ''
    const tokenType = tokenData?.token_type ?? ''

    if (!token || !tokenType) {
        return null
    }

    return `${tokenType} ${token}`
}
