import $ from 'jquery'
import { useSnackbar } from 'notistack'
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core'
import { CheckCircle, CheckCircleOutline, KeyboardArrowDown, KeyboardArrowUp, MoreVert } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import { useContext, useMemo, useState } from 'react'
import { ReduxCommentTypeNamespace } from 'store/modules/comment/comment'

import { ICommentRoom } from './CommentRoom'
import { usePartialUpdateCommentMutation } from 'store/modules/comment'
import { CommentContentType, CommentStatus } from 'store/modules/comment/enum'
import { getDrawerContext } from 'app/context/drawer/provider'
import { useHistory } from 'react-router-dom'
import { CommentDrawerFilterType } from 'app/context/drawer/definition'
import { getTransactionIdromUrl } from 'utils/transaction/general'

interface IThreadHeader {
    threadId: string
    isCurrentUserAuthor?: boolean
    containerClass?: string
    threadStatus: CommentStatus
    viewMode: ICommentRoom['viewMode']
    /**
     * Used when showCloseAction is true
     */
    onClose?: Function
    /**
     * Used when showResolveAction or showViewAllAction  is true
     */
    threadMeta?: {
        contentType: CommentContentType
        contentTypeId: string
    }
    /**
     * Used when showNextPrevAction is true
     */
    threads?: ReduxCommentTypeNamespace.ICommentResponseTransformed['threads']
    headerProps?: {
        /**
         * Please provide props "threads"
         */
        showNextPrevAction?: boolean
        /**
         * ViewAll means open the drawer that show all of comments in this transaction.
         *
         * Please provide props "threadMeta"
         */
        showViewAllAction?: boolean
        /**
         * Please provide props "threadMeta"
         */
        showResolveAction?: boolean
        /**
         * There are 3 menus:
         * - Edit
         * - Delete
         * - Resolved
         *
         * Resolved will be shown when the showResolveAction is true
         */
        showMenuAction?: boolean
        onEdit?: Function
        onDelete?: Function
        /**
         * Please provide props "onClose"
         */
        showCloseAction?: boolean
    }
}

export const ThreadHeader = ({
    threadId,
    threadStatus,
    viewMode,
    onClose,
    isCurrentUserAuthor = false,
    threads = [],
    threadMeta,
    containerClass = 'chat-room--container',
    headerProps = {
        showCloseAction: false,
        showMenuAction: false,
        showNextPrevAction: false,
        showResolveAction: false,
        showViewAllAction: false,
        onEdit: function () {},
        onDelete: function () {},
    },
}: IThreadHeader) => {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const drawer = useContext(getDrawerContext())
    const [partialUpdateComment, { isLoading: isUpdatingComment }] = usePartialUpdateCommentMutation()

    const sortedThreadBasedElementPosition = useMemo(() => {
        return (
            threads
                .filter(thread => thread.status === CommentStatus['Publish'])
                .map(thread => {
                    return {
                        ...thread,
                        elPosition: $(`#${thread.sub_comment_object}`)?.offset()?.top ?? 0,
                    }
                })
                .sort((a, b) => a.elPosition - b.elPosition) ?? []
        )
    }, [threads])

    const totalThread = sortedThreadBasedElementPosition.length
    const currentThreadIndex = sortedThreadBasedElementPosition.findIndex(thread => thread.id === threadId)

    const goToElement = (nextElementId, nextThread) => {
        const currentThread = sortedThreadBasedElementPosition[currentThreadIndex]

        if (currentThread.extra_data.pathname && currentThread.extra_data.pathname === nextThread.extra_data.pathname) {
            const el = $(`#${nextElementId}`)
            if (el) {
                onClose?.()
                const top = el.offset()?.top ?? 0
                const scrollTop = top - window.document.documentElement.clientHeight / 3

                $('html,body').animate(
                    {
                        scrollTop: scrollTop,
                    },
                    'slow',
                )

                setTimeout(() => {
                    el.trigger('click')
                }, 500)
            }
        } else {
            onClose?.()
            history.push(`${nextThread.extra_data.pathname}?comment=${nextThread.id}`)
        }
    }

    const goToPrevComment = () => {
        if (!totalThread || currentThreadIndex === 0) {
            return
        }

        const thread = sortedThreadBasedElementPosition?.[currentThreadIndex - 1]
        if (thread) {
            goToElement(thread.sub_comment_object, thread)
        }
    }

    const goToNextComment = () => {
        if (!totalThread || currentThreadIndex === totalThread - 1) {
            return
        }

        const thread = sortedThreadBasedElementPosition?.[currentThreadIndex + 1]
        if (thread) {
            goToElement(thread.sub_comment_object, thread)
        }
    }

    const openDrawer = () => {
        if (threadMeta) {
            if (threadMeta.contentType === CommentContentType['Application Submission']) {
                const transactionId = getTransactionIdromUrl()
                drawer.onHandle('comment')({
                    id: transactionId,
                    sourceId: CommentContentType['Opportunity'],
                    status: [CommentStatus.Publish],
                    type: CommentDrawerFilterType['Application Submission'],
                })
            } else {
                drawer.onHandle('comment')({
                    id: threadMeta.contentTypeId,
                    sourceId: threadMeta.contentType,
                    status: [CommentStatus.Publish],
                    type: CommentDrawerFilterType['Opportunity'],
                })
            }
        }
    }

    const resolve = async () => {
        if (threadStatus === CommentStatus['Publish']) {
            try {
                await partialUpdateComment({
                    id: threadId,
                    body: {
                        status: CommentStatus['Resolve'],
                    },
                    content: {
                        content_type: threadMeta?.contentType || CommentContentType['Opportunity'],
                        object_id: threadMeta?.contentTypeId || '',
                    },
                }).unwrap()
            } catch (err) {
                enqueueSnackbar('Failed resolve the thread', { variant: 'error' })
            }
        } else {
            try {
                await partialUpdateComment({
                    id: threadId,
                    body: {
                        status: CommentStatus['Publish'],
                    },
                    content: {
                        content_type: threadMeta?.contentType || CommentContentType['Opportunity'],
                        object_id: threadMeta?.contentTypeId || '',
                    },
                }).unwrap()
            } catch (err) {
                enqueueSnackbar('Failed undo resolve the thread', { variant: 'error' })
            }
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onDelete = (e?: any) => {
        e.stopPropagation()
        headerProps.onDelete?.(true)
        headerProps.onEdit?.(false)
        handleClose()
    }

    const onEdit = (e?: any) => {
        e.stopPropagation()
        headerProps.onDelete?.(false)
        headerProps.onEdit?.(true)
        handleClose()
    }

    return (
        <div className={`chat-room__header ${containerClass}`}>
            <div className="chat-room__header__left">
                {viewMode === 'THREAD_VIEW' ? (
                    <>
                        {headerProps.showNextPrevAction && (
                            <>
                                <Button
                                    className="chat-room__header__left-action"
                                    disabled={currentThreadIndex === 0}
                                    style={currentThreadIndex === 0 ? { color: 'rgb(0,0,0,0.26)', background: 'none' } : {}}
                                    onClick={goToPrevComment}
                                >
                                    <KeyboardArrowUp fontSize={'small'} />
                                </Button>
                                <Button
                                    className="chat-room__header__left-action"
                                    disabled={currentThreadIndex === totalThread - 1}
                                    style={currentThreadIndex === totalThread - 1 ? { color: 'rgb(0,0,0,0.26)', background: 'none' } : {}}
                                    onClick={goToNextComment}
                                >
                                    <KeyboardArrowDown fontSize={'small'} />
                                </Button>
                                <span className="chat-room__header__left-label">
                                    {currentThreadIndex + 1} of {totalThread}
                                </span>
                            </>
                        )}

                        {headerProps.showViewAllAction && headerProps.showNextPrevAction && (
                            <span className="chat-room__header-splitter">|</span>
                        )}

                        {headerProps.showViewAllAction && (
                            <Button className="chat-room__header__left-action chat-room__view-all" onClick={openDrawer}>
                                View All
                            </Button>
                        )}
                    </>
                ) : (
                    <Button className="chat-room__header__left-action chat-room__view-all" disabled>
                        Comments
                    </Button>
                )}
            </div>

            <div className="chat-room__header__right">
                {viewMode === 'THREAD_VIEW' && (
                    <>
                        {headerProps.showResolveAction && isCurrentUserAuthor && (
                            <Button
                                className="chat-room__header__right-action"
                                onClick={resolve}
                                disabled={isUpdatingComment}
                                startIcon={isUpdatingComment && <CircularProgress size={16} />}
                            >
                                {threadStatus === CommentStatus['Resolve'] ? (
                                    <CheckCircle fontSize={'small'} color="primary" />
                                ) : (
                                    <CheckCircleOutline fontSize={'small'} color="disabled" />
                                )}
                            </Button>
                        )}

                        {headerProps.showMenuAction && isCurrentUserAuthor && (
                            <div style={{ position: 'relative' }}>
                                <Button aria-controls="thread-menu" className="chat-room__header__right-action" onClick={handleClick}>
                                    <MoreVert fontSize={'small'} />
                                </Button>

                                <Menu
                                    id="thread-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    getContentAnchorEl={null}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {!!headerProps?.onEdit && (
                                        <MenuItem key="Edit" onClick={onEdit}>
                                            Edit
                                        </MenuItem>
                                    )}

                                    {!!headerProps?.onDelete && (
                                        <MenuItem key="Delete" onClick={onDelete}>
                                            Delete
                                        </MenuItem>
                                    )}

                                    {headerProps.showResolveAction && (
                                        <MenuItem key="Resolve" onClick={resolve}>
                                            {threadStatus === CommentStatus['Publish'] ? 'Resolve' : 'Unresolve'}
                                        </MenuItem>
                                    )}
                                </Menu>
                            </div>
                        )}
                    </>
                )}
                {!!headerProps.showCloseAction && onClose && (
                    <Button className="chat-room__header__right-action" onClick={() => onClose()}>
                        <CloseIcon fontSize={'small'} />
                    </Button>
                )}
            </div>
        </div>
    )
}
