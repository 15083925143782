import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter } from 'react-router-dom'

import { App } from './app'
import { Context } from './app/context'
import { mainTheme } from './styles/mainTheme'

import store, { persistor } from './store'
import AppSnackbarProvider from 'app/components/Snackbar/SnackbarProvider'
import { PersistGate } from 'redux-persist/integration/react'

const MOUNT_NODE = document.getElementById('root') as HTMLElement

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <HelmetProvider>
                    <ThemeProvider theme={mainTheme}>
                        <AppSnackbarProvider>
                            <BrowserRouter>
                                <Context>
                                    <App />
                                </Context>
                            </BrowserRouter>
                        </AppSnackbarProvider>
                    </ThemeProvider>
                </HelmetProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    MOUNT_NODE,
)
