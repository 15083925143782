import { useState } from 'react'
import { useSnackbar } from 'notistack'

import { Button, CircularProgress, makeStyles, TextareaAutosize, Typography } from '@material-ui/core'

import './CommentRoom.css'

import { useReplyCommentMutation } from 'store/modules/comment/comment.query'
import { CommentContentType, CommentStatus } from 'store/modules/comment/enum'

import Send from '@material-ui/icons/Send'
import { useGetCommentator } from './hooks/useGetCommentator'

const useStyles = makeStyles(theme => ({
    messageTextArea: {
        width: '100%',
        padding: '8px',
        fontSize: '12px',
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgba(0, 0, 0, 0.26)',
        borderRadius: '3px',
        fontFamily: 'Inter',
    },
}))

window['$'] = window['jQuery'] = $

type ReplyCommentItemProps = {
    parentId: string
    contentType: CommentContentType
    contentTypeId: string
}

export const ReplyForm = (props: ReplyCommentItemProps) => {
    const { enqueueSnackbar } = useSnackbar()
    const { contentType, contentTypeId, parentId } = props

    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const classes = useStyles()

    const { currentUserId, currentUserName, currentCompanyName } = useGetCommentator()

    const [replyComment, { isLoading: isReplyingComment }] = useReplyCommentMutation()

    const shouldDisableAction = isReplyingComment

    const onSave = async e => {
        e.preventDefault()
        e.stopPropagation()

        setErrorMessage('')
        if (shouldDisableAction) {
            return
        }

        try {
            const commentator = {
                content_item_id: currentUserId,
                name: currentUserName,
                company_name: currentCompanyName,
            }

            await replyComment({
                body: {
                    comment_parent_id: parentId,
                    object_id: contentTypeId,
                    content_type: contentType,
                    status: CommentStatus.Publish,
                    text: message,
                    extra_data: {
                        pathname: window.location.pathname,
                    },
                },
                commentator,
            }).unwrap()

            $('.chat-room__reply-wrapper--scroll').animate(
                {
                    scrollTop: document.querySelector('.chat-room__reply-wrapper--scroll')?.scrollHeight || 800,
                },
                'slow',
            )

            setMessage('')
            enqueueSnackbar('Successfully create a new comment!', { variant: 'success' })
        } catch (error) {
            setErrorMessage('Something went wrong')
        }
    }

    const onChangeMessage = e => {
        setMessage(e.target.value)
    }

    const onFocusMessageInput = () => {
        setErrorMessage('')
    }

    const getContent = () => {
        return (
            <div style={{ width: '100%' }}>
                <div style={{ position: 'relative', width: '100%' }}>
                    <TextareaAutosize
                        className={classes.messageTextArea}
                        minRows={1}
                        value={message}
                        placeholder={'Reply'}
                        onChange={onChangeMessage}
                        onFocus={onFocusMessageInput}
                        style={errorMessage ? { borderColor: '#F15656' } : {}}
                        disabled={shouldDisableAction}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            right: '2px',
                            bottom: '7px',

                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {shouldDisableAction && <CircularProgress size={16} />}
                        <Button disabled={shouldDisableAction || !message.trim()} style={{ background: 'transparent' }} onClick={onSave}>
                            <Send color={message.trim() ? 'primary' : 'disabled'} fontSize="small" />
                        </Button>
                    </div>
                </div>
                <Typography color="error" variant="body2">
                    {errorMessage}
                </Typography>
            </div>
        )
    }

    return <div className={`comment-card chat-room--container`}>{getContent()}</div>
}
