import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxActivity } from 'store/modules/activity/activity'
import config from 'config/index'

const reducerPath = 'activityApi'

export const activityApiSlice = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ACTIVITY_URL),
    tagTypes: ['USER_LIST', 'TEAM_LIST'],
    endpoints: builder => ({
        getActivity: builder.query<IReduxActivity.IReduxMainResponse, Object | void>({
            query: () => ({
                url: `/activity/?limit=100`,
                method: 'GET',
            }),
        }),
        markAllAsRead: builder.mutation<any, Object | void>({
            query: () => ({
                url: `/activity/mark-all-as-read/`,
                method: 'GET',
            }),
        }),
        markAsRead: builder.mutation<any, Object | void>({
            query: id => ({
                url: `/activity/${id}/mark-as-read/`,
                method: 'GET',
            }),
        }),
        markAsUnread: builder.mutation<any, Object | void>({
            query: id => ({
                url: `/activity/${id}/mark-as-unread/`,
                method: 'GET',
            }),
        }),
        deleteActivity: builder.mutation<any, Object | void>({
            query: id => ({
                url: `/activity/${id}/`,
                method: 'DELETE',
            }),
        }),
        getEmailSubscriptions: builder.query<IReduxActivity.IReduxEmailSubscriptions, Object | void>({
            query: () => ({
                url: `/userconfig/v1/email-subscriptions/`,
                method: 'GET',
            }),
        }),
        putEmailSubscriptions: builder.mutation({
            query: (body: IReduxActivity.IReduxEmailSubscriptionsPut) => ({
                url: `/userconfig/v1/email-subscriptions/`,
                method: 'PUT',
                body,
            }),
        }),
        getNotificationSubscription: builder.query<IReduxActivity.IReduxNotificationActive, Object | void>({
            query: contentItemId => ({
                url: `notification/my-subscription-setting/?opportunity__content_item_id=${contentItemId}`,
                method: 'GET',
            }),
        }),
        createNotificationSubscription: builder.mutation({
            query: (body: { payload: IReduxActivity.IReduxNotificationCreate }) => ({
                url: `notification/my-subscription-setting/`,
                method: 'POST',
                body: body.payload,
            }),
        }),
        updateNotificationSubscription: builder.mutation({
            query: (body: { subscriptionId: string; payload: IReduxActivity.IReduxNotificationUpdate; contentItemId: string }) => ({
                url: `notification/my-subscription-setting/${body.subscriptionId}/`,
                method: 'PATCH',
                body: body.payload,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
                const currentData = (getState().activityApi?.queries[`getNotificationSubscription("${body.contentItemId}")`]?.data ||
                    {}) as any

                const newData = {
                    ...currentData,
                    results: [{ ...currentData.results[0], enabled: body.payload.enabled }],
                }

                const patchSubscription = dispatch(
                    activityApiSlice.util.updateQueryData('getNotificationSubscription', body.contentItemId, draft => {
                        Object.assign(draft, newData)
                    }),
                )
                try {
                    await queryFulfilled
                } catch {
                    patchSubscription.undo()
                }
            },
        }),
        getContacts: builder.query({
            query: arg => ({
                url: `/authorization/v1/user/`,
                method: 'GET',
                params: arg.params,
            }),
            providesTags: ['USER_LIST'],
        }),
        getTeams: builder.query({
            query: arg => ({
                url: `/authorization/v1/team/`,
                method: 'GET',
                params: arg.params,
            }),
            transformResponse: (response: any, meta, arg) => {
                return response.results.map(item => {
                    const res = item
                    res.displayText = item.display_text
                    res.contentItemId = item.content_item_id
                    return res
                })
            },
            providesTags: ['TEAM_LIST'],
        }),
        getCompanies: builder.query({
            query: arg => ({
                url: `/authorization/v1/company/`,
                method: 'GET',
                params: arg.params,
            }),
            transformResponse: (response: any, meta, arg) => {
                return response.results
            },
        }),
    }),
})

export const {
    useGetContactsQuery,
    useGetTeamsQuery,
    useGetCompaniesQuery,
    useGetActivityQuery,
    useMarkAllAsReadMutation,
    useMarkAsReadMutation,
    useMarkAsUnreadMutation,
    useDeleteActivityMutation,
    useGetEmailSubscriptionsQuery,
    usePutEmailSubscriptionsMutation,
    useGetNotificationSubscriptionQuery,
    useUpdateNotificationSubscriptionMutation,
    useCreateNotificationSubscriptionMutation,
} = activityApiSlice

export const activityQueryReducer = { [reducerPath]: activityApiSlice.reducer }
export const activityQuerySelector = state => state[reducerPath]
