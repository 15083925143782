import React from 'react'

import { Box, Button, CircularProgress, makeStyles, Modal, Typography } from '@material-ui/core'
import { colorPalette } from 'styles/mainTheme'

import { useCreateLevel2RequestMutation } from 'store/modules/level2'
import { userApi } from 'store/modules/user'
import { useSnackbar } from 'notistack'
import { opportunityApi, usePostOpportunityDataRoomRequestAccessMutation } from 'store/modules/opportunity/opportunity.query'
import { useGetLevel2AccessQuery } from 'store/modules/level2'

import { getModalContext } from '../provider'
import { IRequestAccessModalPropsData } from '../definition'
import { getToken } from 'utils/localStorage/token'

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: '0px 2px 18px rgb(13 59 105 / 15%)',
        backgroundColor: colorPalette.white,
        border: 'solid 1px lightgray',
        borderRadius: 8,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 64px)',
            height: 'calc(100% - 64px)',
        },
    },
}))

export const RequestAccessModal = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const { isOpen, category, props, onClose } = React.useContext(getModalContext<IRequestAccessModalPropsData>())
    const access_token = getToken('access_token')

    const { opportunityId = '', level2Status } = props || {}

    const [requestDataRoomAccess, { isLoading: isLoadingDataRoomAccess }] = usePostOpportunityDataRoomRequestAccessMutation()
    const [createLevel2Request, { isLoading: isCreatingLevel2 }] = useCreateLevel2RequestMutation()
    const { refetch: refetchLevel2 } = useGetLevel2AccessQuery({}, { skip: !access_token })
    const { data: userProfile } = userApi.endpoints.getUserProfile.useQueryState({})
    const { data: opportunityDetailSummary } = opportunityApi.endpoints.getOpportunityDetailsSummary.useQueryState(opportunityId)

    const handleOnClose = () => {
        onClose()
    }

    const onRequestAccess = async () => {
        if (level2Status !== 'Active' || (typeof opportunityDetailSummary !== 'undefined' && opportunityDetailSummary?.accessLevel < 2)) {
            try {
                requestDataRoomAccess(opportunityId)

                await createLevel2Request?.({
                    accessorId: userProfile?.teamIds && userProfile?.teamIds[0],
                    resourceId: opportunityId,
                    typeId: 1,
                })
                    .unwrap()
                    .then(() => {
                        enqueueSnackbar('Level 2 Access Requested Successfully', { variant: 'success' })
                        refetchLevel2()
                    })
                    .catch(error => {
                        enqueueSnackbar(`An error occurred: ${error?.data?.error}`, { variant: 'error' })
                    })
                handleOnClose()
            } catch (error) {
                enqueueSnackbar('Request error.', { variant: 'error' })
                console.error('rejected', error)
            }
        } else {
            enqueueSnackbar('Request error, deal already active', { variant: 'error' })
        }
    }

    if (isOpen && category === 'request-access') {
        return (
            <Modal
                open={isOpen}
                onClose={handleOnClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="request-access-modal"
            >
                <Box className={classes.modal}>
                    <div style={{ marginBottom: 12 }}>
                        <Typography color="primary" id="modal-modal-title" variant="h6" component="h2">
                            Request Access
                        </Typography>
                    </div>
                    <div style={{ marginBottom: 4 }}>
                        <Typography id="modal-modal-subtitle" style={{ fontWeight: 600, color: colorPalette.primary }}>
                            Important Information
                        </Typography>
                    </div>
                    <div style={{ marginBottom: 24 }}>
                        <Typography id="modal-modal-description">
                            This deal has confidential data. We will contact the borrower on your behalf for approval. If access is
                            approved, we will notify you.
                            <br />
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 16, gap: 8 }}>
                        <Button onClick={onRequestAccess} type="submit" variant="contained" disabled={isCreatingLevel2}>
                            {isLoadingDataRoomAccess || (isCreatingLevel2 && <CircularProgress size={20} style={{ marginRight: 8 }} />)}
                            Request
                        </Button>
                        <Button onClick={onClose} variant="text">
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        )
    }

    return null
}
