import {
    Checkbox,
    // FormGroup,
    FormControlLabel,
    Typography,
    FormHelperText,
    TextField,
    Button,
    Tooltip,
    Chip,
    Box,
    Switch,
    // Select,
    CircularProgress,
    Avatar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import ChipInput from 'material-ui-chip-input'
import CloseIcon from '@material-ui/icons/Close'
import { Edit } from '@material-ui/icons'
import UploadAvatar from 'app/components/UploadFiles/UploadAvatar/UploadAvatar'
import { useUpdateCompanyDetailsMutation, useUploadCompanyLogoMutation } from 'store/modules/management/management.query'
import { useEffect, useState, useContext } from 'react'
import { useGetTaxonomiesQuery } from 'store/modules/taxonomy/taxonomy.query'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { colorPalette } from 'styles/mainTheme'
import ProgressIndicator from 'app/components/Progress/ProgressIndicator'
import { useSnackbar } from 'notistack'
import { getDrawerContext } from 'app/context/drawer/provider'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(3),
    },
    chipInput: {
        border: 'none',
        padding: 14,
        minHeight: 60,
        borderRadius: 4,
        height: 'auto',
        paddingBottom: 8,
        '&  .MuiChip-deleteIcon': {
            color: 'rgba(0, 0, 0, 0.12) !important',
        },
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    autocomplete: {
        width: 'auto',
        '& .MuiChip-label': {
            color: colorPalette.black,
        },
        '& .MuiChip-root': {
            border: 'none',
        },
        '& .MuiFilledInput-root': {
            marginTop: '-10px',
            backgroundColor: colorPalette.white,
        },
        '& .MuiChip-deleteIcon': {
            color: 'rgba(0, 0, 0, 0.12) !important',
        },
        '& .MuiAutocomplete-inputRoot': {
            height: 'auto',
            padding: '12px 14px',
        },
    },
}))

const CompanyDrawer = props => {
    const classes = useStyles()

    const { companyDetails, isLoadingCompanyDetails } = props

    const [companyData, setCompanyData] = useState<any>({
        contentTags: [],
        associatedEmailDomains: [],
        websiteUrl: '',
        summary: '',
        areUsersBlocked: false,
        isG20Referrer: false,
        complianceEmailAddress: '',
        allowComplianceEmailEditFromModal: false,
        logoUrl: '',
    })

    const { enqueueSnackbar } = useSnackbar()

    const { data: contentTagsTaxonomy, isLoading: isLoadingContentTags } = useGetTaxonomiesQuery('content-tags/')

    const [updateCompanyDetails, { isLoading: isUpdatingCompanyDetails }] = useUpdateCompanyDetailsMutation()

    useEffect(() => {
        if (!isLoadingCompanyDetails && !isLoadingContentTags) {
            setCompanyData({
                associatedEmailDomains: companyDetails?.CommercialEntityPart?.AssociatedEmailDomains,
                contentTags:
                    companyDetails?.CommercialEntityPart?.ContentTags?.TermContentItemIds?.length > 0
                        ? companyDetails?.CommercialEntityPart?.ContentTags?.TermContentItemIds?.map(
                              // @ts-ignore:next-line
                              item => contentTagsTaxonomy?.filter(tag => tag?.id === item)?.[0],
                          )
                        : [],
                websiteUrl: companyDetails?.CommercialEntityPart?.WebsiteUrl,
                summary: companyDetails?.CommercialEntityPart?.Summary,
                areUsersBlocked: companyDetails?.CommercialEntityPart?.AreUsersBlocked,
                isG20Referrer: companyDetails?.CommercialEntityPart?.IsG20Referrer?.Value,
                complianceEmailAddress: companyDetails?.CommercialEntityPart?.ComplianceEmailAddress,
                allowComplianceEmailEditFromModal: companyDetails?.CommercialEntityPart?.AllowComplianceEmailEditFromModal,
                logoUrl: companyDetails?.CommercialEntityPart?.CompanyLogo?.Paths,
            })
        }
    }, [companyDetails, contentTagsTaxonomy, isLoadingContentTags, isLoadingCompanyDetails])

    type Anchor = 'right'

    const drawer = useContext(getDrawerContext())

    const handleClose = () => {
        drawer.onClose()
    }

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return
        }
        props.onChangeOpenDrawer(open)
    }

    const handleChangeSwitch = event => {
        setCompanyData({ ...companyData, [event.target.name]: event.target.checked })
    }

    const [isOpenUploadImage, setIsOpenUploadimage] = useState<boolean>(false)

    const handleIsOpenUploadImage = (isOpen: boolean) => () => {
        setIsOpenUploadimage(isOpen)
    }

    const [uploadLogo] = useUploadCompanyLogoMutation()
    // , { isLoading: isUplodingLogo }

    const onSave = (file: File) => {
        const formData = new FormData()
        formData.append('file', file)

        uploadLogo({ companyId: props.companyId, data: formData })
            .then((res: any) => {
                if (res.data.urls) {
                    enqueueSnackbar('Logo uploaded', { variant: 'success' })
                    setIsOpenUploadimage(false)
                } else {
                    enqueueSnackbar('Something wrong', { variant: 'error' })
                }
            })
            .catch(err => {
                enqueueSnackbar('Something wrong', { variant: 'error' })
            })
    }

    const handleContentTagsChange = (event, value) => {
        setCompanyData({ ...companyData, contentTags: value })
    }

    const handleEdit = () => {
        updateCompanyDetails({
            payload: {
                ...companyData,
                contentTags: {
                    taxonomyContentItemId: companyDetails?.CommercialEntityPart?.ContentTags?.TaxonomyContentItemId,
                    TermContentItemIds: [...companyData.contentTags.map(item => item.id)],
                },
            },
            companyId: props.companyId,
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar('Company details updated successfully!', { variant: 'success' })
            })
            .catch((err: any) => {
                enqueueSnackbar(`An error occurred`, { variant: 'error' })
            })
    }

    if (isLoadingCompanyDetails || isLoadingContentTags) {
        return <ProgressIndicator />
    }

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent={'space-between'} gridColumnGap={24} data-testid="companydrawer">
                <div>
                    <Typography variant="subtitle2">Company</Typography>
                    <Typography variant="h3" color="primary">
                        {companyDetails?.DisplayText}
                    </Typography>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '8px', marginTop: '6px' }}>
                        <Switch
                            checked={companyData.areUsersBlocked}
                            size="small"
                            color="primary"
                            className="switch-small"
                            inputProps={{ 'aria-label': 'Switch demo' }}
                            onChange={handleChangeSwitch}
                            name="areUsersBlocked"
                        />

                        <FormHelperText>Block Company</FormHelperText>

                        <Tooltip title="Block Company Tooltip">
                            <InfoIcon style={{ color: '#4299E1' }} />
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '8px', marginTop: '6px' }}>
                        <Switch
                            checked={companyData.isG20Referrer}
                            size="small"
                            color="primary"
                            className="switch-small"
                            inputProps={{ 'aria-label': 'Switch demo' }}
                            onChange={handleChangeSwitch}
                            name="isG20Referrer"
                        />

                        <FormHelperText>G20 Referral Partner</FormHelperText>

                        <Tooltip title="Tooltip explaining G20">
                            <InfoIcon style={{ color: '#4299E1' }} />
                        </Tooltip>
                    </div>
                </div>

                <CloseIcon onClick={toggleDrawer('right', false)} />
            </Box>
            <div style={{ marginTop: '1rem' }}>
                <ChipInput
                    InputProps={{
                        className: classes.chipInput,
                    }}
                    label="Company"
                    fullWidth
                    variant="outlined"
                    defaultValue={[companyDetails?.DisplayText]}
                    disabled={true}
                    chipRenderer={({ chip, className, handleClick }, key) => (
                        <Chip
                            className={className}
                            key={key}
                            label={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{chip}</span>}
                            onClick={handleClick}
                            style={{
                                background: colorPalette.chipLight,
                                borderRadius: 4,
                            }}
                        />
                    )}
                />
            </div>

            <div style={{ marginTop: '1rem' }}>
                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    id="tags-outlined"
                    onChange={handleContentTagsChange}
                    // @ts-ignore:next-line
                    options={!_.isEmpty(contentTagsTaxonomy) ? contentTagsTaxonomy : []}
                    getOptionLabel={option => option.value}
                    value={companyData.contentTags}
                    filterSelectedOptions
                    renderInput={({ inputProps, ...rest }) => (
                        <TextField {...rest} label="Content Tag" variant="outlined" inputProps={{ ...inputProps, readOnly: true }} />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            return (
                                <Chip
                                    variant="outlined"
                                    style={{
                                        backgroundColor: colorPalette.chipLight,
                                    }}
                                    label={typeof option === 'string' ? option : option.value}
                                    {...getTagProps({ index })}
                                />
                            )
                        })
                    }
                />
            </div>

            <TextField
                style={{ marginTop: '1rem' }}
                label="Website URL"
                variant="outlined"
                value={companyData.websiteUrl}
                onChange={e => setCompanyData({ ...companyData, websiteUrl: e.target.value })}
            />
            <TextField
                style={{ marginTop: '1rem' }}
                label="Summary"
                variant="outlined"
                value={companyData.summary}
                onChange={e => setCompanyData({ ...companyData, summary: e.target.value })}
            />

            <ChipInput
                style={{ marginTop: '1rem' }}
                InputProps={{
                    className: classes.chipInput,
                }}
                label="Associated Email Domains"
                chipRenderer={({ value, className, handleDelete }, key) => (
                    <Chip
                        key={key}
                        onDelete={handleDelete}
                        className={className}
                        label={value}
                        style={{
                            background: colorPalette.chipLight,
                            borderRadius: 4,
                        }}
                    />
                )}
                fullWidth
                variant="outlined"
                defaultValue={companyData.associatedEmailDomains}
                onChange={chips => setCompanyData({ ...companyData, associatedEmailDomains: chips })}
            />
            <FormHelperText>
                These values are automatically updated by the user's email address once the user is associated with this entity. Optionally,
                it can be updated by providing the domains one per line.
            </FormHelperText>
            <TextField
                style={{ marginTop: '1rem' }}
                label="Compliance Email Address"
                variant="outlined"
                value={companyData.complianceEmailAddress}
                onChange={e => setCompanyData({ ...companyData, complianceEmailAddress: e.target.value })}
            />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={companyData.allowComplianceEmailEditFromModal}
                            name="allowComplianceEmailEditFromModal"
                            onChange={handleChangeSwitch}
                        />
                    }
                    label="Compliance Email Editing"
                />
            </div>

            <Typography style={{ color: colorPalette.black, fontWeight: '600', fontSize: '18px' }}>Upload Company Logo</Typography>
            <Box display="flex" alignItems="center" style={{ marginTop: '1rem', width: '80%' }}>
                <Avatar
                    style={{ width: '100%', height: '100%', borderRadius: '0%', display: 'block' }}
                    src={`https://dev.finitive.com/media/${companyData.logoUrl}`}
                ></Avatar>
                <Avatar style={{ cursor: 'pointer' }}>
                    <Edit onClick={handleIsOpenUploadImage(true)} fontSize="small" />
                </Avatar>
            </Box>

            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '2rem' }}>
                <Button
                    style={{ marginRight: '1rem' }}
                    variant="contained"
                    size="large"
                    onClick={handleEdit}
                    disabled={isLoadingCompanyDetails || isUpdatingCompanyDetails}
                >
                    {(isLoadingCompanyDetails || isUpdatingCompanyDetails) && (
                        <CircularProgress size={16} style={{ marginRight: 6, color: colorPalette.primary }} />
                    )}
                    Save
                </Button>
                <Button variant="outlined" size="large" onClick={handleClose}>
                    Cancel
                </Button>
            </div>

            <UploadAvatar
                isLoading={false}
                isOpen={isOpenUploadImage}
                title="Upload Logo"
                onClose={handleIsOpenUploadImage(false)}
                placeholder="Drag and drop"
                onSave={onSave}
            />
        </div>
    )
}

export default CompanyDrawer
