import { createApi } from '@reduxjs/toolkit/query/react'
import config from 'config/index'

import http from 'utils/http'
import { IReduxMaster } from './master'

const reducerPath = 'masterApi'

export const masterApiSlice = createApi({
    reducerPath,
    baseQuery: http(),
    endpoints: builder => ({
        getMarketingSources: builder.query<IReduxMaster.IOptionResult[], void>({
            query: () => ({
                url: '/v1/marketingsources',
                method: 'GET',
            }),
        }),
        getCountries: builder.query<IReduxMaster.ICodeOptionResult[], void>({
            query: () => ({
                url: '/v1/countries',
                method: 'GET',
            }),
        }),
        getInstitutionalOptions: builder.query<IReduxMaster.IOptionResult[], void>({
            query: () => ({
                url: '/v1/institutionaloptions',
                method: 'GET',
            }),
        }),
        getNonInstitutionalOptions: builder.query<IReduxMaster.IOptionResult[], void>({
            query: () => ({
                url: '/v1/noninstitutionaloptions',
                method: 'GET',
            }),
        }),
        getWhiteLabelInfo: builder.query<IReduxMaster.IWhiteLabelInfoResult, { website: string }>({
            query: ({ website }) => ({
                url: `/v1/whitelabelinfo/${website}`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxMaster.IWhiteLabelInfoResult) => {
                response['webConfiguration'] = {
                    hasSignup: response?.whiteLabelInfo?.signup?.value || false,
                    hasBorrowerNDA: response?.whiteLabelInfo?.borrowerNda?.value || false,
                    logoPath: response?.whiteLabelInfo?.logo?.paths?.[0] ?? '',
                    logoHeaderPath: response?.whiteLabelInfo?.headerLogo?.paths?.[0] ?? '',
                    appName: response?.whiteLabelInfo?.website?.text ?? '',
                    appURL: response?.whiteLabelInfo?.website?.url ?? '',
                    title: response?.whiteLabelInfo?.name?.text ?? '',
                    // We will use the regular login if clientId doenst exist in the response
                    clientId: response.whiteLabelInfo?.clientId?.text || config.CLIENT_ID || '',
                    useSSO: response.whiteLabelInfo?.useSSO?.value || false,
                    disablePoweredByFinitiveLogo: response.whiteLabelInfo?.disablePoweredByFinitiveLogo?.value || false,
                    disableMarketplace: response.whiteLabelInfo?.disableMarketplace?.value,
                    poweredByLabel: response.whiteLabelInfo?.poweredByLabel?.value || false,
                    hasInvestorNDA: response?.whiteLabelInfo?.investorNda.value || false,
                }

                return response
            },
        }),
        getBuildInfo: builder.query({
            query: version => ({
                url: version ? `${window.location.origin}/build_info.json?version=${version}` : `${window.location.origin}/build_info.json`,
                responseHandler: 'text',
            }),
        }),
    }),
})

export const {
    useGetMarketingSourcesQuery,
    useGetCountriesQuery,
    useGetInstitutionalOptionsQuery,
    useGetNonInstitutionalOptionsQuery,
    useGetWhiteLabelInfoQuery,
    useGetBuildInfoQuery,
} = masterApiSlice
