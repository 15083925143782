import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxAlgoOpportunity } from 'store/modules/algo-opportunity/algo-opportunity'
import config from 'config/index'

const reducerPath = 'algoOpportunityAPI'

export const algoOpportunityAPI = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ALGO_API_URL),
    endpoints: builder => ({
        getRecommendationOpportunity: builder.query<
            IReduxAlgoOpportunity.IModifiedAlgoOpportunityResponse,
            IReduxAlgoOpportunity.IAlgoOpportunityParam
        >({
            query: body => ({
                url: `/matching/my-matches/`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: IReduxAlgoOpportunity.IAlgoOpportunityResponse) => {
                const transaction = {}
                const threshold = parseInt(config.SCORE_THRESHOLD || '5')
                const filteredTransactions = response.transactions
                    .filter(transaction => transaction.score > threshold)
                    .sort((a, b) => b.score - a.score)

                filteredTransactions.forEach(filteredTransaction => {
                    transaction[filteredTransaction.transaction_id] = true
                })

                return {
                    transaction,
                    transactions: filteredTransactions,
                }
            },
        }),
    }),
})

export const { useGetRecommendationOpportunityQuery } = algoOpportunityAPI

export const algoOpportunityQueryReducer = { [reducerPath]: algoOpportunityAPI.reducer }
export const algoOpportunityQuerySelector = state => state[reducerPath]
