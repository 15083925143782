import { createApi } from '@reduxjs/toolkit/query/react'
import config from 'config/index'

import http from 'utils/http'

const reducerPath = 'contentApi'

export const contentApiSlice = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.API_URL),
    endpoints: builder => ({
        getTnC: builder.query({
            query: () => ({
                url: '/v1/document/terms%20and%20conditions',
                method: 'GET',
            }),
        }),
        getPrivacyPolicy: builder.query({
            query: () => ({
                url: '/v1/document/privacy%20policy',
                method: 'GET',
            }),
        }),
        getFooterDisclaimer: builder.query({
            query: () => ({
                url: '/v1/document/footer%20disclaimer',
                method: 'GET',
            }),
        }),
        GetFooterMarketplaceDisclaimer: builder.query({
            query: () => ({
                url: '/v1/document/marketplace%20disclaimer',
                method: 'GET',
            }),
        }),
        GetFooterTransactionDisclaimer: builder.query({
            query: () => ({
                url: '/v1/document/transaction%20disclaimer',
                method: 'GET',
            }),
        }),
    }),
})

export const {
    useGetTnCQuery,
    useGetPrivacyPolicyQuery,
    useGetFooterDisclaimerQuery,
    useGetFooterMarketplaceDisclaimerQuery,
    useGetFooterTransactionDisclaimerQuery,
} = contentApiSlice
