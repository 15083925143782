import { RenderTree } from './IPTreeCheckbox'

export const geographyChoices: RenderTree = {
    id: '0',
    name: 'All Country',
    children: [
        {
            id: '1',
            name: 'United States',
        },
        {
            id: '8',
            name: 'Canada',
        },
        {
            id: '2',
            name: 'Western Europe',
        },
        {
            id: '9',
            name: 'Central / Eastern Europe',
        },
        {
            id: '3',
            name: 'Asia',
        },
        {
            id: '4',
            name: 'Latin America',
        },
        {
            id: '5',
            name: 'Australia / New Zealand',
        },
        {
            id: '6',
            name: 'Africa',
        },
        {
            id: '7',
            name: 'Middle East',
        },
    ],
}

export const maxTermChoices: RenderTree = {
    id: '0',
    name: 'Select All',
    children: [
        {
            id: '1',
            name: 'Less than 1 year',
        },
        {
            id: '2',
            name: '1-3 years',
        },
        {
            id: '3',
            name: '3-5 years',
        },
        {
            id: '4',
            name: '5-10 years',
        },
        {
            id: '5',
            name: '10 years and over',
        },
    ],
}

export const capitalStructuresChoices: RenderTree = {
    id: '0',
    name: 'All',
    children: [
        {
            id: '1',
            name: 'Senior',
        },
        {
            id: '2',
            name: 'Mezzanine',
        },
        {
            id: '3',
            name: 'Equity',
        },
    ],
}

export const investmentStructuresChoices: RenderTree = {
    id: '0',
    name: 'All Structures',
    children: [
        {
            id: '1',
            name: 'Whole Loans',
        },
        {
            id: '2',
            name: 'Warehouse Lines / Credit Facilities',
        },
        {
            id: '3',
            name: 'Securitized Products (e.g. CLOs, ABS)',
        },
        {
            id: '4',
            name: 'Co-Investments / Participations',
        },
        {
            id: '5',
            name: 'Funds',
        },
        {
            id: '6',
            name: 'Equity',
        },
        {
            id: '7',
            name: 'Corporate Debt',
        },
        {
            id: '8',
            name: 'Other',
        },
    ],
}

export const investmentChoices: RenderTree = {
    id: '0',
    name: 'All Investment Types',
    children: [
        {
            id: '1',
            name: 'Asset-Backed Credit',
            children: [
                {
                    id: '1-1',
                    name: 'Consumer',
                    children: [
                        {
                            id: '1-1-1',
                            name: 'Consumer Auto',
                        },
                        {
                            id: '1-1-2',
                            name: 'Unsecured Installment',
                        },
                        {
                            id: '1-1-3',
                            name: 'Healthcare',
                        },
                        {
                            id: '1-1-4',
                            name: 'Payday',
                        },
                        {
                            id: '1-1-5',
                            name: 'Point of Sale',
                        },
                        {
                            id: '1-1-6',
                            name: 'Student',
                        },
                        {
                            id: '1-1-7',
                            name: 'Timeshare',
                        },
                        {
                            id: '1-1-8',
                            name: 'Credit Card',
                        },
                        {
                            id: '1-1-9',
                            name: 'Prime',
                        },
                        {
                            id: '1-1-10',
                            name: 'Subprime',
                        },
                        {
                            id: '1-1-11',
                            name: 'Other Consumer',
                        },
                    ],
                },
                {
                    id: '1-2',
                    name: 'SME / Business',
                    children: [
                        {
                            id: '1-2-1',
                            name: 'Small Business Loans',
                        },
                        {
                            id: '1-2-2',
                            name: 'Factoring',
                        },
                        {
                            id: '1-2-3',
                            name: 'Franchisee Lending',
                        },
                        {
                            id: '1-2-4',
                            name: 'Merchant Cash Advance',
                        },
                        {
                            id: '1-2-5',
                            name: 'Trade Finance',
                        },
                        {
                            id: '1-2-6',
                            name: 'Whole Business',
                        },
                        {
                            id: '1-2-7',
                            name: 'Other SME / Business',
                        },
                    ],
                },
                {
                    id: '1-3',
                    name: 'Real Estate - Commercial',
                    children: [
                        {
                            id: '1-3-1',
                            name: 'Commercial - Bridge',
                        },
                        {
                            id: '1-3-2',
                            name: 'Commercial - Permanent',
                        },
                        {
                            id: '1-3-3',
                            name: 'Construction',
                        },
                        {
                            id: '1-3-4',
                            name: 'Development',
                        },
                        {
                            id: '1-3-5',
                            name: 'Commercial - NPL',
                        },
                        {
                            id: '1-3-6',
                            name: 'Other Real Estate - Commercial',
                        },
                    ],
                },
                {
                    id: '1-4',
                    name: 'Real Estate - Residential',
                    children: [
                        {
                            id: '1-4-1',
                            name: 'Residential Mortgage - Conventional',
                        },
                        {
                            id: '1-4-2',
                            name: 'Residential Mortgage - Non-QM',
                        },
                        {
                            id: '1-4-3',
                            name: 'Residential - NPL',
                        },
                        {
                            id: '1-4-4',
                            name: 'Residential - Fix and Flip',
                        },
                        {
                            id: '1-4-5',
                            name: 'Single Family Rental',
                        },
                        {
                            id: '1-4-6',
                            name: 'Other Real Estate - Residential',
                        },
                    ],
                },
                {
                    id: '1-5',
                    name: 'Renewables',
                    children: [
                        {
                            id: '1-5-1',
                            name: 'Solar C&I',
                        },
                        {
                            id: '1-5-2',
                            name: 'Solar Residential',
                        },
                        {
                            id: '1-5-3',
                            name: 'Wind',
                        },
                        {
                            id: '1-5-4',
                            name: 'Other Renewables',
                        },
                    ],
                },
                {
                    id: '1-6',
                    name: 'Other Asset-Backed Specialty Sectors',
                    children: [
                        {
                            id: '1-6-1',
                            name: 'Aircraft',
                        },
                        {
                            id: '1-6-2',
                            name: 'Art',
                        },
                        {
                            id: '1-6-3',
                            name: 'Commercial Auto',
                        },
                        {
                            id: '1-6-4',
                            name: 'Digital Currency',
                        },
                        {
                            id: '1-6-5',
                            name: 'Equipment',
                        },
                        {
                            id: '1-6-6',
                            name: 'Fund Leverage',
                        },
                        {
                            id: '1-6-7',
                            name: 'IP-Based Lending',
                        },
                        {
                            id: '1-6-8',
                            name: 'Litigation Finance',
                        },
                        {
                            id: '1-6-9',
                            name: 'Manufacturing',
                        },
                        {
                            id: '1-6-10',
                            name: 'Railcar',
                        },
                        {
                            id: '1-6-11',
                            name: 'Royalty',
                        },
                        {
                            id: '1-6-12',
                            name: 'Shipping Container',
                        },
                        {
                            id: '1-6-13',
                            name: 'Structured Settlements',
                        },
                        {
                            id: '1-6-14',
                            name: 'Tax Liens',
                        },
                        {
                            id: '1-6-15',
                            name: 'Other Asset-Backed Credit',
                        },
                    ],
                },
            ],
        },
        {
            id: '2',
            name: 'Corporate Credit',
            children: [
                {
                    id: '2-1',
                    name: 'Broadly Syndicated Loans',
                },
                {
                    id: '2-2',
                    name: 'Middle Market Loans',
                },
                {
                    id: '2-3',
                    name: 'Asset-Based Loans',
                },
                {
                    id: '2-4',
                    name: 'Mezzanine',
                },
                {
                    id: '2-5',
                    name: 'Distressed / Special Situations',
                },
                {
                    id: '2-6',
                    name: 'Other Corporate Credit',
                },
            ],
        },
        {
            id: '3',
            name: 'Private Equity',
            children: [
                {
                    id: '3-1',
                    name: 'Buyout',
                },
                {
                    id: '3-2',
                    name: 'Growth',
                },
                {
                    id: '3-3',
                    name: 'Secondaries',
                },
                {
                    id: '3-4',
                    name: 'Natural Resources / Real Assets',
                },
                {
                    id: '3-5',
                    name: 'Pre-IPOs / PIPEs / SPACs',
                },
                {
                    id: '3-6',
                    name: 'Real Estate',
                },
                {
                    id: '3-7',
                    name: 'Restructuring / Turnaround / Special Situations',
                },
                {
                    id: '3-8',
                    name: 'Other Private Equity',
                },
            ],
        },
        {
            id: '4',
            name: 'Venture Capital',
            children: [
                {
                    id: '4-1',
                    name: 'Early Stage: Seed',
                },
                {
                    id: '4-2',
                    name: 'Early Stage: Start-up',
                },
                {
                    id: '4-3',
                    name: 'Expansion / Late Stage',
                },
                {
                    id: '4-4',
                    name: 'Venture Debt',
                },
                {
                    id: '4-5',
                    name: 'Other Venture Capital',
                },
            ],
        },
        {
            id: '5',
            name: 'Infrastructure / Project Finance',
        },
    ],
}

export const additionalDataRequired = [
    'Other Venture Capital (Describe)',
    'Venture Debt',
    'Expansion / Late Stage',
    'Early Stage: Start-up',
    'Early Stage: Seed',
    'Other Private Equity (Describe)',
    'Restructuring / Turnaround / Special Situations',
    'Real Estate',
    'Pre-IPOs / PIPEs / SPACs',
    'Natural Resources / Real Assets',
    'Secondaries',
    'Growth',
    'Buyout',
    'Other Corporate Credit (Describe)',
    'Distressed / Special Situations',
    'Mezzanine',
    'Asset-Based Loans',
    'Middle Market Loans',
    'Broadly Syndicated Loans',
]

export const additionalDataRequiredNAICS = ['Equity', 'Corporate Debt']

export const currencyChoices = [
    {
        value: '$',
        text: 'USD',
    },
    {
        value: 'د.إ',
        text: 'AED',
    },
    {
        value: 'A$',
        text: 'AUD',
    },
    {
        value: 'R$',
        text: 'BRL',
    },
    {
        value: 'C$',
        text: 'CAD',
    },
    {
        value: 'CHF',
        text: 'CHF',
    },
    {
        value: 'CLP$',
        text: 'CLP',
    },
    {
        value: '元',
        text: 'CNY',
    },
    {
        value: 'Col$',
        text: 'COP',
    },
    {
        value: 'Kč',
        text: 'CZK',
    },
    {
        value: 'DKK',
        text: 'DKK',
    },
    {
        value: '€',
        text: 'EUR',
    },
    {
        value: '£',
        text: 'GBP',
    },
    {
        value: 'HK$',
        text: 'HKD',
    },
    {
        value: 'Ft',
        text: 'HUF',
    },
    {
        value: 'Rp',
        text: 'IDR',
    },
    {
        value: '₪',
        text: 'ILS',
    },
    {
        value: '₹',
        text: 'INR',
    },
    {
        value: '¥',
        text: 'JPY',
    },
    {
        value: '₩',
        text: 'KRW',
    },
    {
        value: 'Mex$',
        text: 'MXN',
    },
    {
        value: 'RM',
        text: 'MYR',
    },
    {
        value: 'NOK',
        text: 'NOK',
    },
    {
        value: 'NZ$',
        text: 'NZD',
    },
    {
        value: '₱',
        text: 'PHP',
    },
    {
        value: 'zł',
        text: 'PLN',
    },
    {
        value: 'L',
        text: 'RON',
    },
    {
        value: '₽',
        text: 'RUB',
    },
    {
        value: '﷼',
        text: 'SAR',
    },
    {
        value: 'SEK',
        text: 'SEK',
    },
    {
        value: 'S$',
        text: 'SGD',
    },
    {
        value: '฿',
        text: 'THB',
    },
    {
        value: '₺',
        text: 'TRY',
    },
    {
        value: 'NT$',
        text: 'TWD',
    },
    {
        value: 'ZAR',
        text: 'ZAR',
    },
]
