import React, { useEffect, useState } from 'react'

export const getHostname = (): string => {
    if (window) {
        return window.location?.hostname ?? ''
    }

    return ''
}

export const onlyNumber = number => {
    if (number !== null && typeof number !== 'undefined') {
        const pattern = /\d+/g
        const result = number.match(pattern)
        return result ? parseInt(result?.[0] ?? 0) : 0
    }

    return 0
}

export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getErrorMessage = (errorCode: number): string => {
    switch (errorCode) {
        case 109:
            return 'Token has expired'
    }
    return 'Unknown Error'
}

export const withHttp = url => (!/^https?:\/\//i.test(url) ? `http://${url}` : url)

/**
 * Convert array to object based on selected property
 */
export const convertArrayToObject = <T>(arr: T[], key: keyof T): { [key: string]: T } => {
    return arr.reduce((obj, item) => Object.assign(obj, { [item[key.toString()]]: item }), {})
}

export const useDebounce = (value, milliSeconds = 300) => {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, milliSeconds)

        return () => {
            clearTimeout(handler)
        }
    }, [value, milliSeconds])

    return debouncedValue
}

/**
 * A custom hook to handle outside click
 */
export const useOutsideClick = callback => {
    const ref = React.useRef<any>()

    React.useEffect(() => {
        const handleClick = event => {
            if (
                ref.current &&
                !ref.current.contains(event.target) &&
                event.target.style.zIndex !== '-1' &&
                !event.target.classList.contains('MuiListItem-button')
            ) {
                callback()
            }
        }

        document.addEventListener('click', handleClick, true)

        return () => {
            document.removeEventListener('click', handleClick, true)
        }
    }, [callback, ref])

    return ref
}

export const strToSentences = (str: string): string => {
    if (str.includes('-')) {
        return str.replaceAll('-', ' ')
    }

    const stringMaxIndex = str.length - 1
    let newStr = ''

    for (let i = stringMaxIndex; i >= 0; i--) {
        const char = str.charAt(i)

        const isPreviousUppercase = str.charAt(i - 1) === str.charAt(i - 1).toUpperCase()
        const isPreviousLowercase = str.charAt(i - 1) === str.charAt(i - 1).toLowerCase()

        const isCurrentLowercase = char === char.toLowerCase()
        const isCurrentUppercase = char === char.toUpperCase()

        const charMovementLowercaseToUppercase = isCurrentLowercase && isPreviousUppercase
        const charMovementUppercaseToLowercase = isCurrentUppercase && isPreviousLowercase

        if (i !== 0 && (charMovementLowercaseToUppercase || charMovementUppercaseToLowercase)) {
            if (charMovementLowercaseToUppercase) {
                newStr = `${char}${newStr} `
            } else {
                newStr = ` ${char}${newStr}`
            }
        } else {
            newStr = `${char}${newStr}`
        }
    }

    return newStr
}
