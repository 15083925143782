import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxInvestor } from 'store/modules/investor/investor'

const reducerPath = 'investorApi'
export const investorApiSlice = createApi({
    reducerPath,
    baseQuery: http(),
    endpoints: builder => ({
        getInvestorStatusNDA: builder.query<IReduxInvestor.IReduxNDAResponse, Object | void>({
            query: () => ({
                url: `/investor/ndastatus`,
                method: 'GET',
            }),
        }),
        getInvestorSignNDA: builder.mutation<IReduxInvestor.IReduxSignNDAResponse, Object | void>({
            query: () => ({
                url: `/v1/onboarding/investor/signnda`,
                method: 'GET',
            }),
        }),
        requestDBD: builder.mutation<any, IReduxInvestor.IReduxRequestDBD>({
            query: body => ({
                url: '/v1/dealbydeal/request/',
                method: 'POST',
                body,
            }),
        }),
        getContacts: builder.query({
            query: () => ({
                url: '/v1/commercialentity/mycontacts',
                method: 'GET',
            }),
        }),
    }),
})

export const { useGetInvestorStatusNDAQuery, useRequestDBDMutation, useGetInvestorSignNDAMutation, useGetContactsQuery } = investorApiSlice
