import { Helmet } from 'react-helmet-async'

import Layout from 'app/components/Layout'
import { lazyLoad } from 'utils/loadable'

const Page = lazyLoad(
    () => import('./index'),
    module => module.default,
)

export const EmailConfirmationPage = props => {
    return (
        <>
            <Helmet>
                <title>Email Confirmation</title>
                <meta name="description" content="Email Confirmation" />
            </Helmet>
            <Layout footer fullPage isSignup={false}>
                <Page {...props} />
            </Layout>
        </>
    )
}
