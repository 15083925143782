import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxAI } from 'store/modules/ai/ai'
import config from 'config/index'
// import { transformFormJsonSchemaResponse } from 'utils/matching/transformer'
const reducerPath = 'aiApi'

export const aiApiSlice = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.AI_URL),
    endpoints: builder => ({
        getAIAnswerByDDQ: builder.query({
            query: body => ({
                url: ``,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: IReduxAI.IQResponse): IReduxAI.IQResponse => {
                return response
            },
        }),
    }),
})

export const { useGetAIAnswerByDDQQuery } = aiApiSlice
export const aiQueryReducer = { [reducerPath]: aiApiSlice.reducer }
export const aiQuerySelector = state => state[reducerPath]
