import { forwardRef } from 'react'
import { Checkbox, ListItemText, MenuItem } from '@material-ui/core'
import { IReduxTaxonomy } from 'store/modules/taxonomy/taxonomy'

import NumberFormat from 'react-number-format'

export const buildNestedTaxonomyMenuItems = (taxonomyListOptions: IReduxTaxonomy.IReduxTaxonomyResponse[]) => {
    let taxonomyOptions: JSX.Element[] = []
    // let taxonomyListOptions = taxonomies[taxonomyList.findIndex(taxonomy => taxonomy === name)]?.value?.data ?? []

    taxonomyListOptions.forEach(obj => {
        taxonomyOptions.push(
            <MenuItem style={{ padding: '0px', paddingLeft: '16px', backgroundColor: 'white' }} key={obj.id} value={obj.id}>
                <ListItemText key={obj.id} primary={obj.value} />
            </MenuItem>,
        )

        if (obj?.children && obj?.children?.length > 0)
            obj.children.forEach(child => {
                taxonomyOptions.push(
                    <MenuItem
                        style={{ padding: '0px', paddingLeft: '15px', backgroundColor: 'white', display: 'relative', marginLeft: '45px' }}
                        key={child.id}
                        value={child.id}
                    >
                        <ListItemText key={child.id} primary={child.value} />
                    </MenuItem>,
                )

                // TODO: Create recursive asset class tree
                if (child?.children && child?.children.length > 0) {
                    child.children.forEach(child => {
                        taxonomyOptions.push(
                            <MenuItem
                                style={{
                                    padding: '0px',
                                    paddingLeft: '15px',
                                    backgroundColor: 'white',
                                    display: 'relative',
                                    marginLeft: '90px',
                                }}
                                key={child.id}
                                value={child.id}
                            >
                                <ListItemText key={child.id} primary={child.value} />
                            </MenuItem>,
                        )
                    })
                }
            })
    })
    return taxonomyOptions
}

export const buildTaxonomyMenuItems = (taxonomyListOptions: IReduxTaxonomy.IReduxTaxonomyResponse[]) =>
    taxonomyListOptions.map(obj => (
        <MenuItem style={{ padding: '5px', paddingLeft: '15px' }} key={obj.id} value={obj.id}>
            {obj.value}
        </MenuItem>
    ))

export const buildTaxonomyCheckboxOptions = (taxonomyListOptions: IReduxTaxonomy.IReduxTaxonomyResponse[], selectedIds: string[]) =>
    taxonomyListOptions.map(obj => (
        <MenuItem style={{ padding: '0px', paddingLeft: '10px' }} key={obj.id} value={obj.id}>
            <Checkbox checked={selectedIds.indexOf(obj.id) > -1} />
            <ListItemText primary={obj.value} />
        </MenuItem>
    ))

export const NumberFormatCustom = forwardRef(function NumberFormatCustom(props: any, ref) {
    const { onChange, notDecimal = false, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            decimalScale={notDecimal ? 0 : undefined}
            thousandSeparator
            isNumericString
            prefix=""
        />
    )
})
