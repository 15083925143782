export const consumerChoices = [
    'Consumer Auto',
    'Unsecured Installment',
    'Healthcare',
    'Payday',
    'Point of Sale',
    'Student',
    'Timeshare',
    'Credit Card',
    'Prime',
    'Subprime',
]

export const smeChoices = [
    'Small Business Loans',
    'Factoring',
    'Franchisee Lending',
    'Merchant Cash Advance',
    'Trade Finance',
    'Whole Business',
]

export const commercialChoices = ['Commercial - Bridge', 'Commercial - Permanent', 'Construction', 'Development', 'Commercial - NPL']

export const residentialChoices = [
    'Residential Mortgage - Conventional',
    'Residential Mortgage - Non-QM',
    'Residential - NPL',
    'Residential - Fix and Flip',
    'Single Family Rental',
]

export const renewableChoices = ['Solar C&I', 'Solar Residential', 'Wind']

export const specialtyChoices = [
    'Aircraft',
    'Art',
    'Commercial Auto',
    'Digital Currency',
    'Equipment',
    'Fund Leverage',
    'IP-Based Lending',
    'Litigation Finance',
    'Manufacturing',
    'Railcar',
    'Royalty',
    'Shipping Container',
    'Structured Settlements',
    'Tax Liens',
]

export const corporateCreditChoices = [
    'Broadly Syndicated Loans',
    'Middle Market Loans',
    'Asset-Based Loans',
    'Mezzanine',
    'Distressed / Special Situations',
]

export const privateEquityChoices = [
    'Buyout',
    'Growth',
    'Secondaries',
    'Natural Resources / Real Assets',
    'Pre-IPOs / PIPEs / SPACs',
    'Real Estate',
    'Restructuring / Turnaround / Special Situations',
]

export const ventureCapitalChoices = ['Early Stage: Seed', 'Early Stage: Start-up', 'Expansion / Late Stage', 'Venture Debt']

export const investmentStructureChoices = [
    'Whole Loans',
    'Warehouse Lines / Credit Facilities',
    'Securitized Products (e.g. CLOs, ABS)',
    'Co-Investments / Participations',
    'Funds',
    'Equity',
    'Corporate Debt',
]

export const capitalStructureChoices = ['Senior Debt', 'Junior Debt / Mezzanine', 'Equity']
export const geographyChoices = [
    'United States',
    'Canada',
    'Western Europe',
    'Central / Eastern Europe',
    'Asia',
    'Latin America',
    'Australia / New Zealand',
    'Africa',
    'Middle East',
]

export const maxTermChoices = ['Less than 1 year', '1-3 years', '3-5 years', '5-10 years', '10 years and over']

export const currencyChoices = [
    {
        value: '$',
        text: 'USD',
    },
    {
        value: 'د.إ',
        text: 'AED',
    },
    {
        value: 'A$',
        text: 'AUD',
    },
    {
        value: 'R$',
        text: 'BRL',
    },
    {
        value: 'C$',
        text: 'CAD',
    },
    {
        value: 'CHF',
        text: 'CHF',
    },
    {
        value: 'CLP$',
        text: 'CLP',
    },
    {
        value: '元',
        text: 'CNY',
    },
    {
        value: 'Col$',
        text: 'COP',
    },
    {
        value: 'Kč',
        text: 'CZK',
    },
    {
        value: 'DKK',
        text: 'DKK',
    },
    {
        value: '€',
        text: 'EUR',
    },
    {
        value: '£',
        text: 'GBP',
    },
    {
        value: 'HK$',
        text: 'HKD',
    },
    {
        value: 'Ft',
        text: 'HUF',
    },
    {
        value: 'Rp',
        text: 'IDR',
    },
    {
        value: '₪',
        text: 'ILS',
    },
    {
        value: '₹',
        text: 'INR',
    },
    {
        value: '¥',
        text: 'JPY',
    },
    {
        value: '₩',
        text: 'KRW',
    },
    {
        value: 'Mex$',
        text: 'MXN',
    },
    {
        value: 'RM',
        text: 'MYR',
    },
    {
        value: 'NOK',
        text: 'NOK',
    },
    {
        value: 'NZ$',
        text: 'NZD',
    },
    {
        value: '₱',
        text: 'PHP',
    },
    {
        value: 'zł',
        text: 'PLN',
    },
    {
        value: 'L',
        text: 'RON',
    },
    {
        value: '₽',
        text: 'RUB',
    },
    {
        value: '﷼',
        text: 'SAR',
    },
    {
        value: 'SEK',
        text: 'SEK',
    },
    {
        value: 'S$',
        text: 'SGD',
    },
    {
        value: '฿',
        text: 'THB',
    },
    {
        value: '₺',
        text: 'TRY',
    },
    {
        value: 'NT$',
        text: 'TWD',
    },
    {
        value: 'ZAR',
        text: 'ZAR',
    },
]

export const investor_preferences = {
    title: 'Investor Preferences',
    completedHtml:
        'Thank you for sharing your investment preferences. You can update these preferences at any time in your profile information.',
    pages: [
        {
            name: 'Preferences',
            elements: [
                {
                    type: 'checkbox',
                    name: 'Geographies',
                    title: 'In which geographies do you invest?',
                    isRequired: true,
                    choices: [...geographyChoices],
                    hasSelectAll: true,
                },
                {
                    type: 'dropdown',
                    name: 'Currency',
                    title: 'Please select the currency in which you will report your answers.',
                    defaultValue: '$',
                    isRequired: true,
                    choices: [...currencyChoices],
                },
                {
                    type: 'paneldynamic',
                    name: 'InvestmentSize',
                    title: 'What is your investment size per transaction?',
                    isRequired: true,
                    templateElements: [
                        {
                            type: 'matrixdynamic',
                            name: 'minimum',
                            title: ' ',
                            valueName: 'minimum',
                            columns: [
                                {
                                    name: 'numeric',
                                    title: 'Minimum Investment Size (million)',
                                    cellType: 'customnumbers',
                                    visibleIf: "!{row.noMinimum} contains 'No minimum'",
                                    fieldType: 'Currency',
                                    currencyQuestionName: 'Currency',
                                },
                                {
                                    name: 'noMinimum',
                                    title: ' ',
                                    cellType: 'checkbox',
                                    choices: ['No minimum'],
                                },
                            ],
                            rowCount: 1,
                            minRowCount: 1,
                            maxRowCount: 1,
                        },
                        {
                            type: 'matrixdynamic',
                            name: 'maximum',
                            title: ' ',
                            valueName: 'maximum',
                            columns: [
                                {
                                    name: 'numeric',
                                    title: 'Maximum Investment Size (million)',
                                    cellType: 'customnumbers',
                                    visibleIf: "!{row.noMaximum} contains 'No maximum'",
                                    fieldType: 'Currency',
                                    currencyQuestionName: 'Currency',
                                },
                                {
                                    name: 'noMaximum',
                                    title: ' ',
                                    cellType: 'checkbox',
                                    choices: ['No maximum'],
                                },
                            ],
                            allowAddRows: false,
                            allowRemoveRows: false,
                            rowCount: 1,
                            minRowCount: 1,
                            maxRowCount: 1,
                        },
                    ],
                    panelCount: 1,
                    minPanelCount: 1,
                    maxPanelCount: 1,
                },
                {
                    type: 'paneldynamic',
                    name: 'MinReturn',
                    title: 'What is your minimum return requirement or cost of capital?',
                    isRequired: true,
                    templateElements: [
                        {
                            type: 'matrixdynamic',
                            name: 'minimum',
                            title: ' ',
                            valueName: 'minimum',
                            columns: [
                                {
                                    name: 'numeric',
                                    title: 'Minimum Return',
                                    cellType: 'customnumbers',
                                    visibleIf: "!{row.noMinimum} contains 'No minimum'",
                                    fieldType: 'Percentage (0-100)',
                                },
                                {
                                    name: 'noMinimum',
                                    title: ' ',
                                    cellType: 'checkbox',
                                    choices: ['No minimum'],
                                },
                            ],
                            rowCount: 1,
                            minRowCount: 1,
                            maxRowCount: 1,
                        },
                    ],
                    panelCount: 1,
                    minPanelCount: 1,
                    maxPanelCount: 1,
                },
                {
                    type: 'checkbox',
                    name: 'MaxTerm',
                    title: 'In which maturity ranges will you invest (select all that apply)?',
                    isRequired: true,
                    choices: [...maxTermChoices],
                    hasSelectAll: true,
                },
                {
                    type: 'checkbox',
                    name: 'CapitalStructures',
                    title: 'Where in the capital structure do you invest?',
                    isRequired: true,
                    choices: [...capitalStructureChoices],
                    hasSelectAll: true,
                },
                {
                    type: 'checkbox',
                    name: 'InvestmentStructures',
                    title: 'In which structures do you invest?',
                    isRequired: true,
                    choices: [...investmentStructureChoices],
                    hasOther: true,
                    hasSelectAll: true,
                },
                {
                    type: 'radiogroup',
                    name: 'TotalPortfolioAum',
                    title: 'What is the approximate total AUM of the portfolios managed by you/your group (in USD)?',
                    isRequired: true,
                    choices: [
                        'Under $50 Million',
                        '$50 Million - $250 Million',
                        '$250 Million - $1 Billion',
                        '$1 Billion - $10 Billion',
                        '$10 Billion - $100 Billion',
                        'Over $100 Billion',
                    ],
                },
                {
                    type: 'panel',
                    name: 'assetBackedCreditPanel',
                    isRequired: true,
                    title: 'What types of investments interest you?',
                    elements: [
                        {
                            type: 'checkbox',
                            name: 'AssetBackedCreditConsumer',
                            title: 'Consumer',
                            choices: [...consumerChoices],
                            hasOther: true,
                            hasSelectAll: true,
                        },
                        {
                            type: 'checkbox',
                            name: 'AssetBackedCreditSME',
                            title: 'SME / Business',
                            choices: [...smeChoices],
                            hasOther: true,
                            hasSelectAll: true,
                        },
                        {
                            type: 'checkbox',
                            name: 'AssetBackedCreditCommercial',
                            title: 'Real Estate - Commercial',
                            choices: [...commercialChoices],
                            hasOther: true,
                            hasSelectAll: true,
                        },
                        {
                            type: 'checkbox',
                            name: 'AssetBackedCreditResidential',
                            title: 'Real Estate - Residential',
                            choices: [...residentialChoices],
                            hasOther: true,
                            hasSelectAll: true,
                        },
                        {
                            type: 'checkbox',
                            name: 'AssetBackedCreditRenewables',
                            title: 'Renewables',
                            choices: [...renewableChoices],
                            hasOther: true,
                            hasSelectAll: true,
                        },
                        {
                            type: 'checkbox',
                            name: 'AssetBackedSpecialtySectors',
                            title: 'Asset-Backed Specialty Sectors',
                            choices: [...specialtyChoices],
                            hasOther: true,
                            hasSelectAll: true,
                        },
                    ],
                },
                {
                    type: 'panel',
                    name: 'corporateCreditPanel',
                    elements: [
                        {
                            type: 'checkbox',
                            name: 'CorporateCredit',
                            title: 'Corporate Credit',
                            choices: [...corporateCreditChoices],
                            hasOther: true,
                            hasSelectAll: true,
                        },
                    ],
                },
                {
                    type: 'panel',
                    name: 'privateEquityPanel',
                    elements: [
                        {
                            type: 'checkbox',
                            name: 'PrivateEquity',
                            title: 'Private Equity',
                            choices: [...privateEquityChoices],
                            hasOther: true,
                            hasSelectAll: true,
                        },
                    ],
                },
                {
                    type: 'panel',
                    name: 'ventureCapitalPanel',
                    elements: [
                        {
                            type: 'checkbox',
                            name: 'VentureCapital',
                            title: 'Venture Capital',
                            choices: [...ventureCapitalChoices],
                            hasOther: true,
                            hasSelectAll: true,
                        },
                    ],
                },
                {
                    type: 'panel',
                    name: 'InfrastructureProjectFinancePanel',
                    elements: [
                        {
                            type: 'checkbox',
                            name: 'InfrastructureProjectFinance',
                            title: 'Other Finance',
                            choices: ['Infrastructure / Project Finance'],
                        },
                    ],
                },
                {
                    type: 'panel',
                    name: 'RevenuePanel',
                    title: 'What are the minimum financial requirements for the companies you would consider to finance?',
                    elements: [
                        {
                            type: 'customnumbers',
                            name: 'AnnualRevenue',
                            title: 'Annual Revenue (million)',
                            fieldType: 'Currency',
                            currencyQuestionName: 'Currency',
                            defaultValue: '0',
                            isRequired: true,
                        },
                        {
                            type: 'customnumbers',
                            name: 'EBITDA',
                            title: 'EBITDA (million)',
                            fieldType: 'Currency',
                            currencyQuestionName: 'Currency',
                            defaultValue: '0',
                            isRequired: true,
                        },
                        {
                            type: 'customnumbers',
                            name: 'EnterpriseValue',
                            title: 'Enterprise Value (million)',
                            fieldType: 'Currency',
                            currencyQuestionName: 'Currency',
                            defaultValue: '0',
                            isRequired: true,
                        },
                    ],
                },
                {
                    type: 'panel',
                    name: 'filePanel',
                    elements: [
                        {
                            type: 'comment',
                            name: 'Comments',
                            title: 'Please provide any additional comments you would like us to consider.',
                        },
                        {
                            type: 'html',
                            name: 'CommentsNote',
                            html: '<p style="width: 758px">You can also upload files containing additional investment criteria and details using the button below.\nPlease reach out to <a href="mailto:info@dealbridge.net">info@dealbridge.net</a> with any questions.</p> <hr class="questionnaireContainer__separator" />',
                        },
                        {
                            type: 'file',
                            name: 'Files',
                            title: 'Additional Files',
                            description: 'Please drop investment preferences here or click to upload.',
                            requiredErrorText: 'Please upload the relevant file here.',
                            allowMultiple: true,
                            showPreview: true,
                        },
                    ],
                },
            ],
        },
    ],
    showQuestionNumbers: 'off',
    sendResultOnPageNext: true,
}
