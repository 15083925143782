import {
    FormControl,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    FormHelperText,
    TextField,
    Button,
    Tooltip,
    Chip,
    Box,
    Switch,
    CircularProgress,
    Select,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState, useContext } from 'react'
import InfoIcon from '@material-ui/icons/Info'
import ChipInput from 'material-ui-chip-input'
import CloseIcon from '@material-ui/icons/Close'
import { useUpdateUserDetailsMutation } from 'store/modules/management/management.query'
import ProgressIndicator from 'app/components/Progress/ProgressIndicator'
import { useSnackbar } from 'notistack'
import { colorPalette } from 'styles/mainTheme'
import { getDrawerContext } from 'app/context/drawer/provider'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(3),
    },
    chipInput: {
        border: 'none',
        padding: 14,
        minHeight: 60,
        borderRadius: 4,
        height: 'auto',
        paddingBottom: 8,
        '&  .MuiChip-deleteIcon': {
            color: 'rgba(0, 0, 0, 0.12) !important',
        },
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    switch: {
        '& .MuiSwitch-input': {
            maxWidth: 'initial',
        },
    },
}))

const UserDrawer = props => {
    const classes = useStyles()

    const { userDetails, isLoadingUserDetails, params } = props

    const [updateUserData, { isLoading: isLoadingUpdateUserData }] = useUpdateUserDetailsMutation()

    const drawer = useContext(getDrawerContext())

    const [userType, setUserType] = useState({
        StandardUser: false,
        TeamAdmin: false,
        WlpAdmin: false,
    })

    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: 0,
        email: '',
        linkedInUrl: '',
        position: '',
        countryId: '',
    })

    const [fullName, setFullname] = useState<string>('')
    const { firstName, lastName, phoneNumber, email, linkedInUrl, position, countryId } = userData

    const handleUserDataChange = event => {
        setUserData({ ...userData, [event.target.name]: event.target.value })
    }

    const [teamChips, setTeamChips] = useState<any>([])

    const [companyChips, setCompanyChips] = useState<any>([])

    useEffect(() => {
        if (!isLoadingUserDetails) {
            setUserType({
                StandardUser: userDetails?.user?.roleNames?.includes('StandardUser'),
                TeamAdmin: userDetails?.user?.roleNames?.includes('TeamAdmin'),
                WlpAdmin: userDetails?.user?.roleNames?.includes('WlpAdmin'),
            })
            setUserData({
                firstName: userDetails?.onboardingProfilePart?.firstName,
                lastName: userDetails?.onboardingProfilePart?.lastName,
                phoneNumber: userDetails?.onboardingProfilePart?.phoneNumber,
                email: userDetails?.user?.email,
                linkedInUrl: userDetails?.onboardingProfilePart?.linkedInUrl,
                position: userDetails?.onboardingProfilePart?.position,
                countryId: userDetails?.onboardingProfilePart?.countryId,
            })
            setTeamChips(userDetails?.teams?.map(item => item?.displayText))
            setCompanyChips(userDetails?.companies?.map(item => item?.displayText))
            setIsUserEnabled(userDetails?.isEnabled)
            setFullname(userDetails?.onboardingProfilePart?.fullName)
        }
    }, [isLoadingUserDetails, userDetails])

    const handleUserTypeChange = event => {
        setUserType({ ...userType, [event.target.name]: event.target.checked })
    }

    const { WlpAdmin } = userType

    type Anchor = 'right'

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return
        }
        props.onChangeOpenDrawer(open)
    }

    const [isUserEnabled, setIsUserEnabled] = useState(true)

    const handleChangeSwitch = event => {
        setIsUserEnabled(event.target.checked)
    }

    const { enqueueSnackbar } = useSnackbar()

    const handleEdit = () => {
        let userTypePayload: any = []

        for (const key in userType) {
            if (userType[key]) {
                userTypePayload.push(key)
            }
        }

        updateUserData({ payload: { ...userData, isEnabled: isUserEnabled, roleNames: userTypePayload }, userId: props.userId, params })
            .unwrap()
            .then(() => {
                enqueueSnackbar('User data updated successfully!', { variant: 'success' })
            })
            .catch((err: any) => {
                enqueueSnackbar(`An error occurred`, { variant: 'error' })
            })
    }

    const handleClose = () => {
        drawer.onClose()
    }

    const [roleOption, setRoleOption] = useState('StandardUser')

    const handleChangeRole = event => {
        setUserType({ ...userType, [event.target.value]: true })
        setRoleOption(event.target.value)
    }

    useEffect(() => {
        if (userType['TeamAdmin']) {
            setRoleOption('TeamAdmin')
        } else {
            setRoleOption('StandardUser')
        }
    }, [userType])

    if (isLoadingUserDetails) {
        return <ProgressIndicator />
    }

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent={'space-between'} gridColumnGap={24} data-testid="userdrawer">
                <div>
                    <Typography variant="subtitle2">USER</Typography>
                    <Typography variant="h3" color="primary">
                        {fullName}
                    </Typography>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '8px', marginTop: '6px' }}>
                        <Switch
                            checked={isUserEnabled}
                            size="small"
                            color="primary"
                            className="switch-small"
                            inputProps={{ 'aria-label': 'Switch demo' }}
                            onChange={handleChangeSwitch}
                        />

                        <FormHelperText>Enable User</FormHelperText>

                        <Tooltip title="Toggle to deactivate this user account.">
                            <InfoIcon style={{ color: '#4299E1' }} />
                        </Tooltip>
                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        <ChipInput
                            InputProps={{
                                className: classes.chipInput,
                            }}
                            label="Company"
                            fullWidth
                            variant="outlined"
                            defaultValue={companyChips}
                            disabled={true}
                            chipRenderer={({ chip, className, handleClick }, key) => (
                                <Chip
                                    className={className}
                                    key={key}
                                    label={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{chip}</span>}
                                    onClick={handleClick}
                                    style={{
                                        background: '#EBEBEB',
                                        borderRadius: 4,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <ChipInput
                            InputProps={{
                                className: classes.chipInput,
                            }}
                            label="Team"
                            fullWidth
                            variant="outlined"
                            defaultValue={teamChips}
                            disabled={true}
                            chipRenderer={({ chip, className, handleClick }, key) => (
                                <Chip
                                    className={className}
                                    key={key}
                                    label={<span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{chip}</span>}
                                    onClick={handleClick}
                                    style={{
                                        background: '#EBEBEB',
                                        borderRadius: 4,
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <CloseIcon onClick={toggleDrawer('right', false)} />
            </Box>
            <Typography style={{ marginTop: '1rem' }}>Role</Typography>
            <Select value={roleOption} onChange={handleChangeRole} style={{ minHeight: '70px', width: '100%' }}>
                <MenuItem value={'StandardUser'}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon style={{ minWidth: '30px', marginRight: '10px' }}>
                            <AccountCircleIcon />
                        </ListItemIcon>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2" style={{ fontWeight: '700' }}>
                                        Member
                                    </Typography>
                                }
                            />

                            <ListItemText secondary="Access to deals you specify only." />
                        </div>
                    </div>
                </MenuItem>
                <MenuItem value={'TeamAdmin'}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon style={{ minWidth: '30px', marginRight: '10px' }}>
                            <SupervisorAccountIcon />
                        </ListItemIcon>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle2" style={{ fontWeight: '700' }}>
                                        Admin
                                    </Typography>
                                }
                            />
                            <ListItemText secondary="Access to all deals with full featue ability." />
                        </div>
                    </div>
                </MenuItem>
            </Select>
            <FormControl required component="fieldset">
                <Typography style={{ marginTop: '1rem' }}>Account Administrator</Typography>
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControlLabel
                        control={<Checkbox checked={WlpAdmin} onChange={handleUserTypeChange} name="WlpAdmin" />}
                        label="Enable"
                    />
                </FormGroup>
            </FormControl>
            <FormHelperText>
                If updated, then the roles of the users will be updated as well (users on the removed user groups will be removed, users on
                the new user groups will be added).
            </FormHelperText>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
                <TextField
                    style={{ marginRight: '1rem' }}
                    label="First Name"
                    name="firstName"
                    value={firstName}
                    onChange={e => handleUserDataChange(e)}
                    variant="outlined"
                />
                <TextField label="Last Name" variant="outlined" name="lastName" value={lastName} onChange={e => handleUserDataChange(e)} />
            </div>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
                <TextField
                    name="phoneNumber"
                    style={{ marginRight: '1rem' }}
                    value={phoneNumber}
                    onChange={e => handleUserDataChange(e)}
                    label="Telephone Number"
                    variant="outlined"
                />
                <TextField label="Country" variant="outlined" name="countryId" value={countryId} onChange={e => handleUserDataChange(e)} />
            </div>
            <TextField
                style={{ marginTop: '1rem' }}
                label="Email"
                name="email"
                value={email}
                onChange={e => handleUserDataChange(e)}
                variant="outlined"
            />
            <TextField
                style={{ marginTop: '1rem' }}
                label="Job Title"
                name="position"
                value={position}
                onChange={e => handleUserDataChange(e)}
                variant="outlined"
            />
            <TextField
                style={{ marginTop: '1rem' }}
                name="linkedInUrl"
                value={linkedInUrl}
                onChange={e => handleUserDataChange(e)}
                label="LinkedIn Profile"
                variant="outlined"
            />

            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '2rem' }}>
                <Button
                    variant="contained"
                    size="large"
                    style={{ marginRight: '1rem' }}
                    onClick={handleEdit}
                    disabled={isLoadingUpdateUserData || isLoadingUserDetails}
                >
                    {(isLoadingUpdateUserData || isLoadingUserDetails) && (
                        <CircularProgress size={16} style={{ marginRight: 6, color: colorPalette.primary }} />
                    )}
                    Save
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    onClick={handleClose}
                    disabled={isLoadingUpdateUserData || isLoadingUserDetails}
                    data-testid="userdrawer-cancel"
                >
                    Cancel
                </Button>
            </div>
        </div>
    )
}

export default UserDrawer
