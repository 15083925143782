import React, { Context } from 'react'
import { DealByDealModal } from './components/DealByDealModal'
import { CategoryModal, GeneralConfirmationModalOptions, IDBDModalPropsData, IModalContext, Options, Props } from './definition'
import { RequestAccessModal } from './components/RequestAccessModal'
import { GeneralModal } from './components/GeneralModal'
import { GeneralConfirmationModal } from './components/GeneralConfirmationModal'
import UniversalInviteDialog from './components/UniversalInviteDialog'

let ModalContext: Context<IModalContext>

const getModalContext = <TProps, TOptions = Options>() => {
    return ModalContext as unknown as Context<IModalContext<TProps, TOptions>>
}

const { Provider } = (ModalContext = React.createContext<IModalContext>({
    isOpen: false,
    content: '',
    onClose: () => {},
    onHandle: () => () => {},
    category: 'general',
    props: {},
}))

const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [props, setProps] = React.useState<Props>({})
    const [category, setCategory] = React.useState<CategoryModal>('')
    const [content, setContent] = React.useState('')
    const [options, setOptions] = React.useState<Options>({})

    const openModal = <TOptions,>(category: CategoryModal, content: string, _props: any, _options?: TOptions): void => {
        setCategory(category)
        setProps(_props)

        if (_options) {
            setOptions(_options)
        }

        if (content) {
            setContent(content)
        }
    }

    const onClose = () => {
        // Additional action
        options?.onClose?.()

        // Reset when modal closed
        setIsOpen(false)
        setOptions({})
        setContent('')
        setCategory('')
        setProps({})
    }

    const onHandle: any = (category: CategoryModal) => {
        setIsOpen(true)
        if (category === 'deal-by-deal') {
            return (_props: IDBDModalPropsData, _options?: Options) => openModal<Options>(category, '', _props, _options)
        }

        if (category === 'request-access') {
            return (_props: IDBDModalPropsData, _options?: Options) => openModal<Options>(category, '', _props, _options)
        }

        if (category === 'general') {
            return (content: string, _props: Props = {}, _options?: Options) => openModal<Options>(category, content, _props, _options)
        }

        if (category === 'general-confirmation') {
            return (title: string, _props: Props = {}, _options?: GeneralConfirmationModalOptions) =>
                openModal<GeneralConfirmationModalOptions>(category, title, _props, _options)
        }
        if (category === 'universal-invite') {
            return (_props: Props = {}, _options?: Options) => openModal(category, '', _props, _options)
        }
    }

    return (
        <Provider value={{ isOpen, onHandle, onClose, content, category, props, options }}>
            {/* Register reusable modal here */}
            <GeneralModal />
            <GeneralConfirmationModal />
            <DealByDealModal />
            <RequestAccessModal />
            <UniversalInviteDialog />
            {children}
        </Provider>
    )
}

export { ModalContext, ModalProvider, getModalContext }
