import { Badge, Box, Button, CircularProgress, Divider, Drawer, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'

import { CommentDrawerFilterType, ICommentDrawerPropsData } from 'app/context/drawer/definition'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetCommentsQuery } from 'store/modules/comment'
import { ReduxCommentTypeNamespace } from 'store/modules/comment/comment'
import { CommentContentType } from 'store/modules/comment/enum'
import { useGetSurveyOpportunityDDQMutation } from 'store/modules/survey'
import { useDebounce } from 'utils/general'
import { getToken } from 'utils/localStorage/token'
import { SearchInput } from 'app/components/SearchInput/SearchInput'

import CommentRoom from './CommentRoom'

interface ICommentRoomDrawer {
    onClose: Function
    filter: ICommentDrawerPropsData
}

const useStyles = makeStyles(theme => ({
    notificationsDrawer: {
        maxWidth: '400px',
        top: '93px !important',
        '& .MuiDrawer-paperAnchorRight': {
            top: '93px',
            minWidth: 480,
            [theme.breakpoints.down('sm')]: {
                mindWidth: 'auto',
                top: 0,
                left: 0,
                right: 0,
            },
        },
        '& .MuiBackdrop-root': {
            top: '93px',
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    subheader: {
        padding: 16,
        paddingTop: 0,
        display: 'flex',
        gap: '12px',
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },
    notificationsContainer: {
        width: 480,
        overflowY: 'scroll',
        height: 'calc(100% - 310px)',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: 'auto',
        },
    },
    iconContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 32,
            justifyContent: 'space-between',
        },
    },
}))

export const CommentRoomDrawer = ({ onClose, filter }: ICommentRoomDrawer) => {
    const { id, sourceId, status } = filter

    const access_token = getToken('access_token')
    const classes = useStyles()

    const [submissionId, setSubmissionId] = useState(sourceId === CommentContentType['Application Submission'] ? id : '')

    const [search, setSearch] = useState(filter.query || '')
    const [type, setType] = useState<CommentDrawerFilterType>(filter.type || CommentDrawerFilterType['All'])

    const debouncedSearch = useDebounce(search).toLowerCase().trim()
    const [getSurveyOpportunityDDQ, { isLoading: isLoadingDDQ }] = useGetSurveyOpportunityDDQMutation()

    const defaultComment = useMemo(
        () => ({
            elementIdToThreadId: {},
            comment: {},
            thread: {},
            threads: [],
        }),
        [],
    )

    const transactionId = sourceId === CommentContentType['Opportunity'] ? id : ''

    const { data: transactionComment = defaultComment, isLoading: isLoadingTransactionComment } = useGetCommentsQuery(
        {
            contentType: CommentContentType['Opportunity'],
            id: transactionId,
        },
        {
            skip: !access_token || !transactionId,
        },
    )
    const { data: applicationSubmissionComment = defaultComment, isLoading: isLoadingApplicationSubmissionComment } = useGetCommentsQuery(
        {
            contentType: CommentContentType['Application Submission'],
            id: submissionId,
        },
        {
            skip: !access_token || !submissionId,
        },
    )

    const refetchDdq = useCallback(async () => {
        if (transactionId && !submissionId) {
            try {
                const survey = await getSurveyOpportunityDDQ(transactionId).unwrap()
                if (survey.progress > 0 && survey.meta.submissionId) {
                    setSubmissionId(survey.meta.submissionId)
                }
            } catch (err) {}
        }
    }, [transactionId, submissionId, getSurveyOpportunityDDQ])

    useEffect(() => {
        refetchDdq()
    }, [refetchDdq])

    const close = useCallback(() => {
        onClose()
    }, [onClose])

    const onChangeType = e => {
        setType(e.target.value)
    }

    const isLoading = isLoadingTransactionComment || isLoadingDDQ || isLoadingApplicationSubmissionComment

    // Defined filter and join the data
    const combinedCommentWithCount = useMemo((): ReduxCommentTypeNamespace.ICommentResponseTransformed & { total: number } => {
        if (!isLoadingDDQ && !isLoadingTransactionComment) {
            const thread = {
                ...transactionComment.thread,
                ...applicationSubmissionComment.thread,
            }

            const comment = {
                ...transactionComment.comment,
                ...applicationSubmissionComment.comment,
            }

            let total = 0

            const threads = [...transactionComment.threads, ...applicationSubmissionComment.threads]
                .filter(th => {
                    // Filter by search
                    let isFound =
                        !debouncedSearch ||
                        th.text.toLowerCase().includes(debouncedSearch) ||
                        thread[th.id].comments.findIndex(cm => cm.text.toLowerCase().includes(debouncedSearch)) !== -1

                    // Filter by status
                    if (isFound && status?.length) {
                        isFound = status.includes(th.status)
                    }

                    // Filter by type
                    if (isFound && type && type !== CommentDrawerFilterType['All']) {
                        if (type === CommentDrawerFilterType['Opportunity']) {
                            isFound = th.contentType === CommentContentType['Opportunity']
                        } else if (type === CommentDrawerFilterType['Application Submission']) {
                            isFound =
                                th.contentType === CommentContentType['Application Submission'] &&
                                !['ApplicationSubmissionApprovalComment', 'ApplicationSubmissionRejectionComment'].includes(
                                    th.sub_comment_object,
                                )
                        } else if (type === CommentDrawerFilterType['Approval & Rejection']) {
                            isFound = ['ApplicationSubmissionApprovalComment', 'ApplicationSubmissionRejectionComment'].includes(
                                th.sub_comment_object,
                            )
                        }
                    }

                    if (isFound) {
                        // +1 means (thread which is 1 + comments length)
                        total = total + 1 + thread[th.id].comments.length
                        return true
                    }

                    return false
                })
                .sort((a, b) => b.modified_on_ts - a.modified_on_ts)

            return {
                elementIdToThreadId: {
                    ...transactionComment.elementIdToThreadId,
                    ...applicationSubmissionComment.elementIdToThreadId,
                },
                comment,
                thread,
                threads,
                total,
            }
        }

        return {
            ...defaultComment,
            total: 0,
        }
    }, [
        applicationSubmissionComment.comment,
        applicationSubmissionComment.elementIdToThreadId,
        applicationSubmissionComment.thread,
        applicationSubmissionComment.threads,
        debouncedSearch,
        defaultComment,
        isLoadingDDQ,
        isLoadingTransactionComment,
        status,
        transactionComment.comment,
        transactionComment.elementIdToThreadId,
        transactionComment.thread,
        transactionComment.threads,
        type,
    ])

    const { total, ...combinedComment } = combinedCommentWithCount

    const getCommentRoom = () => {
        if (isLoading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </div>
            )
        }

        if (!total && debouncedSearch) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Typography color="textSecondary" style={{ maxWidth: '350px' }} align="center">
                        We can't find a thread that contains your search "{debouncedSearch}"
                    </Typography>
                    <Button style={{ marginTop: '16px' }} variant="text" onClick={() => setSearch('')}>
                        reset
                    </Button>
                </div>
            )
        }

        if (!total) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Typography color="textSecondary" style={{ maxWidth: '350px' }} align="center">
                        The transaction does not have a thread{status ? ` with "${status.join(',')}" status` : ''} yet
                    </Typography>
                </div>
            )
        }

        return (
            <CommentRoom
                data={combinedComment}
                hideHeader
                permissionMeta={{ type: 'opportunity', id: transactionId }}
                viewMode="VIEW_ALL"
                headerProps={{
                    onClose: close,
                }}
            />
        )
    }

    return (
        <div style={{ zIndex: 1000, right: 0, top: 200, position: 'absolute' }}>
            <Drawer className={classes.notificationsDrawer} anchor={'right'} open onClose={close}>
                <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', maxWidth: '480px' }}>
                    <div className={classes.header}>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="h2" style={{ fontSize: 24 }}>
                                Comments
                            </Typography>
                            <Badge badgeContent={total} color="error" style={{ right: '-20px', top: '14px' }}></Badge>
                        </div>

                        <Box display="flex" gridColumnGap={24} className={classes.iconContainer}>
                            <Box display="flex" gridColumnGap={18}></Box>
                            <Box display="flex" gridColumnGap={18}>
                                <CloseIcon onClick={close} />
                            </Box>
                        </Box>
                    </div>
                    <div className={classes.subheader}>
                        <SearchInput value={search} onChange={val => setSearch(val)} />
                        {sourceId === CommentContentType['Opportunity'] && (
                            <Select
                                labelId="drawer-comment-filter-type"
                                id="drawer-comment-filter-type"
                                value={type}
                                style={{ width: '200px' }}
                                onChange={onChangeType}
                            >
                                <MenuItem value={'All'} selected>
                                    All
                                </MenuItem>
                                <MenuItem value={'Opportunity'}>Opportunity</MenuItem>
                                <MenuItem value={'Application Submission'}>Application Submission</MenuItem>
                                <MenuItem value={'Approval & Rejection'}>Approval & Rejection</MenuItem>
                            </Select>
                        )}
                    </div>
                    <Divider />
                    <div style={{ paddingBottom: '100px', overflowY: 'scroll', minHeight: '70vh' }} className="chat-room--scroll">
                        {getCommentRoom()}
                    </div>
                </div>
            </Drawer>
        </div>
    )
}
