import React, { useMemo, useState } from 'react'
import { getModalContext } from '../provider'

import { Box, Button, CircularProgress, makeStyles, Modal, TextareaAutosize, Typography } from '@material-ui/core'
import { colorPalette } from 'styles/mainTheme'
import { GeneralConfirmationModalOptions, Props } from '../definition'

import CloseIcon from '@material-ui/icons/CloseOutlined'
import { useCreateCommentMutation, useGetCommentsQuery, useReplyCommentMutation } from 'store/modules/comment'
import { getToken } from 'utils/localStorage/token'
import { CommentContentType, CommentStatus } from 'store/modules/comment/enum'

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: '0px 2px 18px rgb(13 59 105 / 15%)',
        backgroundColor: colorPalette.white,
        border: 'solid 1px lightgray',
        borderRadius: 8,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 64px)',
            height: 'calc(100% - 64px)',
        },
    },
    modalButton: {
        marginRight: '5px',
        marginLeft: '5px',
    },
    messageTextArea: {
        width: '100%',
        padding: '8px',
        fontSize: '14px',
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgba(0, 0, 0, 0.26)',
        borderRadius: '3px',
        fontFamily: 'Inter',
    },
}))

export const GeneralConfirmationModal = () => {
    const classes = useStyles()
    const access_token = getToken('access_token')
    const { content, onClose, isOpen, category, props, options } = React.useContext(
        getModalContext<Props, GeneralConfirmationModalOptions>(),
    )

    const [message, setMessage] = useState('')

    const {
        data = {
            elementIdToThreadId: {},
        },
        isLoading: isLoadingComment,
    } = useGetCommentsQuery(
        {
            contentType: options?.inputMessageMeta?.commentContentType ?? CommentContentType['Opportunity'],
            id: options?.inputMessageMeta?.commentContentTypeId ?? '',
        },
        { skip: !access_token || !options?.inputMessageMeta?.commentContentType || !options?.inputMessageMeta?.commentContentTypeId },
    )
    const [createComment, { isLoading: isCreatingComment }] = useCreateCommentMutation()
    const [replyComment, { isLoading: isReplyingComment }] = useReplyCommentMutation()

    const { hasCommentArea, isCommentRequired } = useMemo(
        () => ({
            hasCommentArea: !!options?.hasInputMessage && !!options?.processInputMessageAs && !!options?.inputMessageMeta,
            isCommentRequired: !!options?.inputMessageMeta?.isCommentRequired && options?.processInputMessageAs === 'comment',
        }),
        [options?.hasInputMessage, options?.inputMessageMeta, options?.processInputMessageAs],
    )

    const shouldShowLoader = isCreatingComment || isReplyingComment
    const shouldDisableAction = isCreatingComment || isReplyingComment || (isCommentRequired && !message)

    const onNegativeAction = () => {
        props?.onNegativeAction?.() ?? onClose()
    }

    const onPositiveAction = async () => {
        if (options?.processInputMessageAs === 'comment' && options.inputMessageMeta && message) {
            try {
                const commentator = {
                    content_item_id: options.inputMessageMeta.currentAuthorId,
                    name: options.inputMessageMeta.currentAuthorName,
                    company_name: options.inputMessageMeta.currentAuthorName,
                }

                const parentId = data.elementIdToThreadId[options.inputMessageMeta.commentElementId]

                if (parentId) {
                    const body = {
                        comment_parent_id: parentId,
                        text: message,
                        status: CommentStatus.Publish,
                        object_id: options.inputMessageMeta.commentContentTypeId,
                        content_type: options.inputMessageMeta.commentContentType,
                        extra_data: {
                            pathname: window.location.pathname,
                        },
                    }

                    await replyComment({
                        body,
                        commentator,
                    }).unwrap()
                } else {
                    const body = {
                        sub_comment_object: options.inputMessageMeta.commentElementId,
                        text: message,
                        status: CommentStatus.Publish,
                        object_id: options.inputMessageMeta.commentContentTypeId,
                        content_type: options.inputMessageMeta.commentContentType,
                        extra_data: {
                            pathname: window.location.pathname,
                        },
                    }

                    await createComment({
                        body,
                        commentator,
                    }).unwrap()
                }
            } catch (error) {
                console.log(error)
            }
        }

        setMessage('')
        props?.onPositiveAction?.() ?? onClose()
    }

    const getPlaceholder = () => {
        const requiredOptionalText = isCommentRequired ? '(Required)' : '(Optional)'
        if (options?.processInputMessageAs === 'comment') {
            return `Comments ${requiredOptionalText}`
        }

        return `Message ${requiredOptionalText}`
    }

    const onChangeMessage = e => {
        setMessage(e.target.value)
    }

    if (isLoadingComment) {
        return <></>
    }

    if (isOpen && category === 'general-confirmation') {
        return (
            <Modal
                open={isOpen}
                onClose={() => onClose()}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                {...(props?.modal ?? {})}
            >
                <>
                    <Box className={classes.modal}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                            <Typography color="primary" id="confirmation-modal-title" variant="h6" component="h2">
                                {content}
                            </Typography>
                            <Button className="chat-room__header__right-action" onClick={() => onClose()}>
                                <CloseIcon fontSize={'small'} />
                            </Button>
                        </div>

                        <Typography style={{ marginBottom: '8px' }}>
                            <div dangerouslySetInnerHTML={{ __html: props?.modal?.subTitle ?? '' }}></div>
                        </Typography>

                        {hasCommentArea && (
                            <TextareaAutosize
                                className={classes.messageTextArea}
                                minRows={8}
                                value={message}
                                placeholder={getPlaceholder()}
                                onChange={onChangeMessage}
                            />
                        )}
                        {props?.options?.hasAction && (
                            <Box display="flex" alignItems="center" justifyContent="end" mt={4}>
                                {!props?.options?.hideNegative && (
                                    <Button variant="outlined" className={classes.modalButton} onClick={onNegativeAction}>
                                        {props?.options?.negativeText ?? 'Cancel'}
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    style={
                                        props?.options?.positiveColor && !shouldDisableAction
                                            ? { backgroundColor: props.options.positiveColor }
                                            : {}
                                    }
                                    className={classes.modalButton}
                                    onClick={onPositiveAction}
                                    disabled={shouldDisableAction}
                                    endIcon={shouldShowLoader && <CircularProgress size={16} />}
                                >
                                    {props?.options?.positiveText ?? 'Cancel'}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </>
            </Modal>
        )
    }

    return null
}
