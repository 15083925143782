import React from 'react'

import { getDrawerContext } from '../provider'
import { IInvestorPreferenceDrawerPropsData } from '../definition'
import InvestorPreferencesPage from 'app/pages/InvestorPreferencesPage'

export const InvestorPreferenceDrawer = () => {
    const drawer = React.useContext(getDrawerContext<IInvestorPreferenceDrawerPropsData>())

    const { userId, viewAs } = drawer.props || {}

    if (drawer.isOpen && drawer.category === 'investor-preferences') {
        return <InvestorPreferencesPage viewAs={viewAs} userId={userId}></InvestorPreferencesPage>
    }

    return null
}
