import { memo } from 'react'
import { Box, Typography, useMediaQuery, useTheme, Link } from '@material-ui/core'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
// import { openPopupWidget } from 'react-calendly'
import { colorPalette } from 'styles/mainTheme'
import EmailIcon from '@material-ui/icons/Email'
// import CallIcon from '@material-ui/icons/Call'
// import config from 'config/index'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root1: {
            border: '1px solid #D8D8D8',
            backgroundColor: colorPalette.white,
            borderRadius: theme.spacing(1),
        },
        title: {
            display: 'flex',
            gap: theme.spacing(1.25),
            fontSize: 12,
        },
        subtitle: {
            fontSize: 12,
            fontWeight: 600,
            marginLeft: theme.spacing(4.25),
            cursor: 'pointer',
        },
    }),
)

export default memo(function ContactUsCard(props: { isBSSDDq?: boolean }) {
    const classes = useStyles()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const max1500 = useMediaQuery('(max-width:1500px)')

    // const openCalendly = () => openPopupWidget({ url: config.CALENDLY_URL || '' })

    return (
        <Box
            data-testid="contact-us-card"
            className={classes.root1}
            padding={2.5}
            margin="auto"
            width={isSmall ? 'auto' : 255}
            position={(props?.isBSSDDq as any) ? 'inherit' : isSmall ? 'initial' : max1500 ? 'inherit' : 'absolute'}
        >
            <Box paddingBottom={2.5}>
                <Typography color="primary" className={classes.title}>
                    <EmailIcon width={16} height={16} />
                    Email us
                </Typography>
                <Typography color="primary" component={Link} href="mailto:support@dealbridge.net" className={classes.subtitle}>
                    support@dealbridge.net
                </Typography>
            </Box>
            {/* <Divider />
            <Box paddingTop={2.5}>
                <Typography color="primary" className={classes.title}>
                    <CallIcon width={16} height={16} />
                    Speak with our team
                </Typography>
                <Typography onClick={openCalendly} color="primary" className={classes.subtitle}>
                    Schedule a call
                </Typography>
            </Box> */}
        </Box>
    )
})
