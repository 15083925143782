import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxAuthorization } from 'store/modules/authorization/authorization'
import config from 'config/index'

const reducerPath = 'authorizationApi'

export const authorizationApiSlice = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ACTIVITY_URL),
    endpoints: builder => ({
        getTeamG20: builder.query<IReduxAuthorization.IReduxMainResponse, Object | void>({
            query: () => ({
                url: `/authorization/v1/team/?company=&company__is_G20=true`,
                method: 'GET',
            }),
        }),
    }),
})

export const { useGetTeamG20Query } = authorizationApiSlice

export const authorizationQueryReducer = { [reducerPath]: authorizationApiSlice.reducer }
export const authorizationQuerySelector = state => state[reducerPath]
