import { surveySliceReducer } from 'store/modules/survey/survey.slice'
import { surveyQueryReducer } from 'store/modules/survey/survey.query'

const combinedReducer = {
    ...surveySliceReducer,
    ...surveyQueryReducer,
}

export * from 'store/modules/survey/survey.slice'
export * from 'store/modules/survey/survey.query'
export default combinedReducer
