import { Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import TreeItem from '@material-ui/lab/TreeItem'
import React from 'react'
export interface RenderTree {
    id: string
    name: string
    children?: RenderTree[]
}

const getChildByName = (node: RenderTree, name: string) => {
    let array: string[] = []

    function getAllChild(nodes: RenderTree | null) {
        if (nodes === null) return []
        array.push(nodes.name)
        if (Array.isArray(nodes.children)) {
            nodes.children.forEach(node => {
                array = [...array, ...getAllChild(node)]
                array = array.filter((v, i) => array.indexOf(v) === i)
            })
        }
        return array
    }

    function getNodeByName(nodes: RenderTree, name: string) {
        if (nodes.name === name) {
            return nodes
        } else if (Array.isArray(nodes.children)) {
            let result: any = null
            nodes.children.forEach(node => {
                if (!!getNodeByName(node, name)) {
                    result = getNodeByName(node, name)
                }
            })
            return result
        }

        return null
    }

    return getAllChild(getNodeByName(node, name))
}

/**
    A function that return event function to handle change of checkbox value,
    The lifecycle of changes when there's click event:
    - Empty -> Checked
    - Indeterminate -> Checked
    - Checked -> Empty
    @param nodes An object that contain's children
    @param selected Current selected value
    @param setSelected Setter of `selected` value
    @param event contain's the next value of checkbox (the value after user clicked the checkbox)
*/
const onCheckBoxChange = (nodes: RenderTree, selected: string[], setSelected: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    /*
            isNewestChecked will be true if the "next" value (after user click the checkbox) is checked/indeterminate,
            possibility value is checked or indeterminate
        */
    const isNewestChecked = event.currentTarget.checked

    let childrenNames: string[] = getChildByName(nodes, nodes.name)
    if (isNewestChecked) {
        childrenNames = [...selected, ...childrenNames]
    } else {
        childrenNames = selected.filter(name => !childrenNames.includes(name))
    }

    setSelected(childrenNames)
}

const onInputChange = (prevState, setter: (obj: any) => void, name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const formValue = event.target.value
    setter({ ...prevState, [name]: formValue })
}

/**
    A function to collect nested children's name
    @param children An object that contain's children
    @param childrens An array of string variable that will modified to store children's name
*/
const getMyChildrenNames = (children: RenderTree['children'], childrens: string[]) => {
    children?.forEach(child => {
        if (child.children) {
            getMyChildrenNames(child.children, childrens)
        } else {
            childrens.push(child.name)
        }
    })
}

export const renderTree = (nodes: RenderTree, selected: string[], setSelected: any, other?: any, setOther?: any) => {
    const isParent = (nodes.children?.length ?? 0) > 0
    const childrenNames = []
    isParent && getMyChildrenNames(nodes.children, childrenNames)

    const checkedChildItems = isParent ? childrenNames?.filter(name => selected.includes(name)) : []
    const isNotCheckedAnItems = (checkedChildItems?.length ?? 0) === 0
    const isAllChildChecked = childrenNames?.length === checkedChildItems?.length

    /* 
     Indeterminate is "-" icon in checkbox that only defined when
     there's not checked items or all child is checked,
     undefined means we not setup the indeterminate,
     true or false means we setup the indeterminate
    */
    const isIndeterminate = isNotCheckedAnItems || isAllChildChecked ? undefined : isParent && !isAllChildChecked

    /**
     * Remove parent value from a selected value when the parent is indeterminate
     */
    if (isIndeterminate) {
        if (selected.findIndex(s => s === nodes.name) !== -1) {
            setSelected(selected.filter(s => s !== nodes.name))
        }
    } else if (isParent && isAllChildChecked) {
        if (selected.findIndex(s => s === nodes.name) === -1) {
            setSelected([...selected, nodes.name])
        }
    }

    return (
        <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isParent ? isAllChildChecked : selected.some(item => item === nodes.name)}
                                indeterminate={isIndeterminate}
                                onChange={onCheckBoxChange(nodes, selected, setSelected)}
                                onClick={e => e.stopPropagation()}
                                style={{ margin: 9, padding: 0 }}
                                data-testid={`question-${nodes.name}`}
                            />
                        }
                        label={<>{nodes.name}</>}
                        key={nodes.id}
                    />
                    {selected.some(item => item === nodes.name) && nodes.name.toLowerCase().includes('describe') && (
                        <div>
                            <TextField
                                onChange={onInputChange(other, setOther, nodes.name)}
                                id="outlined-start-adornment"
                                style={{ marginLeft: 40, marginTop: 16 }}
                                placeholder="Enter here"
                                defaultValue={other[nodes.name]}
                                inputProps={{
                                    'data-testid': `question-${nodes.name}-other-input`,
                                }}
                            />
                        </div>
                    )}
                </>
            }
        >
            {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node, selected, setSelected, other, setOther)) : null}
        </TreeItem>
    )
}
