import { Helmet } from 'react-helmet-async'

import Layout from 'app/components/Layout'
import { lazyLoad } from 'utils/loadable'

const Page = lazyLoad(
    () => import('./index'),
    module => module.default,
)

export const PatentLitigationPage = props => {
    return (
        <>
            <Helmet>
                <title>Patent Litigation Page</title>
                <meta name="description" content="Patent litigation page" />
            </Helmet>
            <Layout footer ddqPage>
                <Page {...props} />
            </Layout>
        </>
    )
}
