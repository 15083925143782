import { createApi } from '@reduxjs/toolkit/query/react'
import config from 'config/index'
import http from 'utils/http'
const reducerPath = 'level2Api'

export const level2Api = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ALGO_API_URL),
    tagTypes: ['LEVEL2ACCESS'],
    endpoints: builder => ({
        getLevel2Access: builder.query({
            query: arg => ({
                url: `/access_control/resources/`,
                method: 'GET',
            }),
            transformResponse: (response: any, meta, arg) => {
                if (arg.type === 'Grantor') {
                    let res

                    if (arg.id) {
                        res = response.resources[arg.id].filter(item => item.myRole === 'Grantor')
                    } else {
                        let responseList: any[] = []

                        for (const item in response.resources) {
                            const filterByRole = response.resources[item]
                                .filter(item => item.myRole === 'Grantor')
                                .filter(item => item.hidden !== true)

                            responseList.push(filterByRole)
                        }
                        res = [].concat.apply([], responseList)
                    }
                    return res
                }
                return response.resources
            },
            providesTags: ['LEVEL2ACCESS'],
        }),
        getLevel2AccessDirectly: builder.mutation({
            query: arg => ({
                url: `/access_control/resources/`,
                method: 'GET',
            }),
            transformResponse: (response: any, meta, arg) => {
                if (arg.type === 'Grantor') {
                    let res

                    if (arg.id) {
                        res = response.resources[arg.id].filter(item => item.myRole === 'Grantor')
                    } else {
                        let responseList: any[] = []

                        for (const item in response.resources) {
                            const filterByRole = response.resources[item]
                                .filter(item => item.myRole === 'Grantor')
                                .filter(item => item.hidden !== true)

                            responseList.push(filterByRole)
                        }
                        res = [].concat.apply([], responseList)
                    }
                    return res
                }
                return response.resources
            },
        }),
        createLevel2Request: builder.mutation({
            query: body => ({
                url: '/access_control/access/request/',
                method: 'POST',
                body: {
                    accessorId: body.accessorId,
                    resourceId: body.resourceId,
                    typeId: body.typeId,
                },
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
                /**
                 * In this Query, resourceId is contentItemId or transactionId
                 */
                const isExclusiveHoldRequest = body.typeId === 2
                const isExclusivityRequest = body.typeId === 3

                const currentData = (getState().level2Api?.queries['getLevel2Access({})']?.data || {}) as object

                try {
                    if (body.isInvalidateResource) {
                        await queryFulfilled
                        // dispatch(level2Api.util.invalidateTags(['LEVEL2ACCESS']))
                    } else if (isExclusiveHoldRequest || isExclusivityRequest) {
                        console.log('isExclusiveHoldRequest')
                        /**
                         * Used for add manually the resource for exclusive request
                         */
                        dispatch(
                            level2Api.util.updateQueryData('getLevel2Access', {}, draft => {
                                Object.assign(draft, {
                                    ...currentData,
                                    [body.resourceId]: currentData[body.resourceId]?.map(access => {
                                        if (access.type.id === body.typeId && access?.accessors?.[0]?.contentItemId === body.accessorId) {
                                            return {
                                                ...access,
                                                status: 'Active',
                                            }
                                        }

                                        return access
                                    }),
                                })
                            }),
                        )
                        /**
                         * We want update first (not waiting api call)
                         * we Will rollback it in catch function when error
                         */
                        await queryFulfilled
                    } else {
                        /**
                         * Existing process before exclusive implementation
                         */
                        const { data } = await queryFulfilled
                        dispatch(
                            level2Api.util.updateQueryData('getLevel2Access', {}, (draft: any) => {
                                const payload = data.accessRequests.filter(item => item.resourceId === body.resourceId)[0]

                                const key = payload.resourceId
                                let obj = {}

                                obj[key] = [payload]

                                Object.assign(draft, obj)
                            }),
                        )
                    }
                } catch (err) {
                    if (isExclusiveHoldRequest || isExclusivityRequest) {
                        /**
                         * We want to rollback when there's an error
                         */
                        dispatch(
                            level2Api.util.updateQueryData('getLevel2Access', {}, draft => {
                                Object.assign(draft, currentData)
                            }),
                        )
                    }
                }
            },
        }),
        getApproveListBorrower: builder.query({
            query: () => ({
                url: `/access_control/approve/list/`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                let res: any
                if (response.accessRequests.length === 0) return []
                res = response.accessRequests.filter((item: { type: { name: string } }) => item.type.name === 'Level2DataRoom')
                return res
            },
        }),
        approveLevel2: builder.mutation({
            query: body => ({
                url: `/access_control/approve/?request_id=${body.request_id}`,
                method: 'POST',
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(
                        level2Api.util.updateQueryData('getLevel2Access', { id: body.contentItemId, type: 'Grantor' }, (draft: any) => {
                            const approved = draft.filter(item => item.requestId === body.request_id)
                            if (approved) {
                                approved.map((item: any) => {
                                    return (item.status = 'Active')
                                })
                            }
                        }),
                    )

                    dispatch(
                        level2Api.util.updateQueryData('getApproveListBorrower', {}, (draft: any) => {
                            const approved = draft.filter(item => item.id === body.request_id)
                            if (approved) {
                                approved.map((item: any) => {
                                    return (item.status = 'Active')
                                })
                            }
                        }),
                    )
                } catch (err) {
                    console.log(err, 'err')
                }
            },
        }),
        denyLevel2: builder.mutation({
            query: (body: { request_id: number; contentItemId?: string }) => ({
                url: `/access_control/deny/?request_id=${body.request_id}`,
                method: 'POST',
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(
                        level2Api.util.updateQueryData('getLevel2Access', { id: body.contentItemId, type: 'Grantor' }, (draft: any) => {
                            const approved = draft.filter(item => item.requestId === body.request_id)
                            if (approved) {
                                approved.map((item: any) => {
                                    return (item.status = 'Denied')
                                })
                            }
                        }),
                    )
                    dispatch(
                        level2Api.util.updateQueryData('getApproveListBorrower', {}, (draft: any) => {
                            const approved = draft.filter(item => item.id === body.request_id)
                            if (approved) {
                                approved.map((item: any) => {
                                    return (item.status = 'Denied')
                                })
                            }
                        }),
                    )
                } catch (err) {
                    console.log(err, 'err')
                }
            },
        }),
        revokeLevel2: builder.mutation({
            query: body => ({
                url: `/access_control/revoke/?access_mapping_id=${body.request_id}`,
                method: 'POST',
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
                const { revokeType = 'requestId' } = body
                const currentData = (getState().level2Api?.queries['getLevel2Access({})']?.data || {}) as object
                try {
                    /**
                     * Used for existing revoke process before exclusive implementation
                     * The changes just wrapped it with revokeType 'requestId'
                     */
                    if (revokeType === 'requestId') {
                        await queryFulfilled
                        dispatch(
                            level2Api.util.updateQueryData('getLevel2Access', { id: body.contentItemId, type: 'Grantor' }, (draft: any) => {
                                const revoked = draft.filter(item => item.id === body.request_id)
                                if (revoked) {
                                    revoked.map((item: any) => {
                                        return (item.status = 'Revoked')
                                    })
                                }
                            }),
                        )
                    } else if (revokeType === 'resourceId') {
                        /**
                         * Used for revoke exclusive
                         */
                        dispatch(
                            level2Api.util.updateQueryData('getLevel2Access', {}, draft => {
                                Object.assign(draft, {
                                    ...currentData,
                                    [body.contentItemId]: currentData[body.contentItemId].map(access => {
                                        if (access.id === +body.request_id) {
                                            return {
                                                ...access,
                                                status: 'Revoked',
                                            }
                                        }

                                        return access
                                    }),
                                })
                            }),
                        )
                        /**
                         * We want update first (not waiting api call)
                         * * we Will rollback it in catch function when error
                         */
                        await queryFulfilled
                    }

                    if (body.isInvalidateResource) {
                        await queryFulfilled
                        dispatch(level2Api.util.invalidateTags(['LEVEL2ACCESS']))
                    }
                } catch (err) {
                    if (revokeType === 'resourceId') {
                        /**
                         * We want to rollback when there's an error
                         */
                        dispatch(
                            level2Api.util.updateQueryData('getLevel2Access', {}, draft => {
                                Object.assign(draft, currentData)
                            }),
                        )
                    }
                }
            },
            transformResponse: (response: any, meta, id) => {
                return response.resources
            },
        }),
    }),
})

export const {
    useGetLevel2AccessDirectlyMutation,
    useGetLevel2AccessQuery,
    useCreateLevel2RequestMutation,
    useApproveLevel2Mutation,
    useDenyLevel2Mutation,
    useGetApproveListBorrowerQuery,
    useRevokeLevel2Mutation,
} = level2Api

export const level2QueryReducer = { [reducerPath]: level2Api.reducer }
export const level2QuerySelector = state => state[reducerPath]
