import { IReduxSurvey } from 'store/modules/survey/survey'

export const toModifiedResponse = (response: IReduxSurvey.ISurveyResponse): IReduxSurvey.ISurveyModifiedResponse => {
    const model = JSON.parse(response.survey)
    const initialData = JSON.parse(response.submission)

    return {
        meta: {
            contextId: response.contextId,
            submissionId: response.submissionId,
            formContentItemId: response.formContentItemId,
            formContentItemVersionId: response.formContentItemVersionId,
        },
        progress: response.percentageComplete,
        status: response.status,
        model,
        initialData,
    }
}

export const toSliceMatcher = (state: IReduxSurvey.IInitialState, payload: IReduxSurvey.ISurveyModifiedResponse) => {
    const pageFormatted = {}
    const pagesFormatted = [] as string[]

    payload.model?.pages?.forEach(page => {
        pagesFormatted.push(page.name)
        pageFormatted[page.name] = {
            title: page.title,
            progress: 0,
            questions: page.elements.length,
        }
    })

    state.page = pageFormatted
    state.pages = pagesFormatted
    state.activePageName = pagesFormatted[0]
    state.status = payload.status
    state.title = payload.model.title
    state.progress = payload.progress
}

export const getSurveyPages = (survey?: IReduxSurvey.ISurveyModifiedResponse, isPublic: boolean = true) => {
    return survey?.model.pages
        .map(page => {
            const elements = page.elements.filter(element => element.isPublic === isPublic)

            if (elements.length > 0) {
                return {
                    name: page.name,
                    elements,
                    title: page.title,
                }
            }
            return null
        })
        .filter(el => el)
}

export const getPublicSurvey = (survey?: IReduxSurvey.ISurveyModifiedResponse) => {
    const publicPages = getSurveyPages(survey)
    return {
        ...survey,
        model: {
            ...survey?.model,
            pages: publicPages,
        },
    }
}
