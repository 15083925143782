import { memo } from 'react'
import { lazyLoad } from 'utils/loadable'

import { useHistory, useParams } from 'react-router-dom'

const SalesModuleModal = lazyLoad(
    () => import('../../components/Matching/SalesModuleModal'),
    module => module.default,
)

export default memo(function CampaigDetailPage() {
    const history = useHistory()
    const { campaignId } = useParams<{ campaignId: string }>()

    return (
        <>
            <SalesModuleModal open campaignId={campaignId} onClose={() => history.push(`/campaigns`)} />
        </>
    )
})
