import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

import { Box, Button, Chip, CircularProgress, Divider, InputLabel, makeStyles, Modal, TextField, Typography } from '@material-ui/core'
import { colorPalette } from 'styles/mainTheme'
import ChipInput from 'material-ui-chip-input'
import { useRequestDBDMutation } from 'store/modules/investor/investor.query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useCreateLevel2RequestMutation } from 'store/modules/level2'
import { userApi } from 'store/modules/user'
import { useSnackbar } from 'notistack'
import { getModalContext } from '../provider'
import { IDBDModalPropsData } from '../definition'

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        boxShadow: '0px 2px 18px rgb(13 59 105 / 15%)',
        backgroundColor: colorPalette.white,
        border: 'solid 1px lightgray',
        borderRadius: 8,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 64px)',
            height: 'calc(100% - 64px)',
        },
    },
    modalInfo: {
        fontSize: 12,
        marginLeft: theme.spacing(1.5),
    },
    circularProgress: {
        marginLeft: 0,
        marginRight: '15px',
        color: '#FFFFFF',
    },
    modalErrorText: {
        fontSize: 12,
    },
    chipInput: {
        border: 'none',
        padding: 14,
        minHeight: 60,
        borderRadius: 4,
        height: 'auto',
        paddingBottom: 8,
    },
}))

type Inputs = {
    email: any
    reasons: string
}

export const DealByDealModal = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const { onClose, isOpen, category, props } = React.useContext(getModalContext<IDBDModalPropsData>())

    const { opportunityId = '', isAllowEditEmail, isConfidential } = props || {}

    const [emailChips, setEmailChips] = useState([''])
    const [localError, setLocalError] = useState<{ [key: string]: string }>({ key: '' })

    const [requestDBD, { isLoading: isLoadingDBD }] = useRequestDBDMutation({})
    const [createLevel2Request, { isLoading: isCreatingLevel2 }] = useCreateLevel2RequestMutation()
    const { data: userProfile } = userApi.endpoints.getUserProfile.useQueryState({})

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<Inputs>({})

    const onSubmit: SubmitHandler<Inputs> = async data => {
        const transformedEmailChips = emailChips.join()

        const isValidEmail = await EmailSchema.validate(
            {
                transformedEmailChips,
            },
            {
                abortEarly: false,
            },
        )
            .then(() => {
                setLocalError({})
                return true
            })
            .catch(e => {
                const currentLocalError = {}
                e.inner.forEach(e => {
                    currentLocalError[e.path] = e.message
                })
                setLocalError(currentLocalError)
                return false
            })

        if (!isValidEmail) return

        await requestDBD({ complianceEmail: transformedEmailChips, reason: data.reasons, opportunityId: opportunityId })

        if (isConfidential) {
            await createLevel2Request?.({
                accessorId: userProfile?.teamIds?.[0],
                resourceId: opportunityId,
                typeId: 1,
            })
                .unwrap()
                .then(() => {
                    enqueueSnackbar('Request sent', { variant: 'success' })
                })
                .catch(error => {
                    console.log(error)
                    enqueueSnackbar(`An error occurred: ${error?.data?.error ?? error.message}`, { variant: 'error' })
                })
        }

        reset()
        props?.refetch?.()
        // Close Modal
        onClose()
    }

    const EmailSchema: any = yup.object().shape({
        transformedEmailChips: yup
            .array()
            .transform(function (value, originalValue) {
                // Split with space enable
                //console.log('originalValue split origin:', originalValue.split(/[\s,]+/))
                if (this.isType(value) && value !== null) {
                    return value
                }
                return originalValue ? originalValue.split(',') : []
            })
            .min(1, 'Email required')
            .of(
                yup
                    .string()
                    .email(({ value }) => `${value} is not a valid email`)
                    .notOneOf([userProfile?.user.email], 'You cannot request compliance requests using your own email address.'),
            ),
    })

    const isFormErrors: boolean = Object.keys(errors).length === 0 ? false : true

    const displayError = () => {
        let errorText = ''
        Object.values(localError).forEach((text, index) => {
            if (index === 0) {
                errorText += text
            } else {
                errorText += ', ' + text
            }
        })
        return errorText
    }

    const handleOnClose = () => {
        onClose()
        reset()
    }

    useEffect(() => {
        setEmailChips(props?.defaultEmails ?? [])
    }, [props?.defaultEmails, setEmailChips])

    if (isOpen && category === 'deal-by-deal') {
        return (
            <Modal
                open={isOpen}
                onClose={handleOnClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="request-access-modal"
            >
                <Box className={classes.modal}>
                    <div style={{ marginBottom: 12 }}>
                        <Typography color="primary" id="modal-modal-title" variant="h6" component="h2">
                            Request Access
                        </Typography>
                    </div>
                    <Divider style={{ marginBottom: 8 }} />
                    <div style={{ marginBottom: 4 }}>
                        <Typography id="modal-modal-subtitle" style={{ fontWeight: 600, color: colorPalette.primary }}>
                            Important Information
                        </Typography>
                    </div>
                    <div style={{ marginBottom: 24 }}>
                        <Typography id="modal-modal-description">
                            Additional information about this transaction, including the data room, is available to you after receiving
                            approval from your compliance team. <br />
                            <br />
                        </Typography>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputLabel>Compliance Email Address</InputLabel>
                        <ChipInput
                            InputProps={{
                                className: classes.chipInput,
                            }}
                            fullWidth
                            variant="outlined"
                            defaultValue={emailChips}
                            newChipKeyCodes={[32, 13]}
                            onChange={chips => setEmailChips(chips)}
                            disabled={!isAllowEditEmail}
                            chipRenderer={({ chip, className, handleClick, handleDelete }, key) => (
                                <Chip
                                    className={className}
                                    key={key}
                                    label={<span style={{ color: 'white' }}>{chip}</span>}
                                    onClick={handleClick}
                                    onDelete={isAllowEditEmail ? handleDelete : undefined}
                                    style={{
                                        color: 'white',
                                        background: 'rgb(95, 177, 100)',
                                        borderRadius: 4,
                                    }}
                                />
                            )}
                        />
                        {isAllowEditEmail ? (
                            <Typography className={classes.modalInfo}>Please add a valid email address and hit space or return.</Typography>
                        ) : null}

                        <Typography color="error" className={classes.modalErrorText}>
                            {displayError()}
                        </Typography>
                        <TextField
                            id="standard-full-width"
                            label="Reasons"
                            fullWidth
                            multiline
                            rows={2}
                            style={{
                                height: 150,
                                marginTop: 24,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ 'data-testid': 'reason-input' }}
                            {...register('reasons')}
                        />
                        <Typography color="error" className={classes.modalErrorText}>
                            {errors?.reasons?.message}
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 16, gap: 8 }}>
                            <Button disabled={isLoadingDBD || isFormErrors || isCreatingLevel2} type="submit" variant="contained">
                                {(isLoadingDBD || isCreatingLevel2) && (
                                    <CircularProgress
                                        style={{ color: isLoadingDBD || isCreatingLevel2 ? colorPalette.primary : '#FFFF' }}
                                        className={classes.circularProgress}
                                        size={20}
                                    />
                                )}
                                Request
                            </Button>
                            <Button onClick={handleOnClose} variant="text">
                                Cancel
                            </Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        )
    }

    return null
}
