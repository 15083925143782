import { memo } from 'react'
import { lazyLoad } from 'utils/loadable'

import { useHistory, useParams } from 'react-router-dom'

const SalesModuleModal = lazyLoad(
    () => import('../../components/Matching/SalesModuleModal'),
    module => module.default,
)

export default memo(function TransactionCampaigCreatePage() {
    const history = useHistory()
    const { contentItemId } = useParams<{ contentItemId: string; campaignId: string }>()

    return (
        <>
            <SalesModuleModal
                contentItemId={contentItemId}
                isCreation
                open
                onClose={() => history.push(`/transaction/originator/${contentItemId}/access`)}
            />
        </>
    )
})
