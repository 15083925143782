import { Typography, Box, Button, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as React from 'react'
import PDFIcon from '../../../../../assets/icons/pdf.svg'
import XLSIcon from '../../../../../assets/icons/xls.svg'
import WordIcon from '../../../../../assets/icons/word.svg'
//import { Link as RouterLink } from 'react-router-dom'
import { default as moment } from 'moment'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined'
import { useDeleteActivityMutation, useMarkAsReadMutation, useMarkAsUnreadMutation } from 'store/modules/activity/activity.query'
import { useSnackbar } from 'notistack'
import { capitalizeFirstLetter } from 'utils/general'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '100%',
        padding: 16,
        marginBottom: 12,
    },
    root2: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    dot: {
        width: 12,
        height: 12,
        borderRadius: 9999,
        marginTop: 5,
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    iconRightContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: 24,
    },
    textContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 12,
    },
    textHeader: {
        textTransform: 'uppercase',
        fontSize: 16,
    },
    textTitle: {
        fontSize: 16,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
    },
    textContent: {
        fontSize: 16,
        letterSpacing: 0.15,
    },
    textTime: {
        letterSpacing: 0.4,
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: 14,
    },
    moreIcon: {
        marginTop: 16,
    },
}))

interface INotificationRow {
    actionType?: string
    icon?: string
    title?: string
    fileName?: string
    timestamp?: string
    verb?: string
    link?: string
    actor?: string
    info?: string
    unread?: boolean
    refetch?: any
    id?: number
    actor_first_name?: string
    actor_last_name?: string
    external_id?: string
    company_name?: string
    subscriber_role?: string
}

export default React.memo(function NotificationRow(props: INotificationRow) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const { enqueueSnackbar } = useSnackbar()
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const [markAsRead] = useMarkAsReadMutation()
    const [markAsUnread] = useMarkAsUnreadMutation()
    const [deleteActivity] = useDeleteActivityMutation()
    const renderedTime = capitalizeFirstLetter(moment(moment.utc(props?.timestamp).toDate()).fromNow())

    const submitMarkAsRead = id => {
        markAsRead(id.toString())
            .unwrap()
            .then(() => {
                enqueueSnackbar('Notification marked as read', { variant: 'success' })
                props.refetch()
            })
            .catch((err: any) => {
                enqueueSnackbar(`An error occurred when updating: ${err?.data?.error}`, { variant: 'error' })
            })
    }

    const submitMarkAsUnread = id => {
        markAsUnread(id.toString())
            .unwrap()
            .then(() => {
                enqueueSnackbar('Notification marked as unread', { variant: 'success' })
                props.refetch()
            })
            .catch((err: any) => {
                enqueueSnackbar(`An error occurred when updating: ${err?.data?.error}`, { variant: 'error' })
            })
    }

    const submitDelete = id => {
        deleteActivity(id.toString())
            .unwrap()
            .then(() => {
                enqueueSnackbar('Notification deleted', { variant: 'success' })
                props.refetch()
            })
            .catch((err: any) => {
                enqueueSnackbar(`An error occurred when updating: ${err?.data?.error}`, { variant: 'error' })
            })
    }

    const getIcon = () => {
        switch (props.icon) {
            case 'pdf':
                return <img src={PDFIcon} height={20} width={20} alt="pdf icon" />
            case 'xls':
                return <img src={XLSIcon} height={20} width={20} alt="xls icon" />
            case 'doc':
                return <img src={WordIcon} height={20} width={20} alt="doc icon" />
            default:
                return <DescriptionIcon height={20} width={20} />
        }
    }

    const normalizeVerb = verb => {
        switch (verb) {
            case 'viewed':
                return 'Document Viewed'
            case 'downloaded file':
                return 'Document Downloaded'
            case 'trashed':
                return 'Document Deleted'
            case 'uploaded file':
                return 'Document Added'
            default:
                return verb
        }
    }

    const getLink = () => {
        if (props.subscriber_role === 'investor') {
            return `/transaction/${props.external_id}`
        }

        const originatorNotificationRoute = {
            applicationsubmission: 'application',
            finitiveuser: 'team',
            file: 'documents',
        }

        const tab = originatorNotificationRoute[props.actionType || ''] || ''

        return `/transaction/originator/${props.external_id}/${tab}`
    }

    return (
        <div className={classes.root} data-testid="notification-row">
            <div className={classes.iconContainer}>{getIcon()}</div>
            <div className={classes.root2}>
                <div className={classes.textContainer}>
                    <Typography className={classes.textHeader}>{normalizeVerb(props.verb)}</Typography>
                </div>

                <div className={classes.textContainer}>
                    <Link key={props.id} to={getLink()}>
                        <Typography color="primary" className={classes.textTitle}>
                            {props.title}
                        </Typography>
                    </Link>
                </div>
                <div className={classes.textContainer}>
                    <Typography className={classes.textContent}>{props.fileName}</Typography>
                </div>
                <div className={classes.textContainer}>
                    <Typography className={classes.textTime}>
                        <span data-test-id="actor_name">
                            {props.actor_first_name} {props.actor_last_name}
                        </span>
                        {' - '}
                        <span data-test-id="actor_company">{props.company_name}</span> - {renderedTime}
                        {props.info && (
                            <Tooltip style={{ marginLeft: 4 }} title={props.info}>
                                <InfoOutlinedIcon color="primary" style={{ width: '15px', marginLeft: '4px' }} />
                            </Tooltip>
                        )}
                    </Typography>
                </div>
            </div>
            <div className={classes.iconRightContainer}>
                <Tooltip title="Read Status">
                    <Box
                        onClick={() => {
                            props.unread ? submitMarkAsRead(props.id) : submitMarkAsUnread(props.id)
                        }}
                        className={classes.dot}
                        style={{ backgroundColor: props.unread ? '#71A94E' : 'rgba(0, 0, 0, 0.12)', cursor: 'pointer' }}
                    />
                </Tooltip>
                <Button
                    className={classes.moreIcon}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    data-testid="more-button"
                >
                    <MoreVertIcon />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {props.unread ? (
                        <MenuItem onClick={() => submitMarkAsRead(props.id)}>Mark As Read</MenuItem>
                    ) : (
                        <MenuItem onClick={() => submitMarkAsUnread(props.id)}>Mark As Unread</MenuItem>
                    )}
                    <MenuItem onClick={() => submitDelete(props.id)}>Delete</MenuItem>
                </Menu>
            </div>
        </div>
    )
})
