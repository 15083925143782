import { createApi } from '@reduxjs/toolkit/query/react'
import config from 'config/index'
import http from 'utils/http'
const reducerPath = 'messengerApi'

export const messengerApi = createApi({
    reducerPath,
    baseQuery: http(undefined, undefined, config.ACTIVITY_URL),
    tagTypes: ['CHATROOM_LIST', 'MESSAGE_LIST'],
    endpoints: builder => ({
        getChatroomList: builder.query({
            query: () => ({
                url: `/comment/v1/chat-room/`,
                method: 'GET',
            }),

            transformResponse: (response: any) => {
                return response.results
            },
        }),
        getInvestorChatroomList: builder.query({
            query: () => ({
                url: `/comment/v1/chat-room/investor/`,
                method: 'GET',
            }),

            transformResponse: (response: any) => {
                return response.results
            },
            providesTags: ['CHATROOM_LIST'],
        }),
        getCommentListByChatroomId: builder.query({
            query: arg => ({
                url: `/comment/v1/comments/chatroom/${arg.chatroomId}/?limit=250`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response.results
            },
            providesTags: ['MESSAGE_LIST'],
        }),
        createInvestorChatroom: builder.mutation({
            query: body => ({
                url: `/comment/v1/chat-room/investor/`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['CHATROOM_LIST'],
        }),
        createMessage: builder.mutation({
            query: body => ({
                url: `/comment/v1/comments/`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['MESSAGE_LIST'],
        }),
    }),
})

export const {
    useGetChatroomListQuery,
    useGetInvestorChatroomListQuery,
    useGetCommentListByChatroomIdQuery,
    useCreateInvestorChatroomMutation,
    useCreateMessageMutation,
} = messengerApi

export const messengerQueryReducer = { [reducerPath]: messengerApi.reducer }
export const messengerQuerySelector = state => state[reducerPath]
