import { Helmet } from 'react-helmet-async'
import { Route, Switch } from 'react-router-dom'

import '../assets/base.scss'
import ScrollToTop from '../utils/ScrollToTop'
import ReactIdleTimer from './components/ReactIdleTimer'
import SessionAutoRefresh from './components/ReactIdleTimer/sessionAutoRefresh'
import { CalendarPage } from './pages/CalendarPage/Loadable'
import { CompleteSignupPage } from './pages/CompleteSignupPage/Loadable'
import { DashboardPage } from './pages/DashboardPage/Loadable'
import { DataRoomPage } from './pages/DataRoomPage/Loadable'
import { DDQMainPage } from './pages/DDQPage/Loadable'
import { EditEmailPage } from './pages/EditEmailPage/Loadable'
import { EmailConfirmationPage } from './pages/EmailConfirmationPage/Loadable'
import { GlobalAccessPage } from './pages/GlobalAccessPage/Loadable'
import { SiteAccessPage } from './pages/SiteAccessPage/Loadable'
import { AdminPage } from './pages/AdminPage/Loadable'
import { InvestorPreferencesPage } from './pages/InvestorPreferencesPage/Loadable'
import { LoginPage } from './pages/LoginPage/Loadable'
import { MarketplacePage } from './pages/MarketplacePage/Loadable'
import { NotFoundPage } from './pages/NotFoundPage/Loadable'
import { OopsPage } from './pages/Oops/Loadable'
import { PatentLitigationPage } from './pages/PatentLitigation/Loadable'
import { ProfilePage } from './pages/ProfilePage/Loadable'
import { ResetPasswordPage } from './pages/ResetPasswordPage/Loadable'
import { SignupPage } from './pages/SignupPage/Loadable'
import { SignupLitePage } from './pages/SignupLitePage/Loadable'
import { TransactionDetailsOriginatorPage } from './pages/TransactionDetailsOriginatorPage/Loadable'
import { TransactionDetailsPage } from './pages/TransactionDetailsPage/Loadable'
import { NotificationsPage } from './pages/NotificationsPage/Loadable'
import { SchedulePreferencesPage } from './pages/SchedulePreferencesPage/Loadable'
import { NotificationPreferencesPage } from './pages/NotificationPreferencesPage/Loadable'
import { UpdatePasswordPage } from './pages/UpdatePasswordPage/Loadable'
import { PreviewPage } from './pages/PreviewPage/Loadable'
import { AuthRoute } from './components/Layout/Route/AuthRoute'
import { InvestorSegmentsPage } from './pages/InvestorSegmentsPage/Loadable'
import { CampaignTemplatesPage } from './pages/CampaignTemplatesPage/Loadable'
import { CampaignsPage } from './pages/CampaignsPage/Loadable'
import TransactionCampaignDetailPage from './pages/TransactionCampaignDetailPage'
import TransactionCampaignCreatePage from './pages/TransactionCampaignCreatePage'
import CampaignDetailPage from './pages/CampaignDetailPage'
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage/Loadable'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage/Loadable'
import { VerifyEmailPage } from './pages/VerifyEmailPage/Loadable'
import { ComplianceResponsePage } from './pages/ComplianceResponsePage/Loadable'
import { WhitelabelResponsePage } from './pages/WhitelabelResponsePage/Loadable'
import { ReportPage } from './pages/AdminPage/ReportPage/Loadable'
import { CompanyUserManagementPage } from './pages/CompanyUserManagement/Loadable'

// const ORG_ID = '177D3Q' // Full story ID

export function App() {
    return (
        <>
            <Helmet titleTemplate="%s" defaultTitle="DealBridge" htmlAttributes={{ lang: 'en' }}>
                <meta name="description" content="A DealBridge application" />
                <meta name="robots" content="noindex"></meta>
            </Helmet>

            {/* @ts-ignore */}
            <SessionAutoRefresh frequency={5} />

            <ReactIdleTimer />

            <ScrollToTop>
                <Switch>
                    {/* Public Page */}
                    <Route exact path="/" component={LoginPage} />
                    <Route exact path="/oops" component={OopsPage} />
                    <Route exact path="/ComplianceResponse" component={ComplianceResponsePage} />
                    <Route exact path="/whitelabelResponse" component={WhitelabelResponsePage} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/ResetPassword" component={UpdatePasswordPage} />
                    <Route exact path="/SetPassword" component={UpdatePasswordPage} />
                    <Route exact path="/forgot-password" component={ResetPasswordPage} />
                    <Route exact path="/signup" component={SignupLitePage} />
                    <Route exact path="/signuplite" component={SignupLitePage} />
                    <Route exact path="/Register" component={SignupPage} />
                    <Route exact path="/edit-email" component={EditEmailPage} />
                    <Route exact path="/complete-signup" component={CompleteSignupPage} />
                    <Route exact path="/verify_email" component={VerifyEmailPage} />
                    <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />

                    {/* Authenticate page - Only Approved user */}
                    <AuthRoute exact path="/marketplace" component={MarketplacePage} />
                    <AuthRoute exact path="/profile" component={ProfilePage} />
                    <AuthRoute exact path="/investor-preferences" component={InvestorPreferencesPage} />
                    <AuthRoute exact path="/schedule-preferences" component={SchedulePreferencesPage} />
                    <AuthRoute exact path="/global-access" component={GlobalAccessPage} />
                    <AuthRoute exact path="/admin/users" component={AdminPage} />
                    <AuthRoute exact path="/admin/reports/:groupId/:reportId" component={ReportPage} />
                    <AuthRoute exact path="/patent-litigation" component={PatentLitigationPage} />
                    <AuthRoute exact path="/notifications" component={NotificationsPage} />
                    <AuthRoute exact path="/Contents/ContentItems/:contentItemId" component={TransactionDetailsPage} />
                    <AuthRoute exact path="/marketplace" component={MarketplacePage} />
                    <AuthRoute exact path="/calendar" component={CalendarPage} />
                    <AuthRoute exact path="/preview/:contentItemId" component={PreviewPage} />
                    <AuthRoute exact path="/ddq" component={DDQMainPage} />
                    <AuthRoute exact path="/ddq/:ddqType" component={DDQMainPage} />
                    <AuthRoute exact path="/patent-litigation" component={PatentLitigationPage} />
                    <AuthRoute exact path="/investor-preferences" component={InvestorPreferencesPage} />
                    <AuthRoute exact path="/notification-preferences" component={NotificationPreferencesPage} />
                    <AuthRoute exact path="/investor-segments" component={InvestorSegmentsPage} />
                    <AuthRoute exact path="/campaign-templates" component={CampaignTemplatesPage} />
                    <AuthRoute exact path="/campaigns/details/:campaignId" component={CampaignDetailPage} />
                    <AuthRoute exact path="/campaigns" component={CampaignsPage} />
                    <AuthRoute exact path="/dataroom" component={DataRoomPage} />
                    <AuthRoute exact path="/transaction/:contentItemId" component={TransactionDetailsPage} />

                    <AuthRoute
                        exact
                        path="/transaction/originator/:contentItemId/access/campaign/create"
                        component={TransactionCampaignCreatePage}
                    />

                    <AuthRoute
                        exact
                        path="/transaction/originator/:contentItemId/access/campaign/details/:campaignId"
                        component={TransactionCampaignDetailPage}
                    />

                    <AuthRoute exact={false} path="/transaction/originator/:contentItemId/" component={TransactionDetailsOriginatorPage} />

                    {/* Authenticate page - Allowed for unapproved user */}
                    <AuthRoute allowUnapproved exact path="/dashboard" component={DashboardPage} />
                    <AuthRoute allowUnapproved exact path="/email-confirmation" component={EmailConfirmationPage} />
                    <AuthRoute allowUnapproved exact path="/company-user-management" component={CompanyUserManagementPage} />
                    <AuthRoute allowUnapproved exact path="/global-access" component={GlobalAccessPage} />
                    <AuthRoute allowUnapproved exact path="/site-access" component={SiteAccessPage} />
                    <AuthRoute allowUnapproved exact path="/profile" component={ProfilePage} />

                    {/* Not found */}
                    <Route exact={false} component={NotFoundPage} />
                </Switch>
            </ScrollToTop>
        </>
    )
}
