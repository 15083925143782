import { useGetUserRoleMembershipQuery } from 'store/modules/opportunity/opportunity.query'
import { userApi } from 'store/modules/user'
import { getToken } from 'utils/localStorage/token'

export interface IUseCheckPermissionComment {
    type: 'opportunity'
    id: string
}

export const useCheckPermissionComment = (props: IUseCheckPermissionComment) => {
    const access_token = getToken('access_token')

    const authorId = userApi.endpoints.getUserProfile.useQueryState({})?.data?.user?.contentItemId ?? ''

    const { data: userMembership, isLoading: isLoadingOpportunityMembership } = useGetUserRoleMembershipQuery(props.id, {
        skip: !access_token || props.type !== 'opportunity' || !props.id,
    })

    let canCreate = true

    if (props.type === 'opportunity' && userMembership) {
        const isViewer = userMembership?.find(m => m.user.contentItemId === authorId)?.role === 'Viewer'
        if (isViewer) {
            canCreate = false
        }
    }

    return {
        isLoading: isLoadingOpportunityMembership,
        canCreate,
        canReply: canCreate,
        canResolve: canCreate,
        canEdit: canCreate,
        canDelete: canCreate,
    }
}
