import { Fab, Fade, Tooltip } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useEffect, useState } from 'react'

const ScrollToPageTop = () => {
    const [showFAB, setShowFAB] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)
    useEffect(() => {
        const handleScroll = () => {
            if (scrollPosition > 200) {
                setShowFAB(true)
            } else {
                setShowFAB(false)
            }
            setScrollPosition(window.scrollY)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            handleScroll()
        }
    }, [scrollPosition])
    return (
        <Fade in={showFAB} style={{ position: 'fixed', right: 45, bottom: 45, zIndex: 99 }}>
            <Tooltip title="Scroll to top">
                <Fab
                    onClick={() =>
                        window.scrollTo({
                            top: 0,

                            behavior: 'smooth',
                        })
                    }
                    size="medium"
                    color="secondary"
                    aria-label="add"
                >
                    <ExpandLessIcon />
                </Fab>
            </Tooltip>
        </Fade>
    )
}

export default ScrollToPageTop
