import Layout from 'app/components/Layout'
import { Helmet } from 'react-helmet-async'

import { lazyLoad } from 'utils/loadable'

const Page = lazyLoad(
    () => import('./index'),
    module => module.default,
)

export const LoginPage = props => {
    return (
        <>
            <Helmet>
                <title>Login Page</title>
                <meta name="description" content="Login page" />
            </Helmet>
            <Layout isOnlyNeedLayoutData>{layoutProps => <Page {...props} {...layoutProps} />}</Layout>
        </>
    )
}
