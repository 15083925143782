import { userSliceReducer } from 'store/modules/user'
import { userQueryReducer } from './user.query'

const combinedReducer = {
    ...userSliceReducer,
    ...userQueryReducer,
}

export * from './user.slice'
export * from './user.query'
export default combinedReducer
