import { createTheme } from '@material-ui/core/styles'

export const colorPalette = {
    primary: '#013C4D',
    secondary: '#013C4D',
    white: '#FFFFFF',
    black: '#000000',
    blue: '#4299E1',
    red: '#F15656',
    yellow: '#FFC107',
    green: '#5FB164',
    green2: '#7EB55E',
    success200: '#ECF5EE',
    warning200: '#FDF7EB',
    gray: '#9A9C9F',
    grayNeutral: '#F5F5F5',
    grayBlueSecondary: '#B9C2CB',
    gray1Secondary: '#5E5F61',
    gray2Secondary: '#8B8C8F',
    gray4Secondary: '#D5D5D5',
    lightgray: '#F6F6F6',
    gray1: '#E0E0E0',
    gray2: '#4F4F4F',
    gray3: '#8B8C8F',
    gray5: '#313233',
    chipLight: '#EBEBEB',
    bluePrimary: '#013C4D',
    lightBluePrimary: '#EFF3F7',
    transparent: 'transparent',
    pink: '#FEEEEE',
    dangerDark: '#F15656',
    mediumBlue: '#5C838F',

    // Palette for Chip
    chipLightGreen: '#5fb16429',
    chipGreen: '#5FB164',
    chipLightGray: '#00000014',
    chipGray: '#00000073',
    chipLightOrange: '#ED6C021F',
    chipOrange: '#F57C00',
    chipLightRed: '#D32F2F1F',
    chipRed: '#F15656',
}

export const mainTheme = createTheme({
    palette: {
        primary: {
            main: colorPalette.primary,
        },
        secondary: {
            main: colorPalette.secondary,
        },
        error: {
            main: colorPalette.red,
        },
    },
    typography: {
        fontFamily: 'Inter',
        fontSize: 14,
        button: {
            fontWeight: 600,
            textTransform: 'capitalize',
        },
        body1: {
            fontSize: 16,
            lineHeight: '24px',
            color: colorPalette.gray2,
        },
        body2: {
            fontSize: 12,
            lineHeight: '14.4px',
            color: colorPalette.gray2,
        },
        h2: {
            fontSize: 24,
            color: colorPalette.secondary,
            fontWeight: 600,
        },
        h3: {
            fontSize: 20,
            lineHeight: '30px',
            color: colorPalette.primary,
            fontWeight: 500,
        },
    },
    overrides: {
        MuiBadge: {
            badge: {
                zIndex: 100,
            },
        },
        MuiCheckbox: {
            root: {
                color: colorPalette.secondary,
            },
        },
        MuiAccordionSummary: {
            root: {
                paddingLeft: 0,
            },
        },
        MuiDivider: {
            middle: {
                marginLeft: 0,
                marginRight: 0,
            },
        },
        MuiSlider: {
            thumb: {
                background: colorPalette.primary + ' !important',
            },
        },
        MuiTablePagination: {
            input: {
                border: 'none',
            },
        },
        MuiTableRow: {
            root: {
                borderTop: '1px solid rgba(224, 224, 224, 1)',
            },
        },
        MuiTableCell: {
            stickyHeader: {
                backgroundColor: colorPalette.white,
            },
        },
        MuiInputBase: {
            root: {
                border: '1px solid' + colorPalette.gray1,
                padding: '0 15px',
                borderRadius: 4,
                height: 44,
                backgroundColor: colorPalette.white,
                '&:hover': {
                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
                },
                '&:pressed': {
                    backgroundColor: colorPalette.lightBluePrimary,
                },
            },
            multiline: {
                minHeight: 125,
            },
            inputMultiline: {
                height: '100%',
                padding: 8,
            },
            input: {
                color: colorPalette.gray2,
            },
        },
        MuiOutlinedInput: {
            root: { border: 'none' },
            notchedOutline: { border: '1px solid ' + colorPalette.gray1 + ' !important' },
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '0px',
                },
                '&:after': {
                    borderBottom: '0px',
                },
                '&:hover:not($disabled):before': {
                    borderBottom: '0px',
                },
            },
        },
        MuiFormControlLabel: {
            label: {
                fontSize: 12,
            },
        },
        MuiSelect: {
            icon: {
                right: '8px',
            },
            selectMenu: {
                color: colorPalette.gray2,
                paddingTop: 4,
                paddingBottom: 4,
            },
        },
        MuiSwitch: {
            input: { maxWidth: 'initial' },
        },
        MuiChip: {
            label: { color: colorPalette.gray2 },
        },
        MuiInputLabel: {
            animated: {
                fontSize: 16,
                transform: 'translate(0, -5.5px) scale(0.75)',
                color: colorPalette.gray2Secondary,
                fontWeight: 400,
                whiteSpace: 'pre',
            },
            outlined: {
                transform: 'translate(15px, 14px) scale(1)',
            },
        },
        MuiButton: {
            label: {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
            sizeSmall: {
                padding: '6px 16px',
                fontSize: '14px',
            },
            sizeLarge: {
                padding: '9px 24px',
                fontSize: '16px',
            },
            contained: {
                backgroundColor: colorPalette.primary,
                color: colorPalette.white,
                '&:hover': {
                    backgroundColor: colorPalette.secondary,
                },
                '&:active': {
                    backgroundColor: colorPalette.secondary,
                },
            },
            outlined: {
                color: colorPalette.primary,
                borderColor: colorPalette.primary,
                '&:hover': {
                    color: colorPalette.secondary,
                    borderColor: colorPalette.secondary,
                    backgroundColor: colorPalette.white,
                },
                '&:active': {
                    color: colorPalette.secondary,
                    borderColor: colorPalette.secondary,
                    backgroundColor: colorPalette.white,
                },
            },
            text: {
                color: colorPalette.primary,
                backgroundColor: colorPalette.white,
                '&:hover': {
                    color: colorPalette.secondary,
                    backgroundColor: colorPalette.transparent,
                },
                '&:active': {
                    color: colorPalette.secondary,
                    backgroundColor: colorPalette.transparent,
                },
            },
            textSecondary: {
                '&:hover': {
                    color: colorPalette.secondary,
                    backgroundColor: colorPalette.transparent,
                },
            },
        },
    },
})
