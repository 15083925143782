import 'isomorphic-unfetch'
import { retry, fetchBaseQuery, BaseQueryFn } from '@reduxjs/toolkit/query/react'

import config from 'config/index'
import { getFullToken } from './localStorage/token'

const MAX_RETRIES = 2

export const http = (
    isServer: any = undefined,
    additionalHeader = {},
    baseUrl = config.API_URL,
    authorizationHeaderKey = 'Authorization',
): BaseQueryFn<any> => {
    if (typeof isServer === 'undefined') {
        isServer = typeof window === 'undefined'
    }

    const additionalHeaderKeys = Object.keys(additionalHeader)

    if (isServer) {
        return async ({ url, method, body, headers }, { dispatch, getState }) => {
            let newHeaders = {}
            const token: any = getFullToken()

            if (token && !url.includes('build_info.json')) {
                newHeaders = {
                    [authorizationHeaderKey]: authorizationHeaderKey === 'Authorization' ? token : token.replace('Bearer ', ''),
                }
            }

            newHeaders = {
                ...newHeaders,
                ...headers,
                ...additionalHeader,
            }

            let formattedBody: any = JSON.stringify(body)

            if (newHeaders?.['Content-Type'] === 'application/x-www-form-urlencoded') {
                formattedBody = new URLSearchParams(body)
            }

            try {
                const result = await fetch(`${baseUrl}${url}`, {
                    method,
                    headers: newHeaders,
                    body: formattedBody,
                })
                    .then(res => res.json())
                    .then(res => res)
                return { data: result }
            } catch (err: any) {
                return {
                    error: {
                        status: err?.response?.status,
                        data: err?.response?.data,
                    },
                }
            }
        }
    } else {
        return retry(
            fetchBaseQuery({
                baseUrl,
                prepareHeaders: (headers, { getState, endpoint }: any) => {
                    const token: any = getFullToken()

                    if (token && !endpoint.includes('getBuildInfo')) {
                        headers.set(
                            authorizationHeaderKey,
                            authorizationHeaderKey === 'Authorization' ? token : token.replace('Bearer ', ''),
                        )
                    }

                    if (additionalHeaderKeys?.length) {
                        additionalHeaderKeys.forEach(key => {
                            headers.set(key, additionalHeader[key])
                        })
                    }
                    return headers
                },
            }),
            { maxRetries: MAX_RETRIES },
        )
    }
}

export default http
