import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxOriginator } from 'store/modules/originator/originator'

const reducerPath = 'originatorApi'
export const originatorApiSlice = createApi({
    reducerPath,
    baseQuery: http(),
    endpoints: builder => ({
        getOriginatorStatusNDA: builder.query<IReduxOriginator.IReduxNDAResponse, Object | void>({
            query: () => ({
                url: `/originator/ndastatus`,
                method: 'GET',
            }),
        }),
        getOriginatorSignNDA: builder.mutation<IReduxOriginator.IReduxSignNDAResponse, Object | void>({
            query: () => ({
                url: `/v1/onboarding/originator/signnda`,
                method: 'GET',
            }),
        }),
    }),
})

export const { useGetOriginatorStatusNDAQuery, useGetOriginatorSignNDAMutation } = originatorApiSlice
