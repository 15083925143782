import { createSlice } from '@reduxjs/toolkit'

import { IReduxUser } from 'store/modules/user/user'
import { setToken } from 'utils/localStorage/token'
import { connectApiSlice } from '../connect/connect.query'

const reducerName = 'user'

export const initialState: IReduxUser.IInitialState = {
    email: '',
    name: '',
    expires_in: 0,
    isExpired: false,
    loginAt: 0,
    loginBy: undefined,
    tokenTmp: {
        accessToken: '',
        tokenType: '',
    },
}

export const userSlice = createSlice({
    name: reducerName,
    initialState,
    reducers: {
        setUser: (state: IReduxUser.IInitialState, { payload }: { payload: IReduxUser.IInitialState }) => {
            state.email = payload?.email ?? ''
            state.name = payload?.name || payload?.email
            state.loginAt = new Date().getTime() / 1000
            state.loginBy = payload?.loginBy

            if (payload.access_token) {
                setToken(
                    JSON.stringify({
                        access_token: payload.access_token,
                        refresh_token: payload.refresh_token,
                        token_type: payload.token_type,
                    }),
                )
            }
        },
        logout: (state: IReduxUser.IInitialState) => {
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key] || ''
            })
        },
        setExpired: (state: IReduxUser.IInitialState, { payload }: { payload: boolean }) => {
            state.isExpired = payload
        },
    },
    extraReducers: builder => {
        builder.addMatcher(connectApiSlice.endpoints.refreshToken.matchFulfilled, (state, { payload }) => {
            state.email = payload?.email ?? ''
            state.name = payload?.name || payload?.email
            state.expires_in = payload.expires_in
            state.scope = payload?.scope ?? ''
            state.isExpired = false
            state.loginAt = new Date().getTime() / 1000
            if (payload.refresh_token) {
                setToken(
                    JSON.stringify({
                        access_token: payload.access_token,
                        refresh_token: payload.refresh_token,
                        token_type: payload.token_type,
                    }),
                )
            }
        })

        builder.addMatcher(connectApiSlice.endpoints.getTokenByCredentialInBackground.matchFulfilled, (state, { payload }) => {
            state.tokenTmp = {
                accessToken: payload.access_token,
                tokenType: payload.token_type,
            }
        })
        builder.addMatcher(connectApiSlice.endpoints.getTokenByCredential.matchFulfilled, (state, { payload }) => {
            state.email = payload?.email ?? ''
            state.name = payload?.name || payload?.email
            state.expires_in = payload.expires_in
            state.scope = payload?.scope ?? ''
            state.isExpired = false
            state.loginAt = new Date().getTime() / 1000
            state.loginBy = 'USERNAME_PASSWORD'
            if (payload.refresh_token) {
                setToken(
                    JSON.stringify({
                        access_token: payload.access_token,
                        refresh_token: payload.refresh_token,
                        token_type: payload.token_type,
                    }),
                )
            }
        })
        builder.addMatcher(connectApiSlice.endpoints.logout.matchFulfilled, state => {
            state.access_token = initialState.access_token
            state.name = initialState.name
            state.email = initialState.email
            state.loginAt = 0
            state.isExpired = false
        })
        builder.addMatcher(connectApiSlice.endpoints.getUserInfo.matchFulfilled, (state, { payload }) => {
            state.email = payload?.email ?? ''
            state.name = payload?.name || payload?.email
            state.loginBy = 'FINITIVE_ACCOUNT'
        })
    },
})

export const { setUser, logout, setExpired } = userSlice.actions
export const userSliceReducer = { [reducerName]: userSlice.reducer }
