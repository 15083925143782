import { Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { useLocation } from 'react-router-dom'
import { useGetBuildInfoQuery } from 'store/modules/master/master.query'

export const BuildInfo = () => {
    const { search } = useLocation()
    const queryParam: any = search ? new URLSearchParams(search) : {}
    const version = queryParam?.get?.('version')

    const { data, isLoading } = useGetBuildInfoQuery(version)

    const dataInJson =
        data !== undefined && !JSON.stringify(data).includes('<') ? JSON.parse(data) : { BuildId: '10000', BuildNumber: '1.0.0' }

    const buildNumber = dataInJson?.BuildNumber ?? 'Unknown'
    const buildId = dataInJson?.BuildId ?? 'Unknown'
    const title = isLoading ? 'Getting build info' : `Version: ${buildNumber} , Run: ${buildId}`

    return (
        <div>
            <Tooltip title={title} placement="right">
                <div>
                    <InfoOutlined />
                </div>
            </Tooltip>
        </div>
    )
}
