import $ from 'jquery'
import { forwardRef, useCallback, useEffect, memo } from 'react'

import './CommentRoom.css'
import CommentRoom, { ICommentRoom } from './CommentRoom'

import { useGetCommentsQuery } from 'store/modules/comment'
import { getToken } from 'utils/localStorage/token'
import { CircularProgress, Typography } from '@material-ui/core'
import { ICommentData } from './definition'
import { useOutsideClick } from 'utils/general'

interface ICommentRoomDialog extends ICommentRoom {
    threadMeta: ICommentData
    disableAutoFixPosition: boolean
}

/**
 * Used by context to show the comment room as inline comment (dialog) that follow the question position
 */
const CommentRoomDialog = forwardRef<any, ICommentRoomDialog>((props: ICommentRoomDialog, ref) => {
    const access_token = getToken('access_token')
    const { disableAutoFixPosition = false } = props
    const { boundarySelector, elementId, contentType, contentTypeId } = props.threadMeta

    const { data, isLoading: isLoadingComment } = useGetCommentsQuery(
        {
            contentType: contentType,
            id: contentTypeId,
        },
        {
            skip: !access_token || !contentType || !contentTypeId,
        },
    )

    const close = useCallback(() => {
        props.headerProps?.onClose?.()
    }, [props.headerProps])

    const outsideClickRef = useOutsideClick(close)

    const updateSize = useCallback(
        _elementId => {
            const element = document.getElementById(_elementId)
            if (element) {
                const elementBoundingClientRect = element.getBoundingClientRect?.()

                /**
                 * Get scrollTop with browser support
                 */
                const scrollTop = window.document.body.scrollTop || window.document.documentElement.scrollTop || window.pageYOffset
                const top = elementBoundingClientRect?.top || 0

                const topNumber = scrollTop + top

                // Allow the comment room can follow the right boundaries
                const rightContainerBoundaryPosition =
                    document.querySelector(boundarySelector || `#main-container > div`)?.getBoundingClientRect()?.left || 16

                $('.chat-room-dialog').css({ top: topNumber, right: rightContainerBoundaryPosition })

                if (!disableAutoFixPosition) {
                    // Automatic scrolling when the chatroom opened in not proper position
                    const bottom = elementBoundingClientRect?.bottom || 0
                    const heightView = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
                    const diffScreen = heightView - bottom
                    const isNotProperScrollPosition = diffScreen < 300
                    if (isNotProperScrollPosition) {
                        $('html,body').animate(
                            {
                                scrollTop: scrollTop + 300,
                            },
                            'slow',
                        )
                    }
                }
            }
        },
        [boundarySelector, disableAutoFixPosition],
    )

    useEffect(() => {
        if (elementId && window) {
            const updateSizeResize = () => updateSize(elementId)
            window.addEventListener('resize', updateSizeResize)

            // false means first user click, not triggered by resize listener

            updateSize(elementId)

            return () => window.removeEventListener('resize', updateSizeResize)
        }
    }, [boundarySelector, disableAutoFixPosition, elementId, updateSize])

    /**
     * Ref still need to return to prevent ref error
     */
    if (!elementId) {
        return <div ref={ref}></div>
    }

    if (isLoadingComment) {
        return (
            <div key={elementId} className="chat-room-background" ref={ref}>
                <div className="chat-room-dialog" ref={outsideClickRef}>
                    <div
                        style={{
                            position: 'relative',
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 8,
                        }}
                    >
                        <Typography>Checking your permission</Typography>
                        <CircularProgress size={18} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div key={elementId} className="chat-room-background" ref={ref}>
            <div className="chat-room-dialog" ref={outsideClickRef}>
                <CommentRoom {...props} data={data} showThreadCreationOnEmpty />
            </div>
        </div>
    )
})

export default memo(CommentRoomDialog)
