import { useState } from 'react'
import $ from 'jquery'
import { useSnackbar } from 'notistack'

import { Avatar, Button, CircularProgress, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import { colorPalette } from 'styles/mainTheme'

import './CommentRoom.css'

import { useCreateCommentMutation } from 'store/modules/comment/comment.query'
import { CommentContentType, CommentStatus } from 'store/modules/comment/enum'

import Send from '@material-ui/icons/Send'
import { strToSentences } from 'utils/general'
import { useGetCommentator } from './hooks/useGetCommentator'

const useStyles = makeStyles(() => ({
    messageTextArea: {
        width: '100%',
        //padding: '8px',
        fontSize: '12px',
        backgroundColor: 'rgb(255, 255, 255)',
        //border: '1px solid rgba(0, 0, 0, 0.26)',
        borderRadius: '3px',
        fontFamily: 'Inter',
        '& .MuiInputBase-inputMultiline': {
            padding: 12,
            paddingTop: 6,
        },
        '& .MuiInputBase-multiline': {
            overflow: 'hidden',
            paddingBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        },
        '& .MuiInputBase-input': {
            color: 'rgb(0,0,0,0.87)',
        },
    },
}))

window['$'] = window['jQuery'] = $

type ThreadProps = {
    onClose: Function
    elementId: string
    contentType: CommentContentType
    contentTypeId: string
}

export const ThreadFormCreation = ({ elementId, contentType, contentTypeId, onClose }: ThreadProps) => {
    const { enqueueSnackbar } = useSnackbar()

    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const classes = useStyles()

    const { currentUserId, currentUserName, currentCompanyName } = useGetCommentator()

    const [createComment, { isLoading: isCreatingComment }] = useCreateCommentMutation()
    const shouldDisableAction = isCreatingComment

    const onSave = async e => {
        e.preventDefault()
        e.stopPropagation()

        setErrorMessage('')
        if (shouldDisableAction) {
            return
        }

        try {
            await createComment({
                body: {
                    sub_comment_object: elementId,
                    text: message,
                    status: CommentStatus.Publish,
                    object_id: contentTypeId,
                    content_type: contentType,
                    extra_data: {
                        pathname: window.location.pathname,
                    },
                },
                commentator: {
                    content_item_id: currentUserId,
                    name: currentUserName,
                    company_name: currentCompanyName,
                },
            }).unwrap()

            enqueueSnackbar('Successfully create a new comment!', { variant: 'success' })
        } catch (error) {
            setErrorMessage('Something went wrong')
        }
    }

    const onChangeMessage = e => {
        setMessage(e.target.value)
    }

    const onFocusMessageInput = () => {
        setErrorMessage('')
    }

    const getContent = () => {
        return (
            <div style={{ width: '100%' }}>
                <p className="comment-meta">
                    <span className="comment-meta__content">
                        {contentType === 'applicationsubmission'
                            ? 'Application Question'
                            : contentType === 'opportunity'
                            ? 'Transaction Details'
                            : contentType}
                    </span>
                    <span>|</span>
                    <span className="comment-meta__content">{strToSentences(elementId ?? '')}</span>
                </p>
                <div style={{ position: 'relative', width: '100%' }}>
                    <TextField
                        className={classes.messageTextArea}
                        multiline
                        minRows={6}
                        maxRows={20}
                        value={message}
                        placeholder={'Type your comment'}
                        onChange={onChangeMessage}
                        onFocus={onFocusMessageInput}
                        style={errorMessage ? { borderColor: '#F15656' } : {}}
                        disabled={shouldDisableAction}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            right: '2px',
                            bottom: '7px',

                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {shouldDisableAction && <CircularProgress size={16} />}
                        <Button disabled={shouldDisableAction || !message.trim()} style={{ background: 'transparent' }} onClick={onSave}>
                            <Send color={message.trim() ? 'primary' : 'disabled'} fontSize="small" />
                        </Button>
                    </div>
                </div>
                <Typography color="error" variant="body2">
                    {errorMessage}
                </Typography>
            </div>
        )
    }

    return (
        <div className={`comment-card chat-room--container`}>
            <div className="comment-card__header">
                <div>
                    <Tooltip title={currentUserName}>
                        <Avatar
                            style={{
                                backgroundColor: colorPalette.mediumBlue,
                                width: '32px',
                                height: '32px',
                            }}
                            className="comment-card__header__profile-picture"
                            children={currentUserName
                                .split(' ')
                                .map(n => n[0])
                                .join('')}
                        />
                    </Tooltip>
                    <div className="comment-card__header__profile">
                        <Typography variant="body2">{currentUserName}</Typography>
                        <Typography variant="caption">{currentCompanyName}</Typography>
                    </div>
                </div>
                <div>
                    <Button className="chat-room__header__right-action" onClick={() => onClose()}>
                        <CloseIcon fontSize={'small'} />
                    </Button>
                </div>
            </div>
            {getContent()}
        </div>
    )
}
