import { IconButton, Input, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { SearchOutlined } from '@material-ui/icons'

interface ISearchInput {
    onChange: Function
    value: string
    style?: any
    onClose?: Function
    className?: any
    placeholder?: string
    onKeyDown: Function
}

// CSS Styling
const useStyles = makeStyles(theme => ({
    searchInput: {
        [theme.breakpoints.up('md')]: {
            maxWidth: 320,
        },
        marginLeft: 16,
    },
}))

export const SearchInput = ({ value, onChange, style, onClose, className, onKeyDown = () => {}, placeholder = 'Search' }: ISearchInput) => {
    const classes = useStyles()
    return (
        <Input
            value={value}
            style={style}
            className={className ? className : classes.searchInput}
            placeholder={placeholder}
            endAdornment={
                !value ? (
                    <IconButton style={{ width: 45, height: 45, left: 10 }}>
                        <SearchOutlined color="disabled" />
                    </IconButton>
                ) : (
                    <IconButton style={{ width: 45, height: 45, left: 10 }}>
                        <CloseIcon
                            color="disabled"
                            onClick={() => {
                                onChange('')
                                if (onClose) onClose()
                            }}
                        />
                    </IconButton>
                )
            }
            fullWidth
            onChange={e => onChange(e.target.value)}
            onKeyDown={e => onKeyDown(e)}
        />
    )
}
