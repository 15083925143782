const config = {
    API_URL: process.env.REACT_APP_API_URL ?? (window as any).REACT_APP_API_URL,
    BOX_URL: process.env.REACT_APP_BOX_URL ?? (window as any).REACT_APP_BOX_URL,
    REPORT_URL: process.env.REACT_APP_REPORT_URL ?? (window as any).REACT_APP_REPORT_URL,
    SCHEDULE_API_URL: process.env.REACT_APP_SCHEDULE_API_URL ?? (window as any).REACT_APP_SCHEDULE_API_URL,
    ALGO_API_URL: process.env.REACT_APP_ALGO_API_URL ?? (window as any).REACT_APP_ALGO_API_URL,
    AUTH_URL: process.env.REACT_APP_AUTH_URL ?? (window as any).REACT_APP_AUTH_URL,
    PROXY_URL: process.env.REACT_APP_PROXY_URL ?? (window as any).REACT_APP_PROXY_URL,
    WEB_URL: process.env.REACT_APP_WEB_URL ?? (window as any).REACT_APP_WEB_URL,
    ACTIVITY_URL: process.env.REACT_APP_ACTIVITY_URL ?? (window as any).REACT_APP_ACTIVITY_URL,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? (window as any).REACT_APP_CLIENT_ID,
    SCORE_THRESHOLD: process.env.REACT_APP_SCORE_THRESHOLD ?? (window as any).REACT_APP_SCORE_THRESHOLD,
    CALENDLY_URL: process.env.REACT_APP_CALENDLY_URL ?? (window as any).REACT_APP_CALENDLY_URL,
    GA_TRACKING_ID: process.env.REACT_APP_GOOGLE_ANALYTICS ?? (window as any).REACT_APP_GOOGLE_ANALYTICS,
    PRINT_FULLSTORY_META: process.env.REACT_APP_PRINT_FULLSTORY_META ?? (window as any).REACT_APP_PRINT_FULLSTORY_META,
    AI_URL: process.env.REACT_APP_AI_URL ?? (window as any).REACT_APP_AI_URL,
}

export default config
