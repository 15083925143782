/* eslint-disable no-useless-escape */
import { currencyChoices } from './investor-preferences'

export const roleChoices = ['Lead Counsel', 'Counsel', 'Research & Advisory', 'Other']
export const idealOutcomeChoices = ['Money', 'Injunction', 'License', 'Cross-license', 'Acquisition']
export const concernChoices = [
    'Depositions related to patent',
    'Depositions not related to patent',
    'Admission related to patent',
    'Admission not related to patent ',
    'Record requests related to patent',
    'Record requests not related to patent',
]

export const ownershipChoices = ['Employment agreement', 'Common law rights', 'Independent contractor assignment', 'Third-party assignment']
export const documentationChoices = ['Lab notebooks', 'Photographs', 'Test results', 'Publication']
export const engagementTermChoices = ['Flat fee', 'Hourly rate', 'Mixed rate', 'Equity compensation', 'Contingency']

const patent_litigation = {
    title: 'Patent Litigation Questionnaire',
    completedHtml: 'Thank you for submitting this questionnaire. You can update these preferences at any time.',
    pages: [
        {
            name: 'Case',
            title: 'Case Overview',
            elements: [
                {
                    type: 'dropdown',
                    name: 'Currency',
                    title: 'Please select the currency in which you will report your answers.',
                    defaultValue: '$',
                    isRequired: true,
                    choices: [...currencyChoices],
                },
                {
                    type: 'customnumbers',
                    name: 'TotalCaseBudget',
                    title: 'What is the estimated total case budget (prior to any legal contingency)?',
                    isRequired: true,
                    currencyQuestionName: 'Currency',
                    fieldType: 'Currency',
                },
                {
                    type: 'customnumbers',
                    name: 'TotalCaseBudgetPercent',
                    title: 'What percent of the case budget is the law firm willing to fund on a contingency basis?',
                    isRequired: true,
                    allowDecimal: false,
                    maxDecimal: '',
                    fieldType: 'Percentage (0-100)',
                },
                {
                    type: 'customnumbers',
                    name: 'EstimatedDamages',
                    title: 'What are the estimated damages for lost profits, a reasonable royalty, or a mixture you expect to collect across all defendants?',
                    isRequired: true,
                    currencyQuestionName: 'Currency',
                    fieldType: 'Currency',
                },
                {
                    type: 'paneldynamic',
                    name: 'LawFirms',
                    title: 'Which law firm(s) will be litigating the case?',
                    isRequired: true,
                    templateElements: [
                        {
                            type: 'text',
                            name: 'LawFirm',
                            title: 'Law Firm',
                            isRequired: true,
                        },
                        {
                            type: 'dropdown',
                            name: 'Role',
                            title: 'Role',
                            isRequired: true,
                            hasOther: true,
                            choices: [...roleChoices],
                        },
                    ],
                    minPanelCount: 1,
                },
                {
                    type: 'boolean',
                    name: 'PatentOwner',
                    title: 'Are any of the original patent inventor(s)/ owner(s) still an owner of the patent and claimant in this case?',
                    isRequired: true,
                },
                {
                    type: 'paneldynamic',
                    name: 'PastCases',
                    title: 'Have there been any prior patent litigation or negotiations with the defendant(s)?',
                    isRequired: true,
                    templateElements: [
                        {
                            type: 'boolean',
                            name: 'Cases',
                            title: ' ',
                        },
                        {
                            type: 'comment',
                            name: 'Details',
                            title: 'Details',
                            colCount: 2,
                            visibleIf: '{panel.Cases} = 1',
                        },
                    ],
                    minPanelCount: 1,
                    maxPanelCount: 1,
                },
                {
                    type: 'checkbox',
                    name: 'IdealOutcomes',
                    title: 'Ideal outcome(s) of the case?',
                    isRequired: true,
                    choices: [...idealOutcomeChoices],
                    choicesOrder: 'asc',
                },
                {
                    type: 'checkbox',
                    name: 'ExpectedConcerns',
                    title: 'Any expected blowbacks or concerns about the process of litigation?',
                    isRequired: true,
                    choices: [...concernChoices],
                    hasOther: true,
                },
            ],
        },
        {
            name: 'Inventors',
            title: 'Inventors',
            elements: [
                {
                    type: 'paneldynamic',
                    name: 'PatentInventor',
                    title: 'Patent inventor',
                    templateElements: [
                        {
                            type: 'text',
                            name: 'FirstName',
                            title: 'First name',
                        },
                        {
                            type: 'text',
                            name: 'LastName',
                            title: 'Last name',
                        },
                        {
                            type: 'text',
                            name: 'LinkedInProfile',
                            title: 'LinkedIn profile URL',

                            validators: [
                                {
                                    type: 'regex',
                                    text: 'Must be a valid URL',
                                    // eslint-disable-next-line prettier/prettier
                                    regex: '^https://www.linkedin.com/[^*]*',
                                },
                            ],
                        },
                        {
                            type: 'boolean',
                            name: 'StillPatentOwner',
                            title: 'Is this party still an owner of the patent and claimant in this case?',
                        },
                        {
                            type: 'boolean',
                            name: 'PeerRecognition',
                            title: 'Is there press or peer recognition of the role of the invention in the invention in the industry?',
                        },
                        {
                            type: 'text',
                            name: 'MeadiaLinks',
                            title: 'Media links',

                            validators: [
                                {
                                    type: 'regex',
                                    text: 'Must be a valid URL',
                                    // eslint-disable-next-line prettier/prettier
                                    regex: '^https:[^*]',
                                },
                            ],
                        },
                        // TODO: add "Recognition document(s)" section"
                    ],
                    minPanelCount: 1,
                },
                {
                    type: 'checkbox',
                    name: 'Ownership',
                    title: 'How did each of the named inventors come to assign to the owner?',
                    choices: [...ownershipChoices],
                    hasOther: true,
                },
                {
                    type: 'paneldynamic',
                    name: 'DutiesPanel',
                    title: 'Do the inventors have ongoing duties of cooperation in prosecution and/or enforcement of the patent?',
                    templateElements: [
                        {
                            type: 'boolean',
                            name: 'OngoingDuties',
                            title: ' ',
                        },
                        {
                            type: 'comment',
                            name: 'DutiesDetails',
                            title: 'Please describe the contractual relationship',
                            colCount: 2,
                            visibleIf: '{panel.OngoingDuties} = 1',
                        },
                    ],
                    minPanelCount: 1,
                    maxPanelCount: 1,
                },
            ],
        },
        {
            name: 'Patents',
            title: 'Patents',
            elements: [
                {
                    type: 'paneldynamic',
                    name: 'PatentInventor',
                    title: 'Patent inventor',
                    templateElements: [
                        {
                            type: 'text',
                            name: 'PatentNumber',
                            title: 'Patent’s number',
                        },
                        {
                            type: 'text',
                            name: 'PatentOrganization',
                            title: 'Which organization is this identifier from?',
                        },
                        {
                            type: 'text',
                            name: 'PatentName',
                            title: 'Patent’s name',
                        },
                        {
                            type: 'text',
                            name: 'PatentAbstract',
                            title: 'Patent’s abstract',
                        },
                        {
                            type: 'text',
                            name: 'PatentStory',
                            title: 'Describe the story of the invention and how it came to be',
                        },
                        {
                            type: 'boolean',
                            name: 'PrototypeDeveloped',
                            title: 'Was a prototype developed?',
                        },
                        {
                            type: 'checkbox',
                            name: 'PointInTimeDocumentation',
                            title: 'What is the point-in-time documentation of the invention and prototypes?',
                            choices: [...documentationChoices],
                        },
                    ],
                    minPanelCount: 1,
                },
                {
                    type: 'text',
                    name: 'AttoureySelectionCriteria',
                    title: 'How was the patent attorney or law firm selected for the patent application?',
                },
                {
                    type: 'checkbox',
                    name: 'EngagementTerms',
                    title: 'What were the general terms of engagement of the patent counsel?',
                    choices: [...engagementTermChoices],
                    hasOther: true,
                },
            ],
        },
    ],
    sendResultOnPageNext: true,
}

export default patent_litigation
