import { memo, useMemo, useRef, useState } from 'react'

import { Box, Button, Grid, Slider, Typography } from '@material-ui/core'
import AvatarEditor from 'react-avatar-editor'

import { UploadFilesWithModal, IUploadImageWithModal, IUploadFilesChildren } from '..'

interface IEnhancedAvatarEditor extends IUploadFilesChildren {
    onSave?: (file: File, url: string) => any
    isLoading?: boolean
    onClose: Function
}

const EnhancedAvatarEditor = ({ files, reset, onSave, isLoading, onClose }: IEnhancedAvatarEditor) => {
    const avatarEditor = useRef<any>(null)
    //const [rotate, setRotate] = useState(0)
    const [scale, setScale] = useState(0.75)
    //const [borderRadius, setBorderRadius] = useState(0)
    const borderRadius = 0
    const [width] = useState(485)
    const [height] = useState(250)
    const [borderX, setBorderX] = useState(0)
    const [borderY, setBorderY] = useState(0)

    const file = useMemo(() => {
        return files?.[0]
    }, [files])

    // const rotateLeft = e => {
    //     e.preventDefault()
    //     setRotate(rotate - 90)
    // }

    // const rotateRight = e => {
    //     e.preventDefault()
    //     setRotate(rotate + 90)
    // }

    const handleScale = (_e, newValue) => {
        setScale(parseFloat(newValue))
    }

    const handleHeight = (_e, newValue) => {
        setBorderY(parseFloat(newValue))
    }

    const handleWidth = (_e, newValue) => {
        setBorderX(parseFloat(newValue))
    }

    // const handleBorderRadius = (_e, newValue) => {
    //     setBorderRadius(parseInt(newValue))
    // }

    const handleSave = () => {
        if (avatarEditor) {
            avatarEditor.current.getImageScaledToCanvas().toBlob(async blob => {
                const newFile = new File([blob], `profilePicture.png`, {
                    type: 'image/png',
                })

                const canvas = avatarEditor.current.getImage().toDataURL()

                const imageUrl = await fetch(canvas)
                    .then(res => res.blob())
                    .then(blob => window.URL.createObjectURL(blob))

                onSave?.(newFile, imageUrl)
            })
        }
    }

    if (typeof file === 'undefined') {
        return (
            <Grid container style={{ padding: '8px' }}>
                <Grid item xs={12} style={{ marginTop: 4 }}>
                    <Box mt={4} display="flex" justifyContent="flex-end" gridGap={12}>
                        <Button
                            data-testid="back-button"
                            onClick={() => onClose()}
                            variant="outlined"
                            style={{ marginRight: '5px' }}
                            size="large"
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={true}
                            data-testid="save-button"
                            onClick={handleSave}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container data-testid="upload-avatar-children">
            <Grid item xs={12} style={{ marginTop: 4 }}>
                <AvatarEditor
                    data-testid="avatar-editor"
                    ref={avatarEditor}
                    image={file}
                    width={width - 2 * borderX}
                    border={[borderX, borderY]}
                    height={height - 2 * borderY}
                    borderRadius={width / (100 / borderRadius)}
                    scale={scale}
                    //rotate={rotate}
                />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 4 }}>
                <Typography>Zoom</Typography>
                <Slider
                    data-testid="zoom-slider"
                    value={scale}
                    onChange={handleScale}
                    aria-labelledby="continuous-slider"
                    step={0.1}
                    min={0}
                    max={2}
                />
                <Typography>Crop Height</Typography>
                <Slider
                    data-testid="zoom-slider"
                    onChange={handleHeight}
                    value={borderY}
                    aria-labelledby="continuous-slider"
                    step={20}
                    min={0}
                    max={height / 2}
                />

                <Typography>Crop Width</Typography>
                <Slider
                    data-testid="zoom-slider"
                    onChange={handleWidth}
                    value={borderX}
                    aria-labelledby="continuous-slider"
                    step={20 * 2}
                    min={0}
                    max={width / 2}
                />

                {/* <Typography>Border radius</Typography>
                <Slider
                    data-testid="radius-slider"
                    value={borderRadius}
                    onChange={handleBorderRadius}
                    aria-labelledby="continuous-slider"
                    step={1}
                    min={0}
                    max={50}
                /> */}

                {/* <Typography>Rotate</Typography>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button data-testid="rotate-left" onClick={rotateLeft}>
                        Left
                    </Button>
                    <Button data-testid="rotate-right" onClick={rotateRight}>
                        Right
                    </Button>
                </ButtonGroup> */}
            </Grid>
            <Grid item xs={12} style={{ marginTop: 4 }}>
                <Box mt={4} display="flex" justifyContent="flex-end" gridGap={12}>
                    <Button
                        disabled={isLoading}
                        data-testid="back-button"
                        onClick={() => reset()}
                        variant="outlined"
                        style={{ marginRight: '5px' }}
                        size="large"
                    >
                        Back
                    </Button>
                    <Button
                        size="large"
                        disabled={isLoading}
                        data-testid="save-button"
                        onClick={handleSave}
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

interface IUploadAvatar extends Omit<IUploadImageWithModal, 'dropzoneOptions'> {
    onSave: (file: File, url: string) => any
    isLoading?: boolean
}

const UploadAvatar = ({ onSave, isLoading, ...props }: IUploadAvatar) => {
    return (
        <UploadFilesWithModal
            {...props}
            isHideInputAfterUpload={true}
            dropzoneOptions={{ maxFiles: 1, multiple: false, accept: { 'image/*': [] } }}
        >
            {uploadFileProps => <EnhancedAvatarEditor {...uploadFileProps} onClose={props.onClose} onSave={onSave} isLoading={isLoading} />}
        </UploadFilesWithModal>
    )
}

export default memo(UploadAvatar)
